
export default {

  data () {
    return {

        mixinClassIconEye: 'icon-eye-main icon-common-eye-hide', // Switch EYE-icons

        iconEyeHideClass: 'icon-eye-main icon-common-eye-hide', // Set DEFAULT value
        iconEyeShowClass: 'icon-eye-main icon-common-eye-show', // Set DEFAULT value

        mixinPasswordType: 'password',
      
    } // return
  }, // data

  
  // ===========================================================================================================
  created () {

    if (this.$store.state.userTheme == 'dark') { // THEMEs
      this.iconEyeHideClass = 'icon-eye-main icon-common-eye-hide-light';
      this.iconEyeShowClass = 'icon-eye-main icon-common-eye-show-light';
      this.mixinClassIconEye = this.iconEyeHideClass; // Set DEFAULT value
    }
    //else {
    //  this.iconEyeHideClass = 'icon-eye-main icon-common-eye-hide';
    //  this.iconEyeShowClass = 'icon-eye-main icon-common-eye-show';
    //  this.mixinClassIconEye = this.iconEyeHideClass; // Set DEFAULT value
    //}

  }, // created


  methods: {

    // Show/hide PASSWORD and RASSWORD REPEAT ----------------------------------------------------------------------
    mixinPasswordShowHide () {
          if (this.mixinPasswordType == 'password') {
              this.mixinPasswordType = 'text';
              this.mixinClassIconEye = this.iconEyeShowClass;
          }
          else {
              this.mixinPasswordType = 'password';
              this.mixinClassIconEye = this.iconEyeHideClass;
          }
      }

  } // methods
}

<template>
<div class="w-100">

  <div class="client-container init">

    <!-- NAVBAR .............................................................................. -->
    <item-init-nav-bar></item-init-nav-bar>

<div>

  <!-- PP-1 ............................................................................. -->
  <section class="init-box-1">

    <div><h1 class="title-h1-bigger theme-light-title-1">Myy edustamasi yhtiön vakuutuksia etänä</h1></div>
     
    <div>
      <p>Laadukkaita tarjouspyyntöjä joita et muualta saa. Ei sitoutumista tai yllätyskuluja. Markkinoiden kustannustehokkain lisämyyntikanava</p>
      <!-- <div class="m_t-10"><h4 class="theme-light-title-2">Vain 29 €/kk sekä max 5% provisio hyväksytyistä tarjouksista</h4></div> -->
    </div>
      
    <div class="init-box-1-1">
      <div><img src="@/assets/img/ill-07.jpg" alt="Nainen analysoimassa dataa kannettavallaan"></div>
      <!-- SIGN UP: NESTED ... -->
      <item-sign-up-nested></item-sign-up-nested>
    </div>

  </section>

  <!-- MAIN ............................................................................. -->
  <div class="init-box-main">

    <!-- BOX-2 ............................................................................. -->
    <section class="init-box-2">
      <div class="init-box-2-1">
        <div><h4 class="theme-light-title-2">Edusta yhtiötäsi etänä</h4></div>
        <div><h1 class="theme-light-title-1 m_b-0">Hallitse myyntiäsi. Keskity palvelemiseen, me hoidamme asiakashankinnan</h1></div>
        <div><p>Päätä itse paljonko asiakkaita haluat ja muuta tarjouspyyntöjen rajaa milloin tahansa. Vältä yhtiön sisäinen kilpailu dedikoiduilla liideillä. Olitpa sitten yhtiösi myyntikuningas tai vain tasaisesta tulovirrasta nauttiva, tämä palvelu on tehty sinua varten</p></div>
      </div>
      <div><img src="@/assets/img/ill-08.jpg" alt="Toimistotyöntekijä rentoutumassa aurinkotuolissa"></div>
    </section>

    <!-- BOX: HOW IT WORKS ................................................................. -->
    <section class="init-box-how_it_works">

      <div><h4 class="theme-light-title-2">Miten palvelu toimii?</h4></div>
      <div><h1 class="theme-light-title-1 m_b-0">Näin aloitat palvelun käytön</h1></div>
      <div>Vakuutustarjousten tekeminen ei ole koskaan ollut näin helppoa.</div>
      
      <div class="init-box-how_it_works-1">

        <div class="init-box-iconed-1">
          <div>
            <div class="init-box-iconed-1-number">1</div>
            <img src="@/assets/img/icon-ir-register.svg" alt="Hymyilevä mies">
          </div>
          <div><h4 class="theme-light-title-2">Rekisteröidy</h4></div>
          <div>Luo ilmainen profiili ja täydennä tiedot. Olemme sinuun yhteydessä viimeistään seuraavana arkipäivänä</div>
        </div>

        <div class="init-box-iconed-1">
          <div>
            <div class="init-box-iconed-1-number">2</div>
            <img src="@/assets/img/icon-ir-offer.svg" alt="Täytetty lomake ja hammasratas">
          </div>
          <div><h4 class="theme-light-title-2">Tee tarjous</h4></div>
          <div>Ole yhteydessä asiakkaisiin ja tee tarjous dedikoituihin tarjouspyyntöihisi</div>
        </div>
      
        <div class="init-box-iconed-1">
          <div>
            <div class="init-box-iconed-1-number">3</div>
            <img src="@/assets/img/icon-vakuutus-tehdaan-sopimus.svg" alt="Kädenpuristus">
          </div>
          <div><h4 class="theme-light-title-2">Sovi kaupoista</h4></div>
          <div>Myy omalla tyylilläsi. Ei myyntiprovisiota kaupoista. Onnittele itseäsi hyvästä kaupasta</div>
        </div>

      </div>

    </section>

    <!-- BOX-3 ............................................................................. -->
    <section class="init-box-3">

      <div><img src="@/assets/img/ill-06.jpg" alt="Iloiset miehet kättelevät auton edessä kaupungissa"></div>
      
      <div class="init-box-3-1">
        <div><h4 class="theme-light-title-2">Vakuutusasiamiehet edellä luotu</h4></div>
        <div><h1 class="theme-light-title-1 m_b-0">Sinulla on unelmatyö. Me teemme siitä vielä vähän paremman</h1></div>
        <div>Tiesitkö että vakuutustiedot.fi on suunniteltu alun alkaen nimenomaan vakuutusasiamiesten tarpeiden näkökulmasta</div>
      </div>
    
    </section>

    <!-- BOX: WHY .......................................................................... -->
    <section class="init-box-why">

      <div><h4 class="theme-light-title-2">Vakuutustiedot.fi tekee vakuutustarjousten tekemisestä helppoa</h4></div>
      <div><h1 class="theme-light-title-1 m_b-0">Tarjoa edustamasi yhtiön vakuutuksia niitä tarvitseville</h1></div>
      <div>Vakuutustarjousten tekeminen on nyt helppoa ja entistäkin mukavampaa</div>
     
      <div class="init-box-why-1">

        <div class="init-box-iconed-1">
          <div><img src="@/assets/img/icon-wallet.svg" alt="Lompakko jossa on käteistä"></div>
          <div><h4 class="theme-light-title-2">Tienaa enemmän</h4></div>
          <div>Maksa ainoastaan vastaanottamistasi tarjouspyynnöistä. Ei muita kuluja</div>
        </div>

        <div class="init-box-iconed-1">
          <div><img src="@/assets/img/icon-ir-benefit-2.svg" alt="Kämmenen päällä levitoiva henkilö"></div>
          <div><h4 class="theme-light-title-2">Asiakashankinta</h4></div>
          <div>Hoidamme asiakashankinnan puolestasi panostamalla tehokkaaseen ja näkyvään markkinointiin</div>
        </div>
      
        <div class="init-box-iconed-1">
          <div><img src="@/assets/img/icon-ir-benefit-3.svg" alt="Rantatuoli auringonpaisteessa"></div>
          <div><h4 class="theme-light-title-2">Nauti vapaudesta</h4></div>
          <div>Ei enää tarvetta konttoritapaamisille tai jatkuville asiakashankintapuheluille</div>
        </div>

      </div>
      <div class="init-box-why-2">

        <div class="init-box-iconed-1">
          <div><img src="@/assets/img/icon-ir-benefit-4.svg" alt="Peukalo pystyssä"></div>
          <div><h4 class="theme-light-title-2">Helppoa</h4></div>
          <div>Vastaanota tarjouspyynnöt työsähköpostiisi. Kontaktoi asiakkaat ja lähetä tarjoukset yhtiösi järjestelmästä</div>
        </div>
      
        <div class="init-box-iconed-1">
          <div><img src="@/assets/img/icon-ir-benefit-5.svg" alt="Asiakaspalvelija"></div>
          <div><h4 class="theme-light-title-2">Asiakaspalvelu</h4></div>
          <div>Ilmainen asiakaspalvelumme opastaa tarvittaessa palvelun käytössä</div>
        </div>
      
        <div class="init-box-iconed-1">
          <div><img src="@/assets/img/icon-ir-benefit-6.svg" alt="Riippulukko kilven päällä"></div>
          <div><h4 class="theme-light-title-2">Turvallinen</h4></div>
          <div>Palvelu on tietoturvallinen. Suodatamme ja estämme roskapostin</div>
        </div>

      </div>

    </section>
   
    <!-- BOX: FAQ .......................................................................... -->
    <box-init-box-faq></box-init-box-faq>
  
  </div>

</div>
  </div>

  <!-- FOOTER ................................................................................ -->
  <item-footer-main></item-footer-main>

</div>
</template>


<script> // -------------------------------------------------------------------------------------------------------

import itemFooterMain       from '@/components/itemFooterMain'
import itemInitNavBar       from '@/components/pageInit/itemInitNavBar'
import itemSignUpNested     from '@/components/itemSignUp/itemSignUpNested'
import boxInitBoxFaq        from '@/components/pageInit/boxInitBoxFaq'
// import boxInitBoxWhy        from '@/components/pageInit/boxInitBoxWhy'
// import boxInitBoxHowItWorks from '@/components/pageInit/boxInitBoxHowItWorks'

import mixinUserDefine from '@/mixins/mixinUserDefine'


export default {
  name:        'pageInitIr',
  components: {
                itemFooterMain,
                itemInitNavBar,
                itemSignUpNested,
                boxInitBoxFaq
                // boxInitBoxWhy,
                // boxInitBoxHowItWorks,
              },
  mixins:     [ 
                mixinUserDefine
              ],

  data () {

    return {

       signUpShow: false,

    } // return

  }, // data


  // ===========================================================================================================
  beforeMount() {

    this.mixinUserDefine_RedirectFromSharedPages(); // ONLY for SIGNED IN USER: redirect user from here to his profile page

  }, // beforeMount


  // ===========================================================================================================
  created () {

    this.$store.commit('userTypeInitSet', 'ir'); // In Store: SET type of Init User Type: in use in several places

  }, // created


  // ===========================================================================================================
  mounted () {
  }, // mounted


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>
<template>
<transition name="fade" mode="out-in">

  <div>

    <!-- SIGN UP: FORM ................ -->
    <item-sign-up-form parentType              = "nested"
                      @sign-up-successful-show = "signUpSuccessfulShow=true"></item-sign-up-form>

    <!-- SIGN UP: SUCCESSFUL .......... -->
    <item-sign-up-successful v-if              = "signUpSuccessfulShow"
                             key               = "signUpSuccessfulShow"
                             parentType        = "nested"
                            @current-box-close = "signUpSuccessfulShow=false"></item-sign-up-successful>

  </div>

</transition>
</template>


<script> // ----------------------------------------------------------------------------------------------------------

import itemSignUpForm       from '@/components/itemSignUp/itemSignUpForm'
import itemSignUpSuccessful from '@/components/itemSignUp/itemSignUpSuccessful'


export default {
  name:        'itemSignUpNested',
  components: { 
                itemSignUpForm,
                itemSignUpSuccessful
              },
  //mixins:     [ mixinXhrPostAsyncApp ],
  //props:      ['parentType'],


  data () {

    return {

      buttonTheme1:    'button-theme-1',
      //buttonTheme2:    'button-theme-2',
      inputTextTheme1: 'input-text-theme-1',

      signUpSuccessfulShow: false,

    }

  }, // data


  // ===========================================================================================================
  created () {},


  // ===========================================================================================================
  methods: {

    //--- Reset reCaptcha VALIDATION ---------------------------------------------
    //recaptchaValidationReset () {},

  }, // methods


} // default

</script>
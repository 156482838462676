<template>
  <div>
    
  <!-- <p><b>Suosittele palvelua ja tienaa rahaa</b></p> -->

  <p>Maksamme 10 € palkkion jokaisesta palveluumme kutsumastasi uudesta henkilö- tai yritysasiakkaasta, joka hyväksyy hänelle esitetyn vakuutustarjouksen palvelussamme.</p>

  <p>Vain suomalaisen henkilötunnuksen omaavat ja vähintään 15-vuotiaat asiakkaat voivat pyytää tarjouksia palvelussamme. Samasta asiakkaasta voit saada palkkion vain yhden kerran. Asiakkaiden henkilöllisyys varmistetaan vahvalla tunnistautumisella.</p>

  <p>Palkkio maksetaan ilmoittamallesi pankkitilille automaattisesti heti saatuamme provision asiakkaan hyväksymästä vakuutustarjouksesta. Tilin tulee olla suomalaisessa pankissa ja sinun omasi.</p>

  <p>Suositteluohjelmaan osallistuminen edellyttää profiilin luomista palveluumme. Profiilin luominen ei velvoita sinua mihinkään.</p>

  <p>Pidätämme kaikki oikeudet suositteluohjelman ja sen ehtojen muutoksiin.</p>

  <p><br/></p>

  <p><b>Neljä helppoa vaihetta tienataksesi suosittelemalla</b></p>

  <ol>
    <li>Luo profiili palveluumme tai kirjaudu olemassa olevaan profiiliisi.</li>
    <li>Avaa "Suosittele palvelua ja tienaa rahaa"-osio.</li>
    <li>Tallennettuasi tilinumerosi saat suosittelulinkin, jota voit jakaa kenelle haluat.</li>
    <li>Maksamme tienaamasi suosittelupalkkiot automaattisesti tilillesi.</li>
  </ol>

  </div>
</template>


<script> // -----------------------------------------------------------------------------------------------------------

//import mixinXhrPostAsyncApp from '@/mixins/mixinXhrPostAsyncApp'


export default {
  name:  'itemRecommendAndEarnRulesText', 
  // emits: [ 'current-box-close'],
  // props: [ 'rewardDrawOutLimitMin' ],
  //mixins:     [],
  //components: {},


  data () {
    return {

      // buttonTheme1: 'button-theme-1',

    } // return
  }, // data


  // ===========================================================================================================
  created () {
    // document.body.classList.add('overflow-hidden'); // For NOT to SCROLL whole page at opened POP-UP WINDOW
  }, // created


  // ===========================================================================================================
  beforeUnmount () {
    // document.body.classList.remove('overflow-hidden'); // After closing POP-UP WINDOW there is no need in that CLASS
  }, // beforeUnmount


  // ===========================================================================================================
  methods: {
    
    /*
    //---  -------------------------------------------------
    example () {
    },
    */

  }, // methods

}
</script>


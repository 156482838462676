<template>

<!-- SIGN UP: FORM ....................................................................................... -->
<div :class = "signUpFormClass"
      class = "init-sign_up">


  <!-- TOP ........................................................... -->
  <div :class="signUpFormTopClass">

    <div>
        <h3 class="theme-light-title-1">{{ initSignupButtonTitle }}</h3>
    </div>

    <span v-if="parentType=='pop-upped'" class="button-close" @click="$emit('current-box-close')" title="Sulje ikkuna"></span>

  </div>


  <!-- MIDDLE ........................................................ -->
  <div :class="signUpFormMiddleClass">


  <!-- USER TYPE: PICK UP ONE ........................................ -->

  <div v-if  = "currentPageType=='pageInfo'"
       class = "windowed-4 init-sign_up-form-user_type-choose">

    <div>
      <h5 class="theme-light-title-1">Asiakasryhmä</h5>
    </div>

    <div>
      <select class   = "input-text-4"
              v-model = "signUpUserType"
             @change  = "signUpFormSet">
        <option class="option-theme-1" value="pp">Henkilöasiakas</option>
        <option class="option-theme-1" value="pc">Yritysasiakas</option>
        <option class="option-theme-1" value="ir">Vakuutusasiamies</option>
      </select>
    </div>

  </div>

  <form class="init-sign_up-form" novalidate="true">


  <!-- FIRSTNAME ........................................................................................... -->

  <div v-if = "initSignupInputShow.firstName">

    <input type         = "text"
           class        = "input-text-3"
          :class        = "inputTextTheme1"
           v-model      = "initSignup.firstName"
          @input        = "firstNameInput"
          @blur         = "firstNameBlur"
          :maxlength    = "mixinValidation_SharedNameSimple_inputMaxLength"
           autocomplete = "given-name"
           placeholder  = "Etunimi">
          <!-- @focus        = "mixinTooltipsInputShowSimple('firstName')" -->
<!--     <input type         = "text"
           class        = "input-text-3"
          :class        = "inputTextTheme1"
           v-model      = "initSignup.firstName"
          @focus        = "mixinTooltipsInputShowSimple('firstName')"
          @focusout     = "mixinTooltipsInputHide('firstName')"
          @input        = "mixinValidation_SharedNameSimple(initSignup.firstName, 'firstName')"
          @blur         = "mixinValidation_SharedNameSimple_Start(initSignup.firstName, 'firstName')"
          :maxlength    = "mixinValidation_SharedNameSimple_inputMaxLength"
           autocomplete = "given-name"
           placeholder  = "Etunimi">
 -->
    <!-- TOOLTIPS MAIN -->
    <box-tooltips-input :tooltipsMainShow  = "mixinTooltipsInput.firstName"
                        :tooltipsMainText  = "mixinTooltipsInputText.firstName"
                        @current-box-close = "mixinTooltipsInputHide('firstName')"></box-tooltips-input>

    <!-- VALIDATION BOX -->
    <div :class="mixinClassVal.firstName">{{ mixinValidError.firstName }}</div>

  </div>


  <!-- LASTNAME ........................................................................................... -->

  <div v-if = "initSignupInputShow.lastName">

    <input type         = "text"
           class        = "input-text-3"
          :class        = "inputTextTheme1"
           v-model      = "initSignup.lastName"
          @focus        = "mixinTooltipsInputShowSimple('lastName')"
          @input        = "lastNameInput"
          @blur         = "lastNameBlur"
          :maxlength    = "mixinValidation_SharedNameSimple_inputMaxLength"
           autocomplete = "family-name"
           placeholder  = "Sukunimi">
<!--     <input type         = "text"
           class        = "input-text-3"
          :class        = "inputTextTheme1"
           v-model      = "initSignup.lastName"
          @focus        = "mixinTooltipsInputShowSimple('lastName')"
          @focusout     = "mixinTooltipsInputHide('lastName')"
          @input        = "mixinValidation_SharedNameSimple(initSignup.lastName, 'lastName')"
          @blur         = "mixinValidation_SharedNameSimple_Start(initSignup.lastName, 'lastName')"
          :maxlength    = "mixinValidation_SharedNameSimple_inputMaxLength"
           autocomplete = "family-name"
           placeholder  = "Sukunimi">
 -->
    <!-- TOOLTIPS MAIN -->
    <box-tooltips-input :tooltipsMainShow  = "mixinTooltipsInput.lastName"
                        :tooltipsMainText  = "mixinTooltipsInputText.lastName"
                        @current-box-close = "mixinTooltipsInputHide('lastName')"></box-tooltips-input>

    <!-- VALIDATION BOX -->
    <div :class="mixinClassVal.lastName">{{ mixinValidError.lastName }}</div>

  </div>


  <!-- IC NAME ................................................................................................ -->

  <div v-if = "initSignupInputShow.icName">

    <select class   = "input-text-3"
           :class   = "inputTextTheme1"
            v-model = "initSignup.icName"
           @blur    = "mixinValidation_SharedTextSimple_Start(initSignup.icName, 'icName')"
           @change  = "mixinValidation_SharedTextSimple_Start(initSignup.icName, 'icName')">
      <option v-for    = "icName in icNames"
             :value    = "icName.id"
             :key      = "icName.id"
             :disabled = "icName.disabled">{{ icName.name }}</option>
    </select>

    <!-- VALIDATION BOX -->
    <div :class="mixinClassVal.icName">{{ mixinValidError.icName }}</div>

  </div>


  <!-- PC NAME .................................................................................................... -->

  <div v-if = "initSignupInputShow.companyName">

    <input type         = "text"
           class        = "input-text-3"
          :class        = "inputTextTheme1"
           v-model      = "initSignup.companyName"
          @focus        = "mixinTooltipsInputShowSimple('companyName')"
          @focusout     = "mixinTooltipsInputHide('companyName')"
          @input        = "mixinValidation_companyName(initSignup.companyName)"
          @blur         = "mixinValidation_companyName_Start(initSignup.companyName)"
          :maxlength    = "mixinValidation_companyName_inputMaxLength"
           autocomplete = "organization"
           placeholder  = "Yrityksen nimi">

    <!-- TOOLTIPS MAIN -->
    <box-tooltips-input :tooltipsMainShow  = "mixinTooltipsInput.companyName"
                       :tooltipsMainText  = "mixinTooltipsInputText.companyName"
                       @current-box-close = "mixinTooltipsInputHide('companyName')"></box-tooltips-input>

    <!-- VALIDATION BOX -->
    <div :class="mixinClassVal.companyName">{{ mixinValidError.companyName }}</div>

  </div>


  <!-- PC ID ......................................................................................................... -->

  <div v-if = "initSignupInputShow.businessId">

    <input type        = "text"
           class       = "input-text-3"
          :class       = "inputTextTheme1"
           v-model     = "initSignup.businessId"
          @focus       = "mixinTooltipsInputShowSimple('businessId')"
          @focusout    = "mixinTooltipsInputHide('businessId')"
          @input       = "businessId_input"
          @blur        = "mixinValidation_businessId_Start(initSignup.businessId)"
           placeholder = "Y-tunnus">
          <!-- :maxlength   = "mixinValidation_businessId_inputMaxLength"  -->

    <!-- TOOLTIPS MAIN -->
    <box-tooltips-input :tooltipsMainShow  = "mixinTooltipsInput.businessId"
                        :tooltipsMainText  = "mixinTooltipsInputText.businessId"
                        @current-box-close = "mixinTooltipsInputHide('businessId')"></box-tooltips-input>

    <!-- VALIDATION BOX -->
    <div :class="mixinClassVal.businessId">{{ mixinValidError.businessId }}</div>

  </div>


  <!-- REG NUMBER ....................................................................................................... -->

  <!--
  <div v-if = "initSignupInputShow.registrationNumber">

    <input type        = "text"
           class       = "input-text-3"
          :class       = "inputTextTheme1"
          v-model      = "initSignup.registrationNumber"
          @focus       = "mixinTooltipsInputShowSimple('registrationNumber')"
          @focusout    = "mixinTooltipsInputHide('registrationNumber')"
          @input       = "registrationNumber_input"
          @blur        = "mixinValidation_registrationNumber_Start(initSignup.registrationNumber)"
           placeholder = "Rekisterinumero">

    <box-tooltips-input :tooltipsMainShow  = "mixinTooltipsInput.registrationNumber"
                        :tooltipsMainText  = "mixinTooltipsInputText.registrationNumber"
                        @current-box-close = "mixinTooltipsInputHide('registrationNumber')"></box-tooltips-input>

    <div :class="mixinClassVal.registrationNumber">{{ mixinValidError.registrationNumber }}</div>

  </div>
  -->


  <!-- EMAILADDRESS ....................................................................................................... -->

  <div>

    <input type         = "text"
           class        = "input-text-3"
          :class        = "inputTextTheme1"
           v-model      = "initSignup.emailAddress"
          @input        = "mixinValidation_emailAddress(initSignup.emailAddress)"
          @blur         = "mixinValidation_emailAddress_Start(initSignup.emailAddress)"
          :maxlength    = "mixinValidation_emailAddress_inputMaxLength"
           autocomplete = "email"
          :placeholder  = "emailAddressType">

    <!-- VALIDATION BOX -->
    <div :class="mixinClassVal.emailAddress">{{ mixinValidError.emailAddress }}</div>

  </div>


  <!-- PASSWORD ............................................................................................................ -->

  <div class="relative">

    <!-- Show/hide PASSWORD and RASSWORD REPEAT -->
    <span :class = "mixinClassIconEye"
          @click = "mixinPasswordShowHide"></span>

    <input :type        = "mixinPasswordType"
            class       = "input-text-3"
           :class       = "inputTextTheme1"
            v-model     = "initSignup.password"
           @input       = "password_input"
           @blur        = "password_blur"
           :maxlength   = "mixinValidation_password_inputMaxLength" 
            placeholder = "Salasana">

    <!-- TOOLTIPS MAIN -->
    <box-tooltips-input :tooltipsMainShow  = "mixinTooltipsInput.password"
                        :tooltipsMainText  = "mixinTooltipsInputText.password"
                        @current-box-close = "mixinTooltipsInputHide('password')"></box-tooltips-input>

    <!-- VALIDATION BOX -->
    <div :class="mixinClassVal.password">{{ mixinValidError.password }}</div>

  </div>


  <!-- PASSWORD REPEAT .................................................................................................... -->

  <div>

    <input :type         = "mixinPasswordType"
            class        = "input-text-3"
           :class        = "inputTextTheme1"
            v-model      = "initSignup.passwordRepeat"
           @input        = "mixinValidation_passwordRepeat(initSignup.passwordRepeat, initSignup.password)"
           @blur         = "mixinValidation_passwordRepeat_Start(initSignup.passwordRepeat, initSignup.password)"
           :maxlength    = "mixinValidation_passwordRepeat_inputMaxLength" 
            placeholder  = "Salasana uudelleen">

    <!-- VALIDATION BOX -->
    <div  :class="mixinClassVal.passwordRepeat">{{ mixinValidError.passwordRepeat }}</div>

  </div>

  <!-- reCAPTCHA V2 ................................. -->
  <!--
  <div>

    <div id           = "recaptcha"
         class        = "g-recaptcha"
        :data-sitekey = "recaptchaKey"></div>

    <div :class="mixinClassVal.recaptcha">{{ mixinValidError.recaptcha }}</div>

  </div>
  -->

  <!-- (reCAPTCHA V2 INVISIBLE) ...................... -->
  <!--
  <div id            = "recaptcha"
       class         = "g-recaptcha"
       data-sitekey  = "6LcH5f8UAAAAAFn0zH32FKWu7r1D9d6OFj2Gfaeh"
       data-callback = "onSubmit"
       data-size     = "invisible"></div>
  -->

  <!-- SUBMIT .......................................................................................... -->

    <!--
    <input type          = "button"
           class         = "g-recaptcha button-3"
          :class         = "buttonTheme1"
          @click         = "initSignupSubmit"
           data-sitekey  = "6LeQcP8UAAAAAD77IPQZ31IUGmMM0iy7crL35svZ"
           data-callback = 'onSubmit'
           data-action   = 'submit'
           value         = "Luo profiili">
     -->

    <!--
    <input type        = "button"
           class       = "button-3"
          :class       = "buttonTheme1"
          @click       = "initSignupSubmit"
           data-action = "signUpSubmit"
          :disabled    = "!submitAllow"
          :value       = "submitButtonValue">
     -->


    <!-- VALIDATION BOX from SERVER -->
    <box-validation-server-basic v-show            = "mixinValidationServerBasicShow"
                                :messages          = "mixinValidationServerBasicMessages"
                                @current-box-close = "mixinBoxValidationServerBasicClose"></box-validation-server-basic>


    <box-button-submit-main submitButtonValue = "Rekisteröidy"
                            dataAction        = "signUpSubmit"
                           :submitAllow       = "submitAllow"
                           @submit-main       = "initSignupSubmit"></box-button-submit-main>

    <box-ic-names v-if         = "initSignupInputShow.icName"
                  ref          = "icNamesRef"
                 :active       = "true"
                 :pp           = "'whatever'"
                 :pc           = "'whatever'"
                 @ic-names-get = "icNames_get"></box-ic-names>

  </form>

  </div>

</div>

</template>


<script> // ----------------------------------------------------------------------------------------------------------

import mixinValidationCommon              from '@/mixins/mixinValidation/mixinValidationCommon'
import mixinValidation_SharedTextSimple   from '@/mixins/mixinValidation/mixinValidation_SharedTextSimple'
import mixinValidation_SharedNameSimple   from '@/mixins/mixinValidation/mixinValidation_SharedNameSimple'
import mixinValidation_companyName        from '@/mixins/mixinValidation/mixinValidation_companyName'
import mixinValidation_businessId         from '@/mixins/mixinValidation/mixinValidation_businessId'
// import mixinValidation_registrationNumber from '@/mixins/mixinValidation/mixinValidation_registrationNumber'
import mixinValidation_emailAddress       from '@/mixins/mixinValidation/mixinValidation_emailAddress'
import mixinValidation_password           from '@/mixins/mixinValidation/mixinValidation_password'
import mixinValidation_passwordRepeat     from '@/mixins/mixinValidation/mixinValidation_passwordRepeat'
import mixinValidation_recaptcha          from '@/mixins/mixinValidation/mixinValidation_recaptcha'

import mixinXhrPostAsyncApp          from '@/mixins/mixinXhrPostAsyncApp'
import mixinTooltipsInput            from '@/mixins/mixinTooltipsInput'
import mixinPasswordShowHide         from '@/mixins/mixinPasswordShowHide'
import mixinBoxValidationServerBasic from '@/mixins/mixinBoxValidationServerBasic'
import mixinTrim                     from '@/mixins/mixinTrim'
import mixinWhitespaceRemove         from '@/mixins/mixinWhitespaceRemove'
// import mixinIcNames                  from '@/mixins/mixinIcNames'
// import mixinRecaptcha                from '@/mixins/mixinRecaptcha'

import boxValidationServerBasic from '@/components/boxValidationServerBasic'
import boxTooltipsInput         from '@/components/boxTooltipsInput'
import boxButtonSubmitMain      from '@/components/boxButtonSubmitMain'
import boxIcNames               from '@/components/boxIcNames'
// import boxTooltipsInfo          from '@/components/boxTooltipsInfo'


export default {
  name:        'itemSignUpForm',
  mixins:     [
                mixinXhrPostAsyncApp,
                mixinTooltipsInput,
                mixinPasswordShowHide,
                mixinBoxValidationServerBasic,
                mixinValidationCommon,
                mixinValidation_SharedTextSimple,
                mixinValidation_SharedNameSimple,
                mixinValidation_companyName,
                mixinValidation_businessId,
                mixinValidation_emailAddress,
                mixinValidation_password,
                mixinValidation_passwordRepeat,
                mixinValidation_recaptcha,
                mixinTrim,
                mixinWhitespaceRemove
                // mixinValidation_registrationNumber,
                // mixinIcNames,
                // mixinRecaptcha
              ],
  components: {
                boxValidationServerBasic,
                boxTooltipsInput,
                boxButtonSubmitMain,
                boxIcNames
                // boxTooltipsInfo
              },
  props:      [
               'parentType', // OPTIONAL: to make some changes according to parent type
               'currentPageType' // OPTIONAL: detect opened current page
              ],
  emits:      [
               'current-box-close',
               'sign-up-successful-show'
              ],


  data () {

    return {

      //buttonTheme2:    'button-theme-2',
      buttonTheme1:    'button-theme-1',
      inputTextTheme1: 'input-text-theme-1',

      signUpFormClass:       'windowed-2-medium',
      signUpFormTopClass:    'box-2-top', // init-sign_up-nested-top
      signUpFormMiddleClass: 'box-2-middle', // init-sign_up-nested-middle

      initSignupButtonTitle: 'Luo henkilöasiakkaan profiili',
      emailAddressType:      'Sähköpostiosoite',

      submitAllow: true, // Prevent multiclicking of Submit

      // To show or hide INPUTS
      initSignupInputShow: {
        firstName:          false,
        lastName:           false,
        icName:             false,
        companyName:        false,
        businessId:         false,
        // registrationNumber: false
      },

      signUpUserType: '',
      icNames: [], //

      // VALUES of INPUTS
      initSignup: {
        firstName:          '',
        lastName:           '',
        icName:             '',
        companyName:        '',
        businessId:         '',
        emailAddress:       '',
        password:           '',
        passwordRepeat:     '',
        recaptcha:          '',
        // registrationNumber: '',
      },

      // passwordType:          'password', // SHOW/HIDE input symbols
      // submitButtonValue: 'Rekisteröidy';
      // recaptchaKey: '6LdAQ9QUAAAAANg-HWcVz7XkmDhQC9XfTleWIWlU',

    }

  }, // data

  // ===========================================================================================================
  created () {

    this.created();

  },


  // ===========================================================================================================
  mounted () {

    //this.mixinRecaptchaRender('recaptcha', this.recaptchaKey);
    //this.$nextTick( () => { grecaptcha.render('recaptcha'); } );
    //grecaptcha.render(document.getElementById('recaptcha'));
    //grecaptcha.render(document.getElementById('recaptcha'), { 'sitekey' : '6LcH5f8UAAAAAFn0zH32FKWu7r1D9d6OFj2Gfaeh' });
    //this.$nextTick(() => { grecaptcha.render( 'recaptcha'/*, { 'sitekey' : '6LdAQ9QUAAAAANg-HWcVz7XkmDhQC9XfTleWIWlU', 'callback' : this.captchaSubmit() }*/ ); }); // (reCAPTCHA V2 INVISIBLE)

  }, // mounted


  // ===========================================================================================================
  beforeUnmount () {

    //this.mixinRecaptchaReset('');
    //this.$nextTick( () => { grecaptcha.reset('recaptcha'); } );
    //grecaptcha.reset(document.getElementById('recaptcha'), { 'sitekey' : '6LcH5f8UAAAAAFn0zH32FKWu7r1D9d6OFj2Gfaeh' });
    //grecaptcha.reset(document.getElementById('recaptcha'));
    //this.$refs.recaptcha.reset();
    //this.$nextTick( () => { grecaptcha.reset(); } );

  }, // beforeUnmount


  // ===========================================================================================================
  methods: {


    //--- -----------------------------------------------------------------
    created () {

      // Need to copy here because it can be changed at "Asiakasryhmä".
      // "signUpUserType" is in use for sending right form to SERVER
      this.signUpUserType = this.$store.state.userTypeInit;

      this.signUpFormSet();

      if (this.parentType == 'nested') {
        this.signUpFormClass       = 'windowed-3';
        this.signUpFormTopClass    = 'init-sign_up-nested-top';
        this.signUpFormMiddleClass = 'init-sign_up-nested-middle';
       //this.initSignupButtonTitle = 'Luo profiili';
      }

      // this.mixinValidationServerBasicMessages = ['Ensimmäinen virhe', 'Joku toinen vakavampi virhe'];

      //this.$nextTick(function () {grecaptcha.render("recaptcha", {'theme': 'dark'} );}); // Render reCAPTCHa

    },

    
    //--- -----------------------------------------------------------------
    icNames_get (obj) {

      // Add first default value
      this.icNames.push({
        id:       '', // Have to be empty
        name:     'Edustamasi yhtiö',
        disabled: true
      });
      
      // Add ic names
      obj.forEach(
        value => {
          this.icNames.push({
            id:   value.id,
            name: value.name,
            disabled: false
          });
        }
      );

      // console.log('icNames_get done');

    },


    //--- -----------------------------------------------------------------
    password_input () {

      this.mixinTooltipsInputShowSimple('password');
      this.mixinValidation_password(this.initSignup.password, this.initSignup.passwordRepeat);

    },


    //--- -----------------------------------------------------------------
    password_blur () {

      this.mixinTooltipsInputHide('password');
      this.mixinValidation_password_Start(this.initSignup.password, this.initSignup.passwordRepeat);

    },


    //--- -----------------------------------------------------------------
    firstNameInput () {

      this.mixinTooltipsInputShowSimple('firstName');
      this.mixinValidation_SharedNameSimple(this.initSignup.firstName, 'firstName');

    },


    //--- -----------------------------------------------------------------
    firstNameBlur () {

      this.mixinTooltipsInputHide('firstName');
      this.mixinValidation_SharedNameSimple_Start(this.initSignup.firstName, 'firstName');

    },


    //--- -----------------------------------------------------------------
    lastNameInput () {

      // !: For "Tooltips" FOCUS in use also (to work with autocomplete)
      this.mixinTooltipsInputShowSimple('lastName');
      this.mixinValidation_SharedNameSimple(this.initSignup.lastName, 'lastName');

    },


    //--- -----------------------------------------------------------------
    lastNameBlur () {

      this.mixinTooltipsInputHide('lastName');
      this.mixinValidation_SharedNameSimple_Start(this.initSignup.lastName, 'lastName');

    },


    //--- -----------------------------------------------------------------
    /*
    registrationNumber_input (event) {

      // console.log('registrationNumber_input FIRES');
      this.initSignup.registrationNumber = this.mixinWhitespaceRemove(event.target.value);
      this.mixinValidation_registrationNumber(this.initSignup.registrationNumber);

    },
    */


    //--- -----------------------------------------------------------------
    businessId_input (event) {

      this.initSignup.businessId = this.mixinWhitespaceRemove(event.target.value);
      this.mixinValidation_businessId(this.initSignup.businessId);

    },


    //--- Set FORM according to user type --------------------------------------------------------------
    signUpFormSet () {

      switch (this.signUpUserType) { // Show or hide inputs for current Signup
        case 'pp':
              this.initSignupButtonTitle                  = 'Luo henkilöasiakkaan profiili';
              this.emailAddressType                       = 'Sähköpostiosoite';
              this.initSignupInputShow.firstName          = true;
              this.initSignupInputShow.lastName           = true;
              this.initSignupInputShow.icName             = false;
              this.initSignupInputShow.companyName        = false;
              this.initSignupInputShow.businessId         = false;
              // this.initSignupInputShow.registrationNumber = false;
              break;
        case 'pc':
              this.initSignupButtonTitle                  = 'Luo yritysasiakkaan profiili';
              this.emailAddressType                       = 'Sähköpostiosoite';
              this.initSignupInputShow.firstName          = false;
              this.initSignupInputShow.lastName           = false;
              this.initSignupInputShow.icName             = false;
              this.initSignupInputShow.companyName        = true;
              this.initSignupInputShow.businessId         = true;
              // this.initSignupInputShow.registrationNumber = false;
              break;
        case 'ir':
        case 'ic':
              this.initSignupButtonTitle                  = 'Luo vakuutusasiamiehen profiili';
              if (this.signUpUserType == 'ic') this.initSignupButtonTitle = 'Luo vakuutusyhtiön profiili';
              this.emailAddressType                       = 'Työsähköpostiosoite';
              this.initSignupInputShow.firstName          = true;
              this.initSignupInputShow.lastName           = true;
              this.initSignupInputShow.icName             = true;
              this.initSignupInputShow.companyName        = false;
              this.initSignupInputShow.businessId         = false;
              // this.initSignupInputShow.registrationNumber = true;
              break;
        case '':
              break;
        default: alert('ERROR! itemSignup -> userTypeInit: no value found');
      }

    },


    //--- Create from SERVER response user friendly ERROR MSGs -------------------------------------------------------------
    xhrPostAsyncAppResponseMsgs (response) {

      if (response.succeeded == true)  {
        // this.initSignupResetDefault();
        Object.assign(this.$data, this.$options.data.apply(this)); // RESET all values
        this.created();
        if (this.initSignupInputShow.icName) this.$refs.icNamesRef.submitMain();
        this.$emit('sign-up-successful-show'); // Switch to Msg "Registration was successful"
      }
      else if (response.succeeded == false) {
        this.mixinValidationServerBasicShow = true; // SHOWs validation box
        this.mixinValidationServerBasicMessages.push(response.summaryMessage); // this.mixinValidationServerBasicMessages.push(this.$store.state.msg.responseFalse);
        //if (response.messageMap) { for (let msg in response.messageMap) { this.mixinValidationServerBasicMessages.push(response.messageMap[msg]); } }
      }
      // else { alert('ERROR! itemSignUpForm: xhrPostAsyncAppResponseMsgs: check B responce parameters.'); }

      this.submitAllow = true;

    },


    //--- Submit INPUTs and sent its to the SERVER -----------------------------------------------------
    async initSignupSubmit () {

      if (this.submitAllow) {

        this.mixinBoxValidationServerBasicClose(); // RESET ERROR msgs from SERVER

        var messageCheckAllInputs = () => { // If some INPUTS are empty or there is any validError msg
          //this.recaptchaValidationReset(); // Reset reCaptcha VALIDATION
          this.mixinIsEmptyBeforeSubmit(this.initSignup); // Show error MSG at empty INPUTs
          for (let type in this.initSignupInputShow) { // Delete errors of unusing INPUTS
            if (this.initSignupInputShow[type] == 'none') {
              this.mixinValidError[type] = '';
              this.mixinClassVal[type]   = '';
            }
          }
          // TODO: Find out better solution for reseting reCaptcha's VALID ERRORs
          //if (this.mixinValidError.recaptcha) { setTimeout( () => this.recaptchaValidationReset(), 2000 ); }

          flagExit = true; // Do not allow to contunue request
        }

        var url      = 'luoProfiili'; // URL address for HTTPXMLrequest in mixinXhrPostAsyncApp
        var header   = 'app';
        var obj      = ''; // Request to SERVER

        var v        = this.initSignup; // Var "v" is used several times
        var e        = this.mixinValidError; // Var "e" is used several times
        var flagExit = false; // FLAG: prevent of making request to SERVER, if smth wrong

        v.recaptcha = ''; // Reset reCAPTCHA value

        this.mixinTrimObject(v); // To get rid of spaces from start/end of the strings

        //await this.$recaptchaLoaded(); // (optional) Wait until recaptcha has been loaded.
        //v.recaptcha = await this.$recaptcha('signUpSubmit'); // Execute reCAPTCHA with action "submit".
        //alert(v.recaptcha);

        //grecaptcha.execute();
        //console.log(v.recaptcha);
        //grecaptcha.reset();
        //console.log('SUBMIT start');

        switch (this.signUpUserType) { // Switch sent info according to userTypeInit

          case 'pp': //-------------------------------
                if (v.firstName
                &&  v.lastName
                &&  v.emailAddress
                &&  v.password
                &&  v.passwordRepeat
                && !e.firstName
                && !e.lastName
                && !e.emailAddress
                && !e.password
                && !e.passwordRepeat) {

                  await this.recaptchaExecute();

                  if (v.recaptcha) {

                    this.submitAllow = false;

                    obj = 'privateClientFirstName='     + encodeURIComponent(v.firstName)    +
                          '&privateClientLastName='     + encodeURIComponent(v.lastName)     +
                          '&privateClientEmailAddress=' + encodeURIComponent(v.emailAddress) +
                          '&privateClientPassword='     + encodeURIComponent(v.password)     +
                          '&g-recaptcha-response='      + v.recaptcha                        +
                          '&profileType='               + 'private_person';
                  }
                  else { this.mixinValidation_recaptcha(v.recaptcha); }

                }
                else { messageCheckAllInputs(); }
                break;

          case 'pc': //-------------------------------
                if (v.companyName
                &&  v.businessId
                &&  v.emailAddress
                &&  v.password
                &&  v.passwordRepeat
                && !e.companyName
                && !e.businessId
                && !e.emailAddress
                && !e.password
                && !e.passwordRepeat) {

                  await this.recaptchaExecute();

                  if (v.recaptcha) {

                    this.submitAllow = false;

                    obj = 'privateCompanyName='          + encodeURIComponent(v.companyName)  + // privateCompanyName
                          '&privateCompanyBusinessId='   + encodeURIComponent(v.businessId)   + // privateCompanyRegistrationNumber
                          '&privateCompanyEmailAddress=' + encodeURIComponent(v.emailAddress) + // privateCompanyEmailAddress
                          '&privateCompanyPassword='     + encodeURIComponent(v.password)     + // privateCompanyPassword
                          '&g-recaptcha-response='       + v.recaptcha                        +
                          '&profileType='                + 'private_company';
                  }
                  else { this.mixinValidation_recaptcha(v.recaptcha); }

                }
                else {
                  messageCheckAllInputs();
                }
                break;

          case 'ir': //-------------------------------
                if (v.firstName
                &&  v.lastName
                &&  v.icName
                /* &&  v.registrationNumber */
                &&  v.emailAddress
                &&  v.password
                &&  v.passwordRepeat
                && !e.firstName
                && !e.lastName
                && !e.icName
                /* && !e.registrationNumber */
                && !e.emailAddress
                && !e.password
                && !e.passwordRepeat) {

                  await this.recaptchaExecute();

                  if (v.recaptcha) {

                    this.submitAllow = false;

                    obj = 'insuranceCompanyRepresentativeFirstName='           + encodeURIComponent(v.firstName)          +
                          '&insuranceCompanyRepresentativeLastName='           + encodeURIComponent(v.lastName)           +
                          '&insuranceCompanyRepresentativeCompanyId='          + v.icName                                 +
                          /* '&insuranceCompanyRepresentativeRegistrationNumber=' + encodeURIComponent(v.registrationNumber) + */
                          '&insuranceCompanyRepresentativeEmailAddress='       + encodeURIComponent(v.emailAddress)       +
                          '&insuranceCompanyRepresentativePassword='           + encodeURIComponent(v.password)           +
                          '&g-recaptcha-response='                             + v.recaptcha                              +
                          '&profileType='                                      + 'insurance_representative';
                  }
                  else { this.mixinValidation_recaptcha(v.recaptcha); }

                }
                else {
                  messageCheckAllInputs();
                }
                break;

          case 'ic': //-------------------------------
                alert('ERROR! itemSignUpForm: IC asiakastyyppi ei ole kytkety!');
                /*
                if (v.firstName
                &&  v.lastName
                &&  v.icName
                &&  v.registrationNumber
                &&  v.emailAddress
                &&  v.password
                &&  v.passwordRepeat
                && !e.firstName
                && !e.lastName
                && !e.icName
                && !e.registrationNumber
                && !e.emailAddress
                && !e.password
                && !e.passwordRepeat) {

                  await this.recaptchaExecute();

                  if (v.recaptcha) {

                    this.submitAllow = false;

                    obj = 'insuranceCompanyEmployeeFirstName='           + v.firstName          +
                          '&insuranceCompanyEmployeeLastName='           + v.lastName           +
                          '&insuranceCompanyEmployeeCompanyName='        + v.icName             +
                          '&insuranceCompanyEmployeeRegistrationNumber=' + v.registrationNumber +
                          '&insuranceCompanyEmployeeEmailAddress='       + v.emailAddress       +
                          '&insuranceCompanyEmployeePassword='           + v.password           +
                          '&g-recaptcha-response='                       + v.recaptcha          +
                          '&profileType='                                + 'insurance_company';
                  }
                  else { this.mixinValidation_recaptcha(v.recaptcha); }

                }
                else {
                  messageCheckAllInputs();
                }
                */
                break;

          default: alert('ERROR! itemSignUpForm: userTypeInit: no value found'); flagExit = true;

        } //-------------------------------

        if (flagExit == false) this.mixinXhrPostAsyncApp(obj, url, header); // IF obj is formed properly

      }

    },


    //--- RECAPTCHA ------------------------------------------------------------------------------------
    async recaptchaExecute() {

      await this.$recaptchaLoaded(); // (optional) Wait until recaptcha has been loaded.
      this.initSignup.recaptcha = await this.$recaptcha('signUpSubmit'); // Execute reCAPTCHA with action "submit".

    },


    //--- RESET all values and DATA after SIGN UP window is closed -------------------------------------
    // initSignupResetDefault () {},


  }, // methods


} // default

</script>

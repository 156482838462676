
import mixinValidation_password_regexs from '@/mixins/mixinValidation/mixinValidation_password_regexs'

/*
ATTENTION! Do not forget to define VALIDATION rules in documentation:
https://insured.atlassian.net/wiki/spaces/VAKUUTUSTI/pages/290947073/Validation+rules+F+B
*/

export default {


  mixins: [ mixinValidation_password_regexs ],
  

  data () {
    return {

      mixinValidation_password_inputMaxLength: 30,

      mixinFlagAllowValidate:  { password:  false         }, /*mixinValidationAllows*/     // Stop from VALIDATION at the first INPUT editing
      mixinValidError:         { password: ''             }, /*mixinValidationErrorTexts*/ // ERROR MSG of VALIDATION
      mixinClassVal:           { password: 'display-hide' }, /*mixinValidationClasses*/    // SHOW/HIDE ERROR BOX
      mixinValidationOptional: { password:  false         },                               // For making INPUT as an OPTIONAL:

    } // return
  }, // data


  methods: {
		
    //---  -----------------------------------------------------------------------------------------------
    mixinValidation_password (inputValue, passwordRepeatValue/*, passwordOtherType*/) {

      var inputType = 'password'; //var inputType = this.mixinValidation_password_inputTypeSet(passwordOtherType);

      // Need to allow VALIDATION after finding empty INPUTS at pushing "SUBMIT" button 
      if (this.mixinValidError[inputType]) this.mixinFlagAllowValidate[inputType] = true;

      if (this.mixinFlagAllowValidate[inputType]) {
        this.mixinValidation_password_Check(inputValue, passwordRepeatValue/*, passwordOtherType*/);
        this.mixinValidationErrorBoxShowHide(inputType);
      }

    },

    //--- Validation starts only after user has filles INPUT for the first time ---------------------------
    mixinValidation_password_Start (inputValue, passwordRepeatValue/*, passwordOtherType*/) {

      var inputType = 'password'; //var inputType = this.mixinValidation_password_inputTypeSet(passwordOtherType);

      //this.mixinValidationCommon_ValueAdd(inputType); // In use only for SHARED mixins

      this.mixinFlagAllowValidate[inputType] = true;
      this.mixinValidation_password(inputValue, passwordRepeatValue/*, passwordOtherType*/);

    },

    //-----------------------------------------------------------------------------------------------------
    //--- INNER WORKER ------------------------------------------------------------------------------------
    mixinValidation_password_Check (inputValue, passwordRepeatValue/*, passwordOtherType*/) {

      var inputType = 'password'; //var inputType = this.mixinValidation_password_inputTypeSet(passwordOtherType);

      if (inputValue) {

        if (this.mixinValidation_password_regexTotal.test(inputValue)) { // "password": testing with general rules regex:

        if (passwordRepeatValue) {
         if (this.mixinValidation_password_regexTotal.test(passwordRepeatValue)) {
            if (inputValue == passwordRepeatValue) { // If "passwordRepeatValue" was already filled
              this.mixinValidationCommon_ErrorMsgReset(inputType);
            }
            else {
              this.mixinValidError[inputType] = 'Salasanat eivät täsmää';
            }
            // RESET 'passwordRepeat'
            this.mixinValidationErrorBoxShowHide('passwordRepeat');
            this.mixinValidationCommon_ErrorMsgReset('passwordRepeat');
          }
          else {
            this.mixinValidationCommon_ErrorMsgReset(inputType); // RESET "password"
            this.mixinValidError.passwordRepeat = 'Tarkista salasana'; // SET error to "passwordRepeat"
          }
        }
        else {
          this.mixinValidationCommon_ErrorMsgReset(inputType);
        }

        }
        else {
          this.mixinValidError[inputType] = 'Tarkista salasana';
        }

      }
      else { // If empty
        this.mixinValidationErrorIfEmpty(inputType);
      }
  
    },
    
    /*
    //---   ----------------------------------------------------------------------------
    mixinValidation_password_inputTypeSet (passwordOtherType) {
      if (passwordOtherType) { return passwordOtherType; } else { return 'password'; }
    }
    */

  } // methods

}




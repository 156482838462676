
export default {

  /*
  ATTENTION! Do not forget to define VALIDATION rules in documentation:
  https://insured.atlassian.net/wiki/spaces/VAKUUTUSTI/pages/290947073/Validation+rules+F+B
  */

  data () {
    return {

      // mixinValidation_businessId_inputMaxLength: 9,

      mixinFlagAllowValidate:  { businessId:  false         }, /*mixinValidationAllows*/     // Stop from VALIDATION at the first INPUT editing
      mixinValidError:         { businessId: ''             }, /*mixinValidationErrorTexts*/ // ERROR MSG of VALIDATION
      mixinClassVal:           { businessId: 'display-hide' }, /*mixinValidationClasses*/    // SHOW/HIDE ERROR BOX
      mixinValidationOptional: { businessId:  false         },                               // For making INPUT as an OPTIONAL:

    } // return
  }, // data


  methods: {
		
    //---  -----------------------------------------------------------------------------------------------
    mixinValidation_businessId (inputValue) { 

      var inputType = 'businessId';

      // Need to allow VALIDATION after finding empty INPUTS at pushing "SUBMIT" button 
      if (this.mixinValidError[inputType]) this.mixinFlagAllowValidate[inputType] = true;

      if (this.mixinFlagAllowValidate[inputType]) {
        this.mixinValidation_businessId_Check(inputValue);
        this.mixinValidationErrorBoxShowHide(inputType);
      }

    },

    //--- Validation starts only after user has filles INPUT for the first time ---------------------------
    mixinValidation_businessId_Start (inputValue) {

      var inputType = 'businessId';

      this.mixinFlagAllowValidate[inputType] = true;
      this.mixinValidation_businessId(inputValue);

    },
 
    //-----------------------------------------------------------------------------------------------------
    //--- INNER WORKER ------------------------------------------------------------------------------------
    mixinValidation_businessId_Check (inputValue) {

      var inputType = 'businessId';

      var validRegex = (value) => {
        
        // 7 numbers, then "-", then 1 number
        var re = /^\s*[0-9]{7}[-][0-9]{1}\s*$/gm;

        if (re.test(value)) {

          let weights = [ 7, 9, 10, 5, 8, 4, 2 ];
          let numbers = value.split('').slice(0,7);
          let lastOne = value.slice(-1);
          let sum     = 0;

          for ( let i = 0; i < 7; i++ ) { sum += numbers[i] * weights[i]; }

          let remainder = sum % 11;

          if (remainder == 0) {
            if (remainder == lastOne) { return true; } else { return false; } 
          }
          else if (remainder == 1) {
            return false;
          }
          else if (remainder > 1 && remainder < 11) {
            if (11 - remainder == lastOne) { return true; } else { return false; } 
          }
          else {
            alert('ERROR! mixinValidation_businessId: validRegex: remainder is ' + remainder + '. Should be from 0 to 10.' );
            return false;
          }

        }

      }

      if (!inputValue) { // If empty
        this.mixinValidationErrorIfEmpty(inputType);
      } 
      else {
        if (!validRegex(inputValue)) {
          this.mixinValidError[inputType] = 'Tarkista Y-tunnus';
        }
        else {
          this.mixinValidationCommon_ErrorMsgReset(inputType);
        }
      }
  
    },

  } // methods

}




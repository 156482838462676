<template>
<div>

  <div class="client-container info">

    <!-- NAVBAR .............................................................................. -->
    <item-init-nav-bar parentType="pageInfo"></item-init-nav-bar>

    <!-- TITLE ............................................................................... -->
    <div class="info-box-title-main">
      <div class="info-box-main">
        <div><h1 class="title-h1-bigger theme-light-title-1">Käyttöehdot</h1></div>
      </div>
    </div>

    <!-- MAIN ................................................................................ -->
    <div class="info-box-main-two_rows info-box-2">

      <div class="info-terms_of_use-sidebar">
        <div @click="navBarBookmarkGoTo('userTypePp')" :class="userTypeClassActive.pp">Henkilö&shy;asiakkaille</div>
        <div @click="navBarBookmarkGoTo('userTypePc')" :class="userTypeClassActive.pc">Yritys&shy;asiakkaille</div>
        <div @click="navBarBookmarkGoTo('userTypeIr')" :class="userTypeClassActive.ir">Vakuutus&shy;asiamiehille</div>
      </div>

      <div class="info-terms_of_use-1_2">

        <!-- PP ... -->
        <div>
          <div ref="userTypePp"><h3>Henkilöasiakkaille</h3></div>
          <div><p>Käyttämällä palveluamme (näissä ehdoissa "palvelu" tai "Vakuutustiedot.fi") hyväksyt palvelumme käyttöehdot.</p></div>
          <ol>
            <li v-for="(rule, index) in mixinData_rulesGeneral_pp" :key="'rulesGeneral-pp-' + index">{{ rule }}</li>
          </ol>
        </div>

        <!-- PC ... -->
        <div>
          <div ref="userTypePc"><h3>Yritysasiakkaille</h3></div>
          <div><p>Käyttämällä palveluamme (näissä ehdoissa "palvelu" tai "Vakuutustiedot.fi") hyväksyt palvelumme käyttöehdot.</p></div>
          <ol>
            <li v-for="(rule, index) in mixinData_rulesGeneral_pc" :key="'rulesGeneral-pc-' + index">{{ rule }}</li>
          </ol>
        </div>

        <!-- IR ... -->
        <div>
          <div ref="userTypeIr"><h3>Vakuutusasiamiehille</h3></div>
          <div><p>Käyttämällä palveluamme (näissä ehdoissa "palvelu" tai "Vakuutustiedot.fi") hyväksyt palvelumme käyttöehdot.</p></div>
          <ol>
            <li v-for="(rule, index) in mixinData_rulesGeneral_ir" :key="'rulesGeneral-ir-' + index">{{ rule }}</li>
          </ol>
        </div>

      </div>
    
    </div>

  </div>

  <!-- FOOTER ................................................................................ -->
  <item-footer-main></item-footer-main>


  <!-- Just add this whre you need to warch for user's ativity .................................. -->
  <!-- On this page it has to turn this "activity watvhing" on only if user was signed in -->
  <box-user-keep-active v-if="$store.state.userType!=''"></box-user-keep-active>


</div>
</template>


<script> // -------------------------------------------------------------------------------------------------------

import itemInitNavBar    from '@/components/pageInit/itemInitNavBar'
import itemFooterMain    from '@/components/itemFooterMain'
import boxUserKeepActive from '@/components/boxUserKeepActive'

import mixinData_rulesGeneral_pp from '@/mixins/mixinData/mixinData_rulesGeneral_pp'
import mixinData_rulesGeneral_pc from '@/mixins/mixinData/mixinData_rulesGeneral_pc'
import mixinData_rulesGeneral_ir from '@/mixins/mixinData/mixinData_rulesGeneral_ir'



export default {
  name:        'pageInfoTermsOfUse',
  components: {
                itemInitNavBar,
                itemFooterMain,
                boxUserKeepActive
              },
  mixins:     [
                mixinData_rulesGeneral_pp,
                mixinData_rulesGeneral_pc,
                mixinData_rulesGeneral_ir
              ],


  data () {

    return {

      userTypeOffsetTop: {
        pp: 0,
        pc: 0,
        ir: 0
      },

      userTypeClassActive: {
        pp: '',
        pc: '',
        ir: ''
      }

    } // return

  }, // data


  // ===========================================================================================================
  created () {

    window.addEventListener('scroll', this.handleScroll);

  
  }, // created

  // ===========================================================================================================
  mounted () {

    this.userTypeOffsetTop.pp = this.$refs.userTypePp.offsetTop;
    this.userTypeOffsetTop.pc = this.$refs.userTypePc.offsetTop;
    this.userTypeOffsetTop.ir = this.$refs.userTypeIr.offsetTop;
    
    //console.log(this.userTypeOffsetTop.pp);
    //console.log(this.userTypeOffsetTop.pc);
    //console.log(this.userTypeOffsetTop.ir);

  }, // mounted


  // ===========================================================================================================
  unmounted () {
  
    window.removeEventListener('scroll', this.handleScroll);
  
  }, // unmounted


  // ===========================================================================================================
  methods: {

    //---  -------------------------------------------------
    navBarBookmarkGoTo (bookmark) {
      var toTop = this.$refs[bookmark].offsetTop + 150; // "ref" do not want work here
      //var toTop = document.getElementById(bookmark).offsetTop + 150; // "ref" do not want work here
      window.scrollTo({ top: toTop, left: 0, behavior: 'smooth' });
    },

    //---  -------------------------------------------------
    handleScroll () {

      var windowOffsetTop = window.pageYOffset;
      // console.log(windowOffsetTop);
      this.userTypeClassActiveReset();

      if (windowOffsetTop < this.userTypeOffsetTop.pc) {
        this.userTypeClassActive.pp = "info-box-sidebar-main-link-active";
      }
      else if (windowOffsetTop < this.userTypeOffsetTop.ir) {
        this.userTypeClassActive.pc = "info-box-sidebar-main-link-active";
      }
      else {
        this.userTypeClassActive.ir = "info-box-sidebar-main-link-active";
      }

    },

    //---  -------------------------------------------------
    userTypeClassActiveReset () {
      for (const type in this.userTypeClassActive) {
          this.userTypeClassActive[type] = '';
        }
    }

  }, // methods

}
</script>
<template>
<div>

  <div class="client-container info">

    <!-- NAVBAR .............................................................................. -->
    <item-init-nav-bar parentType="pageInfo"></item-init-nav-bar>

    <!-- TITLE ............................................................................... -->
    <div class="info-box-title-main">
      <div class="info-box-main">
        <div><h1 class="title-h1-bigger theme-light-title-1">Rekisteri- ja tietosuoja&shy;seloste</h1></div>
      </div>
    </div>

    <!-- MAIN ................................................................................ -->
    <div class="info-box-main info-box-1">

      <div class="info-privacy-policy-1">

        <p>Tämä on Insured Oy:n henkilötietolain (10 ja 24 §) ja EU:n yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja tietosuojaseloste. Laadittu 2.5.2020. Viimeisin muutos 8.10.2021.</p>
        
        <h4>1. Rekisterinpitäjä</h4>
        <p>Insured Oy, Opastinsilta 6 A, 00520 Helsinki</p>

        <h4>2. Rekisteristä vastaava yhteyshenkilö</h4>
        <p>Anton Tolla, anton@vakuutustiedot.fi, 041 368 2034</p>

        <h4>3. Rekisterin nimi</h4>
        <p>www.vakuutustiedot.fi verkkopalvelun käyttäjärekisteri</p>

        <h4>4. Oikeusperuste ja henkilötietojen käsittelyn tarkoitus</h4>
        <p>EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle on henkilön antama suostumus, hyväksyessään palvelumme käyttöehdot. Henkilötietojen käsittelyn tarkoitus on mahdollistaa vakuutusten kilpailuttaminen palvelumme välityksellä, yhteydenpito asiakkaisiin sekä asiakassuhteen ylläpito.</p>

        <h4>5. Rekisterin tietosisältö</h4>
        <p>Rekisteriin tallennettavia tietoja ovat: henkilön nimi, yritys/yhdistys, rekisterinumero, asiamiehen edustama vakuutusyhtiön nimi, puhelinnumero, sähköpostiosoite, osoite, syntymäaika, tiedot palveluun lisätyistä vakuutustiedoista ja niiden muutoksista. Tietoja säilytetään palvelussa kunnes käyttäjä poistaa oman profiilinsa, tai saamme vahvistetun pyynnön profiilin poistamisesta. Voimme myös poistaa käyttäjän tiedot ilman hänen omaa pyyntöään, jos käyttöehtojen vastaista toimintaa ilmenee. Tietojen mahdollisesta poistamisesta lähetetään sähköposti-ilmoitus vähintään viikkoa ennen poistoa.</p>

        <h4>6. Säännönmukaiset tietolähteet</h4>
        <p>Rekisteriin tallennettavat tiedot saadaan asiakkaalta sivustollamme olevien www-lomakkeiden kautta lähetetyistä viesteistä, joissa asiakas luovuttaa tietojaan.</p>

        <h4>7. Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai ETA:n ulkopuolelle</h4>
        <p>Emme luovuta käyttäjien tietoja muille tahoille. Käyttäjän tietoja voidaan luovuttaa ainoastaan poikkeustapauksissa Suomen viranomaisille, viranomaisen tekemän oikeuspäätöksen perusteella. Tietoja ei luovuteta EU:n tai ETA:n ulkopuolelle.</p>

        <h4>8. Rekisterin suojauksen periaatteet</h4>
        <p>Rekisterin käsittelyssä noudatetaan huolellisuutta ja tietojärjestelmien avulla käsiteltävät tiedot suojataan asianmukaisesti. Käytämme ainoastaan internet-palvelimia, joiden laitteiston fyysisestä ja digitaalisesta tietoturvasta huolehditaan asiaankuuluvasti. Rekisterinpitäjä huolehtii siitä, että tallennettuja tietoja sekä palvelimien käyttöoikeuksia ja muita henkilötietojen turvallisuuden kannalta kriittisiä tietoja käsitellään luottamuksellisesti ja vain niiden työntekijöiden toimesta, joiden työnkuvaan se kuuluu.</p>

        <h4>9. Tarkastusoikeus ja oikeus vaatia tiedon korjaamista</h4>
        <p>Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa rekisteriin tallennetut tietonsa ja vaatia mahdollisen virheellisen tiedon korjaamista tai puutteellisen tiedon täydentämistä. Mikäli henkilö haluaa tarkistaa hänestä tallennetut tiedot tai vaatia niihin oikaisua, pyyntö tulee lähettää kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).</p> 

        <h4>10. Muut henkilötietojen käsittelyyn liittyvät oikeudet</h4>
        <p>Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien henkilötietojen poistamista rekisteristä ("oikeus tulla unohdetuksi"). Niin ikään rekisteröidyillä on muut EU:n yleisen tietosuoja-asetuksen mukaiset oikeudet, kuten henkilötietojen käsittelyn rajoittaminen tietyissä tilanteissa. Pyynnöt tulee lähettää kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).</p>

      </div>

    </div>

  </div>

  <!-- FOOTER ................................................................................ -->
  <item-footer-main></item-footer-main>


  <!-- Just add this whre you need to warch for user's ativity .................................. -->
  <!-- On this page it has to turn this "activity watvhing" on only if user was signed in -->
  <box-user-keep-active v-if="$store.state.userType!=''"></box-user-keep-active>


</div>
</template>


<script> // -------------------------------------------------------------------------------------------------------

import itemInitNavBar from '@/components/pageInit/itemInitNavBar'
import itemFooterMain from '@/components/itemFooterMain'
import boxUserKeepActive from '@/components/boxUserKeepActive'


export default {
  name:        'pageInfoPrivacyPolicy',
  components: {
                itemInitNavBar,
                itemFooterMain,
                boxUserKeepActive
              },

  data () {

    return {

       //signUpShow: false,

    } // return

  }, // data


  // ===========================================================================================================
  created () {
  }, // created


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>
<template>

  <div id = "app">

    <!-- LOADING WINDOW -->
    <!--
    <div v-if="loadingShow" class="box-flex-backgrounded-3 p-fixed">
      <div class="icon-loading"></div>
      <div class="loading-text-main">Ladataan...</div>
    </div>
    -->
    
    <!-- This div is ONLY FOR TESTING .......................................................... -->
    <dev-menu v-if="devMenu_show"></dev-menu>

    <!-- ALL SUCCESSFUL MSGs AFTER REQUESTs go here -->
    <box-request-successful-main></box-request-successful-main>

    <!-- ALL FAILED MSGs AFTER REQUESTs go here -->
    <box-request-failed-main></box-request-failed-main>

    <!-- POP-UP WINDOW FOR ALL TYPES OF BASIC NOTIFICATIONS .................................................... -->
    <window-notification-simple-basic v-if              = "$store.state.windowNotificationSimpleBasic.show"
                                     :titleMain         = "$store.state.windowNotificationSimpleBasic.titleMain"
                                     :textMain          = "$store.state.windowNotificationSimpleBasic.textMain"
                                     @current-box-close = "$store.commit('windowNotificationSimpleBasic_Reset')"></window-notification-simple-basic>

    <!-- LOADING: COVER -->
    <box-loading-cover v-if="$store.state.loadingCover_show"></box-loading-cover>

    <!-- "Joukkoviesti" from SA goes here ................................................................. -->
    <!-- <div style="color:red;">TÄSSÄ</div> -->

    <!-- ....................................................................................... -->
    <!--
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    -->
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>

  </div>

</template>


<script> // -------------------------------------------------------------------------------------------------------

import boxRequestSuccessfulMain      from '@/components/boxRequestSuccessfulMain'
import boxRequestFailedMain          from '@/components/boxRequestFailedMain'
import windowNotificationSimpleBasic from '@/components/windowNotificationSimpleBasic'
import boxLoadingCover               from '@/components/boxLoadingCover'

/*--- "MOCK" ("DEV_") mode ---*/
import devMenu from '@/components/devMenu'
// const { devMenu } = require('@/components/devMenu')
// var xxx = () => import('@/components/devMenu'); // { if (process.env.NODE_ENV == 'development') import('@/components/devMenu'); }


export default {
  name: 'App',
  components: { 
                boxRequestSuccessfulMain,
                boxRequestFailedMain,
                windowNotificationSimpleBasic,
                boxLoadingCover,
                //--- "MOCK" ("DEV_") mode ---
                devMenu
                // 'dev-menu': () => import('@/components/devMenu')
              },


  data () {

    return {

      devMenu_show: false

    } // return

  }, // data


  // ===========================================================================================================
  created () {

    this.devMenu_show = process.env.NODE_ENV=='development';

  }, // created


  // ===========================================================================================================
  computed: {
    /* requestFailedShow () {if (this.$route.name == 'status404') { return false; } else { return true; } } */
  }, // computed
  

  // ===========================================================================================================
  methods: {

    //--- ------------------------------------------
    /*testShowPageUserClientPp () {
      this.$store.commit('userTypeSet', 'PRIVATE_CLIENT');
      this.$router.push({ path: 'client' });
    },*/

    //--- ------------------------------------------
    /*testShowPageUserClientPc () {
      this.$store.commit('userTypeSet', 'PRIVATE_COMPANY');
      this.$router.push({ path: 'client' });
    },*/

  }, // methods

}

</script>
<style src="./assets/css/main.css">
</style>


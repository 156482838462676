
export default {

  data () {
    return {
      
      // Show TOOLTIP
      mixinTooltipsInput: {
        firstName:          false,
        lastName:           false,
        companyName:        false,
        birthday:           false,
        businessId:         false,
        registrationNumber: false,
        password:           false,
        iban:               false
        // companyNamePp:      false,
      },

      // Show TOOLTIP TEXT
      mixinTooltipsInputText: {
        firstName:                     'Kirjoita ainakin yksi etunimi',
        lastName:                      'Kirjoita ainakin yksi sukunimi',
        fullNameCompanyRepresentative: 'Kirjoita yrityksen edustajan etu- ja sukunimi',
        companyName:                   'Kirjoita 2-100 merkkiä',
        birthday:                      'Teksti on tulossa...',
        businessId:                    'Kirjoita muodossa 1234567-8',
        registrationNumber:            '5-6 numeroinen tunnus',
        password:                      'Salasanassa on oltava 8-30 merkkiä, ja sen tulee sisältää vähintään yksi iso ja pieni kirjain sekä numero ja erikoismerkki. Erikoismerkeistä hyväksyttyjä ovat $, @, !, %, *, ?, & , #, _, -, ., ,, : ja ;.',
        iban:                          'Anna tilinumero IBAN-muodossa. Tilin tulee olla suomalaisessa pankissa ja sinun omasi.'
        //companyNamePp:                 'Halutessasi voit lisätä myös toiminimesi tai kevytyrityksesi vakuutukset profiiliisi. Kirjoita 2-25 merkkiä.',
      },

      // Show of hide TOOLTIP BOX // TODO: "tooltip" was changed, this part need to be deleted after testing the new code of "tooltip"
      mixinTooltipsInputClass: {
        firstName:          'display-hide',
        lastName:           'display-hide',
        companyName:        'display-hide',
        businessId:         'display-hide',
        registrationNumber: 'display-hide',
        password:           'display-hide',
        iban:               'display-hide'
      }

    } // return
  }, // data


  methods: {

    //--- Show TOOLTIPS BOX -----------------------------------------------------------------------------------
    mixinTooltipsInputShow (type) {
      this.mixinTooltipsInputClass[type] = 'tooltip';
      //setTimeout(() => {this.mixinTooltipsInputClass[type] = 'tooltip';}, 300);
    },

    //--- Show TOOLTIPS BOX -----------------------------------------------------------------------------------
    mixinTooltipsInputShowSimple (type) {
      this.mixinTooltipsInput[type] = true;
      //this.mixinTooltipsInputClass[type] = 'tooltip';
    },

    //--- Hide TOOLTIPS BOX -----------------------------------------------------------------------------------
    mixinTooltipsInputHide (type) {
      this.mixinTooltipsInput[type] = false;
      //setTimeout(() => { this.mixinTooltipsInputClass[type] = 'display-hide'; }, 300);
    },

    //--- Hide TOOLTIPS BOX -----------------------------------------------------------------------------------
    mixinTooltipsInputShowHideByButton (inputTypeCurrent) {
      this.mixinTooltipsInput[inputTypeCurrent] = !this.mixinTooltipsInput[inputTypeCurrent];
      for (const inputType in this.mixinTooltipsInput) {
          if (inputType == inputTypeCurrent) { continue; }
          this.mixinTooltipsInput[inputType] = false;
        }
    },

  } // methods
}

<template>

    <!-- INIT BOX: WHY ............................................................................. -->
    <section class="init-box-why">

      <div><h4 class="theme-light-title-2">Miksi kilpailuttaa vakuutukset Vakuutustiedot.fi palvelussa</h4></div>
      <div><h1 class="theme-light-title-1 m_b-0">Kilpailuta vakuutuksesi ilmaiseksi</h1></div>
      <div>Vakuutusten kilpailuttaminen on helppoa ja täysin ilmaista</div>
     
      <div class="init-box-why-1">

        <div class="init-box-iconed-1">
          <div><img src="@/assets/img/icon-pp-benefit-1.svg" alt="Säästöpossu"></div>
          <div><h4 class="theme-light-title-2">Rahan säästöä</h4></div>
          <div>Kilpailuttamalla vakuutukset vuosittain säästät rahaa</div>
        </div>

        <div class="init-box-iconed-1">
          <div><img src="@/assets/img/icon-pp-benefit-2.svg" alt="Kellotaulu"></div>
          <div><h4 class="theme-light-title-2">Säästä aikaa</h4></div>
          <div>Voit hoitaa kilpailutuksen mistä ja milloin haluat</div>
        </div>
      
        <div class="init-box-iconed-1">
          <div><img src="@/assets/img/icon-pp-benefit-3.svg" alt="Hyväksytty lomake"></div>
          <div><h4 class="theme-light-title-2">Paremmat ehdot</h4></div>
          <div>Kilpailuttamalla voit saada vakuutuksiisi paremmat ehdot</div>
        </div>

      </div>
      <div class="init-box-why-2">

        <div class="init-box-iconed-1">
          <div><img src="@/assets/img/icon-pp-benefit-4.svg" alt="Nolla euroa"></div>
          <div><h4 class="theme-light-title-2">Ilmainen</h4></div>
          <div>Palvelumme käyttö on täysin ilmaista henkilö-, yritys- ja yhdistysasiakkaille</div>
        </div>
      
        <div class="init-box-iconed-1">
          <div><img src="@/assets/img/icon-pp-benefit-5.svg" alt="Asiakaspalvelija"></div>
          <div><h4 class="theme-light-title-2">Asiakaspalvelu</h4></div>
          <div>Ilmainen asiakaspalvelumme opastaa tarvittaessa palvelun käytössä</div>
        </div>
      
        <div class="init-box-iconed-1">
          <div><img src="@/assets/img/icon-pp-benefit-6.svg" alt="Riippulukko kilven päällä"></div>
          <div><h4 class="theme-light-title-2">Turvallinen</h4></div>
          <div>Palvelu on tietoturvallinen. Kaikki tietosi ovat omassa hallinnassasi</div>
        </div>

      </div>

    </section>

</template>


<script> // -------------------------------------------------------------------------------------------------------

//import boxPrelaunchMain from '@/components/pageInit/boxPrelaunchMain'

export default {
  name:        'boxInitBoxWhy',
  components: {},


  data () {

    return {

       //titleMain: 'Näin kilpailutat vakuutuksesi helposti', // DEFAULT: for "pp"

    } // return

  }, // data


  // ===========================================================================================================
  created () {
  }, // created


  // ===========================================================================================================
  mounted () {
  }, // mounted


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>
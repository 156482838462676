
/*
It is a kind of common simple validation for TEXTS.
*/

export default {

  /*
  ATTENTION! Do not forget to define VALIDATION rules in documentation:
  https://insured.atlassian.net/wiki/spaces/VAKUUTUSTI/pages/290947073/Validation+rules+F+B
  */

  data () {
    return {

      //TODO: For some reason reactivity does not work by adding values into this objects. Need to find out a way to do it.

      mixinValidation_icName_inputMaxLength:                    50,
      mixinValidation_icName_other_inputMaxLength:              50,
      mixinValidation_street_inputMaxLength:                    100,
      mixinValidation_town_inputMaxLength:                      50,
      mixinValidation_tradeUnion_inputMaxLength:                500,
      mixinValidation_insuranceType_inputMaxLength:             100,
      mixinValidation_insuranceTypeOfficial_inputMaxLength:     100,
      /* TURNED-OFF-on-LEAD -> */ // mixinValidation_contactInfoTimeBestToCall_inputMaxLength: 100,

      mixinFlagAllowValidate: { /*mixinValidationAllows*/ // Stop from VALIDATION at the first INPUT editing
        icName:                false,
        icName_id:             false,
        icName_other:          false,
        street:                false,
        insuranceTypeOfficial: false,
        insuranceType:         false,
        msg:                   false,
        tradeUnion:            false,
        clientCategory:        false,
        // contactInfoTimeBestToCall: false
      },

      mixinValidError: { /*mixinValidationErrorTexts*/ // ERROR MSG of VALIDATION
        icName:                '',
        icName_id:             '',
        icName_other:          '',
        street:                '',
        insuranceTypeOfficial: '',
        insuranceType:         '',
        msg:                   '',
        tradeUnion:            '',
        clientCategory:        '',
        // contactInfoTimeBestToCall: ''
      },

      mixinClassVal: { /*mixinValidationClasses*/ // SHOW/HIDE ERROR BOX
        icName:                'display-hide',
        icName_id:             'display-hide',
        icName_other:          'display-hide',
        street:                'display-hide',
        insuranceTypeOfficial: 'display-hide',
        insuranceType:         'display-hide',
        msg:                   'display-hide',
        tradeUnion:            'display-hide',
        clientCategory:        'display-hide',
        // contactInfoTimeBestToCall: 'display-hide'
      },

      mixinValidationOptional: { // For making INPUT as an OPTIONAL:
        icName:                false,
        icName_id:             false,
        icName_other:          false,
        street:                false,
        insuranceTypeOfficial: false,
        insuranceType:         false,
        msg:                   false,
        tradeUnion:            false,
        clientCategory:        false,
        // contactInfoTimeBestToCall: false
      }

    } // return
  }, // data


  methods: {
		
    //---  -----------------------------------------------------------------------------------------------
    mixinValidation_SharedTextSimple (inputValue, inputType) {

      // Need to allow VALIDATION after finding empty INPUTS at pushing "SUBMIT" button 
      if (this.mixinValidError[inputType]) this.mixinFlagAllowValidate[inputType] = true;

      if (this.mixinFlagAllowValidate[inputType]) {
        this.mixinValidation_SharedTextSimple_Check(inputValue, inputType);
        this.mixinValidationErrorBoxShowHide(inputType);
        //console.log('mixinValidation_SharedTextSimple: ' + this.mixinValidError[inputType]);
        //console.log('mixinValidation_SharedTextSimple: ' + this.mixinClassVal[inputType]);
      }

    },

    //--- Validation starts only after user has filles INPUT for the first time ---------------------------
    mixinValidation_SharedTextSimple_Start (inputValue, inputType) {
      
      //this.mixinValidationCommon_ValueAdd(inputType); // In use only for SHARED mixins
      this.mixinFlagAllowValidate[inputType] = true;
      this.mixinValidation_SharedTextSimple(inputValue, inputType);

    },

    //-----------------------------------------------------------------------------------------------------
    //--- INNER WORKER ------------------------------------------------------------------------------------
    mixinValidation_SharedTextSimple_Check (inputValue, inputType) {

      var validRegex = () => {
        // Match everything but NOT BLANK character, at least 1 symbols
        var re = /^(\s|\S)*(\S)+(\s|\S)*$/gm;
        return re.test(inputValue);
      }

      if (!inputValue) { // If empty
        this.mixinValidationErrorIfEmpty(inputType);
      } 
      else {
        if (!validRegex()) {
          switch (inputType) { 
            // case 'icName':                this.mixinValidError[inputType] = 'Tarkista vakuutusyhtiön nimi'; break;
            case 'street':               this.mixinValidError[inputType] = 'Tarkista osoitteesi'; break;
            case 'insuranceTypeOfficial': 
            case 'insuranceType':         this.mixinValidError[inputType] = 'Tarkista vakuutuksen nimi'; break;
            case 'msg':                   this.mixinValidError[inputType] = 'Tarkista viesti'; break;
            case 'tradeUnion':            this.mixinValidError[inputType] = 'Ammattiliiton nimi on liian lyhyt'; break;
            case 'clientCategory':        this.mixinValidError[inputType] = 'Tarkista asiakasryhmä'; break;
            case 'icName':                this.mixinValidError[inputType] = 'Tarkista vakuutusyhtiön nimi'; break;
            case 'icName_id':             this.mixinValidError[inputType] = 'Tarkista vakuutusyhtiön nimi'; break;
            case 'icName_other':          this.mixinValidError[inputType] = 'Tarkista vakuutusyhtiön nimi'; break;
            default: alert('ERROR! mixinValidation_SharedTextSimple_Check: inputType "' + inputType + '" was not found');
          }
        }
        else {
          this.mixinValidationCommon_ErrorMsgReset(inputType);
        }
      }
  
    },

  } // methods

}




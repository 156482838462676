<template>
<div class="w-100">

  <div class="client-container init">

    <!-- NAVBAR .............................................................................. -->
    <item-init-nav-bar></item-init-nav-bar>

<div>

  
    <!-- BOX-1 ............................................................................. -->
    <section class="init-box-1">
      <div><h1 class="title-h1-bigger theme-light-title-1">Kilpailuta yrityksen vakuutukset ilmaiseksi</h1></div>
      <div><p>Kilpailuttamalla voit saada säästöjä ja parempaa vakuutusturvaa. Vakuutustiedot.fi on riippumaton vakuutuskilpailutusalusta</p></div>
      <div class="init-box-1-1">
        <div><img src="@/assets/img/ill-04.jpg" alt="Kaksi miestä korjaamassa autoa"></div>
        <!-- SIGN UP: NESTED ... -->
        <item-sign-up-nested></item-sign-up-nested>
      </div>
    </section>


  <!-- MAIN ............................................................................. -->
  <div class="init-box-main">


    <!-- BOX-2 ............................................................................. -->
    <section class="init-box-2">
      <div class="init-box-2-1">
        <div><h4 class="theme-light-title-2">Kilpailuta vakuutuksesi</h4></div>
        <div><h1 class="theme-light-title-1 m_b-0">Kilpailuta vaikka kaikki vakuutuksesi kerralla</h1></div>
        <div><p>Palvelussa voit helposti kilpailuttaa yksittäisen tai vaikka kaikki vakuutuksesi yhdellä kertaa vaikkapa kotitoimistolta käsin. Helppous korostuu etenkin säännöllisessä kilpailutuksessa</p></div>
      </div>
      <div><img src="@/assets/img/ill-02.jpg" alt="Iloinen mies työskentelemässä kannettavallaan"></div>
    </section>


    <!-- BOX: HOW IT WORKS ................................................................. -->
    <box-init-box-how-it-works></box-init-box-how-it-works>


    <!-- BOX-3 ............................................................................. -->
    <section class="init-box-3">

      <div>
        <img src="@/assets/img/ill-06.jpg" alt="Iloiset miehet kättelevät auton edessä kaupungissa">
      </div>
      
      <div class="init-box-3-1">
        <div><h4 class="theme-light-title-2">Hanki uusia vakuutuksia</h4></div>
        <div><h1 class="theme-light-title-1 m_b-0">Uusi vakuutus kilpailutettuna ja nopeasti</h1></div>
        <div><p>Pyydä tarjous yhdestä tai useammasta uudesta vakuutuksesta haluamistasi vakuutusyhtiöistä. Kilpailuta uusi vakuutus jo ennen sen hankintaa, niin saat jo alusta saakka itsellesi sopivimman vaihtoehdon</p></div>
      </div>
    
    </section>


    <!-- BOX: WHY .......................................................................... -->
    <box-init-box-why></box-init-box-why>


    <!-- PP-6 ............................................................................. -->
    <!--
    <section class="init-pp-6">

      <div><h4 class="theme-light-title-2">Vakuutustiedot.fi on riippumaton kaupankäyntialusta</h4></div>
      <div><h1 class="theme-light-title-1">Vertaa kaikkia vakuutusyhtiöitä</h1></div>
      <div class="init-pp-6-1">
        <div><img src="@/assets/img/logo-IC-If.svg"></div>
        <div><img src="@/assets/img/icon-insurance.svg"></div>
        <div><img src="@/assets/img/logo-IC-Lahitapiola.png"></div>
        <div><img src="@/assets/img/logo-IC-OP.jpg"></div>
        <div><img src="@/assets/img/logo-IC-If.svg"></div>
        <div><img src="@/assets/img/logo-IC-Lahitapiola.png"></div>
        <div><img src="@/assets/img/logo-IC-OP.jpg"></div>
        <div><img src="@/assets/img/logo-IC-If.svg"></div>
        <div><img src="@/assets/img/logo-IC-Lahitapiola.png"></div>
        <div><img src="@/assets/img/logo-IC-OP.jpg"></div>
        <div><img src="@/assets/img/icon-insurance.svg"></div>
      </div>

    </section>
    -->

    
    <!-- BOX: FAQ .......................................................................... -->
    <box-init-box-faq></box-init-box-faq>

  
  </div>

</div>


  </div>


  <!-- FOOTER ................................................................................ -->
  <item-footer-main></item-footer-main>


</div>
</template>


<script> // -------------------------------------------------------------------------------------------------------

import itemFooterMain       from '@/components/itemFooterMain'
import itemInitNavBar       from '@/components/pageInit/itemInitNavBar'
import itemSignUpNested     from '@/components/itemSignUp/itemSignUpNested'
import boxInitBoxHowItWorks from '@/components/pageInit/boxInitBoxHowItWorks'
import boxInitBoxWhy        from '@/components/pageInit/boxInitBoxWhy'
import boxInitBoxFaq        from '@/components/pageInit/boxInitBoxFaq'

import mixinUserDefine from '@/mixins/mixinUserDefine'


export default {
  name:        'pageInitPc',
  components: {
                itemFooterMain,
                itemInitNavBar,
                itemSignUpNested,
                boxInitBoxHowItWorks,
                boxInitBoxWhy,
                boxInitBoxFaq
              },
  mixins:     [ 
                mixinUserDefine
              ],

  data () {

    return {

       signUpShow: false,

    } // return

  }, // data


  // ===========================================================================================================
  beforeMount() {

    this.mixinUserDefine_RedirectFromSharedPages(); // ONLY for SIGNED IN USER: redirect user from here to his profile page

  }, // beforeMount


  // ===========================================================================================================
  created () {

    this.$store.commit('userTypeInitSet', 'pc'); // In Store: SET type of Init User Type: in use in several places

  }, // created


  // ===========================================================================================================
  mounted () {
  }, // mounted


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>
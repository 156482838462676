
// import { store }    from '@/store' // Because this.$store.commit('userTypeReset') do not work here
import mixinSignOut from '@/mixins/mixinSignOut'


export default {

  mixins: [ mixinSignOut ],

	methods: {


    // xxx () { this.mixinSignOut(true); /*this.$store.commit('DEV_itWorks');*/ },


    //--- ONLY! for: "post", "asynchronous" and "application/x-www-form-urlencoded" XMLHttpRequests ----------
    mixinXhrPostAsyncApp (obj, url, header) {


    //---
    let isSessionTerminated = (response) => {

      let isIt = false;

      if (!this.$store.state.DEV_Show) { /* There is no need in automatic singing out at DEV MODE */
        if (this.$store.state.DEV_isSessionTerminated_on && response) { /* Manual turning ON/OFF at DEV panel */
          for (const property in response) {
            if (property == 'requestIsBroken' || property == 'fuckHackers') isIt = true;
          }
        }
      }

      return isIt;
      
    }

    //--- Create from SERVER response user friendly ERROR MSGs ---------------------------------------------
    let responseMessages = (response, url) => {

      // "url" needs when there is 2 or more requests from the same file
      if (isSessionTerminated(response)) {
        // console.log(':::VUE3-BUG-FIXING-1::: mixinXhrPostAsyncApp: isSessionTerminated(response) IS TRUE');
        this.mixinSignOut(true);
      }
      else {
        this.xhrPostAsyncAppResponseMsgs(response, url); 
        // This part can be deleted: it just show time here
        // let d = new Date();
        // let t = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + ':' + d.getMilliseconds();
        // console.log(url + ': B response time: ' + t); // SERVER RESPONSE as STRING
      }

    }


    //---   ------------------------------------------------------
    let catchErrors = (isOnerror) => {

        // store.commit('requestFailedMainSet');
        this.$store.commit('requestFailedMainSet');

        if (isOnerror) {
          // console.log(url + "ONERROR: An error occurred during the transaction");
        }
        else {
          // console.log('REQUEST STATUS (not 4&200): ' + url + ': ' + xhr.status + ', ' + xhr.readyState);
        }

        // To stop "Submit" button from spinning:
        if (this.submitAllow !== undefined) this.submitAllow = true;

        // To stop "Loading..." from spinning:
        if (this.loadingList_show !== undefined) this.loadingList_show = false;

    }

    //---   ------------------------------------------------------
    let url_isInExceptions = (url) => {

      let isIt = false;

      /* Add here all serlvet names ("url"), which should not be called */
      let exceptions = [
        // 'listParticipatedTenderingsForInsuranceRepresentative',
        // 'listTenderingsForInsuranceRepresentative',
        // 'getNumberOfParallelTenderings',
        // 'showActiveGroupMessagesToCustomers'
        // '',
      ];

      exceptions.forEach( value => { if (value == url) isIt = true; });

      return isIt;

    }

    // To prevent sending request with an empty URL     
    if (url) {

      /* This one is only to disable unready servlets: to make it possible to test already connected ones. It can be just deleted from here*/
      if (!url_isInExceptions(url)) {

        var xhr = new XMLHttpRequest();
            
        xhr.onreadystatechange = function() {
          if (xhr.readyState == 4 && xhr.status == 200) {
            // console.log(url + ': B response as string: ' + xhr.response); // SERVER RESPONSE as STRING
            var response = JSON.parse(xhr.response);
            responseMessages(response, url); // Need brecause cannot call from here xhrPostAsyncAppResponseMsgs function
          } 
          else if (xhr.readyState == 4 && xhr.status >= 400) { catchErrors(); }
        };
            
        xhr.open('POST', '/' + url, true);

        xhr.onerror = () => { catchErrors(true); };

        if (header == 'app') {
          xhr.overrideMimeType( 'text/plain; charset=utf-8' ); // To get rid of error in Firefox
          xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
        } // if (header == 'app') { xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8'); }
        else if (header == 'multipart') {
          xhr.setRequestHeader('Content-Type', 'multipart/form-data');
        }

        //console.log(url + ": F request (JSON.stringify(obj)): " + JSON.stringify(obj)); // Show request
        // console.log(url + ': F request (' + typeof obj + '): ' + obj); // Show request

        xhr.send(obj);
          
      }
    }


    },
       

	} // methods
}

import { createStore } from 'vuex'

// export default createStore({state: {}, mutations: {}, actions: {}, modules: {} })

// MSG ----------------------------------

const msg = {

  state: {

    responseTrue:       'Tiedot päivitetty onnistuneesti',
    responseFalse:      'Jokin meni pieleen. Yritä uudelleen',
    responseAdminFalse: 'Palvelimen virhe. Apua saat SRA:lta',

  }
  
  // mutations: {},
  // actions: {},
  // getters: {}

}


// ADMIN ----------------------------------

const admin = {

  namespaced: true,

  state: {

    dataPersonal: {
      id: null
    },

  },
  
  mutations: {
    
    // EXAMPLE: this.$store.state.admin.dataPersonal.id
    // EXAMPLE: this.$store.commit('admin/data_set', { name: 'id', value: '33'})
    data_set (state, datas) {
      state.dataPersonal[datas.name] = datas.value;
    }

  },

  // actions: {},
  // getters: {}

}


// CLIENT ----------------------------------

const client = {

  namespaced: true,

  state: {

    dataPersonal: {
      id: null
    },

    dataPersonalExtra: { // CLIENTS -> "Omat tiedot" -> ICONS' DATA
      insurancesExistingTotal: 0,  // "Vakuutuksia"
      dateLastTender:          '', // "Kilpailutettu"
      offersNewTotal:          0,  // "Tarjouksia" NEW only
      tenders_msgNewTotal:     0,  // "Uudet viestit" from TENDERS
      service_msgTotal:        0,  // "Kaikki viestit" from "Asiakaspalvelu"
      service_msgNewTotal:     0   // "Uudet viestit" from "Asiakaspalvelu"
      /* TURNED-OFF-on-LEAD -> */ // tenders_total:           0,  // "Saadut tarjoukset"
      /* TURNED-OFF-on-LEAD -> */ // offersTotal:             0,  // "Tarjouksia"
      /* TURNED-OFF-on-LEAD -> */ // tenders_msgTotal:        0,  // "Kaikki viestit" from TENDERS
    },

    // tenderingAllowed:       false, // isUserEligibleToTender: true,
    // tenderingBlockedReason: '',
    tenderingBlocked: {
      isIt: false,
      msg: '',
      reason: {
        incompleteProfile:               false, // { value: false, msg:'' },
        ageUnderLimit:                   false,
        limitOfOngoingTenderingsReached: false,
        other:                           false // If B has more, that F knows
      }
    }

  },
  
  mutations: {
    
    // EXAMPLE: this.$store.state.client.dataPersonal.id
    // EXAMPLE: this.$store.commit('client/data_set', { name: 'id', value: '33'})
    data_set (state, data) {
      state.dataPersonal[data.name] = data.value;
    },

    dataPersonalExtra_set (state, data) {
      state.dataPersonalExtra[data.name] = data.value;
    },

    tenderingBlockedIsIt_set (state, value=false) {
      state.tenderingBlocked.isIt = value;
    },

    tenderingBlockedMsg_set (state, msg) {
      state.tenderingBlocked.msg = msg;
    },

    tenderingBlockedMsg_reset (state) {
      state.tenderingBlocked.msg = '';
    },

    tenderingBlockedReason_set (state, name) {
      state.tenderingBlocked.reason[name] = true;
      // alert(name + ': ' + state.tenderingBlocked.reason[name]);
      // alert('isIt: ' + state.tenderingBlocked.isIt);
    },

    tenderingBlockedReason_reset (state) {
      for (const prop in state.tenderingBlocked.reason) {
        state.tenderingBlocked.reason[prop] = false;
      }
      // alert('isIt: ' + state.tenderingBlocked.isIt);
    },

  },

  // actions: {},
  // getters: {}

}


// SELLER ----------------------------------

const seller = {

  namespaced: true,

  state: {

    dataPersonal: {
      id: null
    },

    dataPersonalExtra: { // SELLERS -> "Omat tiedot" -> ICONS' DATA
      tendersRequestsNewTotal: 0, // "Uusia tarjouspyyntöjä"
      offersInProgressTotal:   0, // "Keskeneräiset tarjoukset"
      tendersActualizedTotal:  0, // "Toteutuneet kaupat"
      tenders_msgNewTotal:     0,  // "Uudet viestit" from TENDERS
      service_msgTotal:        0,  // "Kaikki viestit" from "Asiakaspalvelu"
      service_msgNewTotal:     0,  // "Uudet viestit" from "Asiakaspalvelu"
      billsNewTotal:           0
      /* TURNED-OFF-on-LEAD */ // tenders_msgTotal:        0,  // "Kaikki viestejä" from TENDERS
      // rewardDrowOutAvaliableTotal: 0, // "Suosittelu­palkkiot"
    },

    icNames: [], // Possible IC names requested from B

    isAllRequiredPersonalDataFilled: false, // Set by F
    isStrongIdentificationCompleted: false, // Set by B
    isActivatedByAdmin:              false, // Set by B

    insuranceNameInList_max: 3,

  },
  
  mutations: {

    // EXAMPLE: this.$store.state.seller.dataPersonal.id
    // EXAMPLE: this.$store.commit('seller/data_set', { name: 'id', value: '33'})

    data_set (state, datas) {
      state.dataPersonal[datas.name] = datas.value;
    },

    dataPersonalExtra_set (state, data) {
      state.dataPersonalExtra[data.name] = data.value;
    },

    isAllRequiredPersonalDataFilled_set (state, isIt) {
      state.isAllRequiredPersonalDataFilled = isIt;
    },

    isStrongIdentificationCompleted_set (state, isIt) {
      state.isStrongIdentificationCompleted = isIt;
    },

    isActivatedByAdmin_set (state, isIt) {
      state.isActivatedByAdmin = isIt;
    },

    icNames_get (state, obj) {
      obj.forEach(
        value => {
          state.icNames.push({
            id:   value.id,
            name: value.name,
          });
        }
      );
    },


  },
  // actions: {},
  // getters: {}

}


// STRONG IDENTIFICATION ----------------------------------

const identificationStrong = {
  
  namespaced: true,

  state: {

    irRegistration:       'irRegistration',
    userIdParameter:      '_userId_',

    // clientIdentification: 'clientIdentification',
    // offerIdParameter:     '_offerId_',

    location:             '' // Empty as a DEFAULT

  },
  
  mutations: {

    // EXAMPLE: this.$store.state.identificationStrong.location
    // EXAMPLE: this.$store.commit('identificationStrong/location_set', this.location)

    location_set (state, url) {
      state.location = url;
    },

    location_reset (state) {
      state.location = '';
    },

  },
  // actions: {},
  // getters: {}

}


// -------------------------------------------------------
// -------------------------------------------------------

export const store = createStore({
  
  modules: {
    msg:                  msg,
    admin:                admin,
    client:               client,
    seller:               seller,
    identificationStrong: identificationStrong

  },

  state: { // Global params go here

    userType:     '', // User's type, recieved from SERVER 
    userTypeInit: 'pp', // DEFAULT; pageInit: Type of the clicked buttom's id
    userTheme:    '', // User's main theme: "dark", "light" ...

    pageShow: false,

    prelaunch: true, // ONLY FOR PRELAUNCH page TO-DO: delete all "prelaunch" after publication

    requestSuccessfulMain:              [ /*{ id: 0, msg:'Jotain päivitetty onnistuneesti' }*/ ], // All msg of succesful requests goes here
    requestSuccessfulMainAllowDeleting: true,
    requestSuccessfulMainMsgId:         4, // For adding messages' ids

    requestFailedMainShow:       false,
    requestFailedMainMsg:        '',
    requestFailedMainMsgDefault: 'Jotain meni vikaan. Päivitä sivu ja kokeile uudelleen',

    windowNotificationSimpleBasic: {
      show:            false,
      textMain:        '',
      textMainDefault: 'Jotain meni pieleen.',
      titleMain:       '',
    },

    loadingCover_show: false,

    windowPopUp_isAnyOpened: false,

    // adtractionToken: '',

    projectVersion: '2.0.1', // The last "old" styled vrersion was "v2.33.175.72"

    // DEVELOPING MODE
    DEV_Show: false, // When DEV MODE is on/off
    DEV_isSessionTerminated_on: true, // To turn on/off "isSessionTerminated" func at "mixinXhrPostAsyncApp"

  },


  mutations: {

    
    //--- "" --------------------------------------------------------------------------

    windowPopUp_isAnyOpened_set (state) {
      state.windowPopUp_isAnyOpened = true;
    },

    windowPopUp_isAnyOpened_reset (state) {
      state.windowPopUp_isAnyOpened = false;
    },


    //--- "windowNotificationSimpleBasic" -----------------------------------------------------
    
    windowNotificationSimpleBasic_Set (state, obj) {
      state.windowNotificationSimpleBasic.titleMain = obj.titleMain;
      state.windowNotificationSimpleBasic.textMain  = obj.textMain;
      state.windowNotificationSimpleBasic.show      = true;
    },

    windowNotificationSimpleBasic_Reset (state) {
      state.windowNotificationSimpleBasic.show      = false;
      state.windowNotificationSimpleBasic.textMain  = '';
      state.windowNotificationSimpleBasic.titleMain = '';
    },

    
    //--- "loadingCover" --------------------------------------------------------------------------

    loadingCover_set (state) {
      state.loadingCover_show = true;
    },

    loadingCover_reset (state) {
      state.loadingCover_show = false;
    },
    

    //---  --------------------------------------------------------------------------

    //--- Set "pageShow" -------------------------------------------
    pageShowSet (state, value) {
      state.pageShow = value;
      // console.log('STORE: pageShowSet=' + value);
    },


    //--- "userType" --------------------------------------------------------------------------

    //--- Set userType -------------------------------------------
    userTypeSet (state, type) { state.userType = type; },

    //--- Reset userType -----------------------------------------
    userTypeReset (state) { state.userType = ''; /*console.log('userTypeReset: DONE');*/ },
    

    //--- "userTypeInit" ----------------------------------------------------------------------

    //--- pageInit: Get type of the clicked buttom's id ----------
    userTypeInitSet (state, type) { state.userTypeInit = type; },

    //--- pageInit: Reset type of the clicked buttom's id --------
    userTypeInitReset (state) { state.userTypeInit = ''; },


    //--- "userTheme" -------------------------------------------------------------------------
    
    //--- Set main theme -----------------------------------------
    userThemeSet (state, theme) { state.userTheme = theme; },

    //--- Reset main theme ---------------------------------------
    userThemeReset (state) { state.userTheme = ''; },


    //--- "adtractionToken" -------------------------------------------------------------

    //--- Set Adtraction token -----------------------------------------
    // adtractionToken_set (state, token) { state.adtractionToken = token; },

    //--- Reset Adtraction token ---------------------------------------
    // adtractionToken_reset (state) { state.adtractionToken = ''; },


    //--- "requestSuccessfulMain" -------------------------------------------------------------

    //--- MSG: delete --------------------------------------------
    requestSuccessfulMainDelete (state, id_current) {

      var del = () => {
        if (state.requestSuccessfulMainAllowDeleting) {
          state.requestSuccessfulMainAllowDeleting = false;
          state.requestSuccessfulMain.forEach((value, index) => { if (value.id == id_current) state.requestSuccessfulMain.splice(index,1); });
          if (state.requestSuccessfulMain.length < 1) state.requestSuccessfulMainMsgId = 1; // RESET ID at empty list, just for sure
          state.requestSuccessfulMainAllowDeleting = true;
        }
        else {
          setTimeout( () => del(), 50 ); // To wait a little bit...
        }
      }
      
      del();

    },


    //--- MSG: add -----------------------------------------------
    requestSuccessfulMainAdd (state, data/*msg, time*/) {

      /* (!) ATTENTION!
      "data.time" can be number (value in sec), string (like "infinite") or just empty (when DEFAULT value in use)
      Call example: this.$store.commit('requestSuccessfulMainAdd', { msg: 'Jotain päivitetty onnistuneesti', time: 5 });
      */

      if (!data.msg) alert('ERROR! STORE -> requestSuccessfulMainAdd -> "msg" on tyhjä tai ei ole asetettu oikein');
      
      // ID set first
      state.requestSuccessfulMainMsgId++;
      var id_current = state.requestSuccessfulMainMsgId; // F needs to copy that value, as this function can be called several times at the same time
      // console.log('GREEN THING: id_current=' + id_current);
      // console.log('GREEN THING: data.time=' + data.time

      // ADD this MSG
      state.requestSuccessfulMain.unshift({ id: id_current, msg: data.msg });

      // Set deleting of this MSG
      if (typeof data.time == 'number'/* || Number.isInteger(data.time)*/) {
        setTimeout( () => store.commit('requestSuccessfulMainDelete', id_current), data.time*1000 );
      }
      else if (data.time == 'infinite') {
        // Just ignore it: no need for automatic closing in this case
      }
      else { // DEFAULT (like "undefined" or just empty)
        setTimeout( () => store.commit('requestSuccessfulMainDelete', id_current), 10*1000 );
      }

      // if (state.requestSuccessfulMainAllowDeleting) {

      //   var msgClose = () => {
      //     setTimeout(() => { 
      //       state.requestSuccessfulMain.pop();
      //       //console.log('Deleted'); //console.log(state.requestSuccessfulMain.length);
      //       if (state.requestSuccessfulMain.length > 0) {
      //         msgClose();
      //       }
      //       else {
      //         state.requestSuccessfulMainMsgId         = 1; // RESET it fo sure
      //         state.requestSuccessfulMainAllowDeleting = true;
      //       }
      //     }, 10000);
      //   }

      //   console.log('TIMEOUT starts');

      //   state.requestSuccessfulMainAllowDeleting = false; // To prevent call func several times until it is done  

      //   msgClose();

      // }

    },


    // XXX (state) { alert('Works') },
    // xxxx (state) { store.commit('XXX'); },


    //--------------------------------------------------------------------------------------

    //--- -----------------------------------
    requestFailedMainDelete (state) {
      state.requestFailedMainShow = false; // Reset it
      state.requestFailedMainMsg  = state.requestFailedMainMsgDefault; // Reset it
    },

    //--- -----------------------------------
    requestFailedMainSet (state, msg) {

      if (!state.DEV_Show) {
        if (state.requestFailedMainShow == false) {
          (msg) ? state.requestFailedMainMsg = msg : state.requestFailedMainMsg = state.requestFailedMainMsgDefault;
          state.requestFailedMainShow = true;
        }
      }

    },



    //--- "PRELAUNCH" ----------------------------------------------------------------------

    prelaunchSet (state) { state.prelaunch = !state.prelaunch; },

    

    //--- DEV ------------------------------------------------------------------------------

    DEV_ShowHde (state) {
      state.DEV_Show = !state.DEV_Show;
    },

    DEV_itWorks (state) {
      alert('STORE works: ' + state.requestFailedMainMsgDefault);
    },

    DEV_isSessionTerminated_set (state) {
      state.DEV_isSessionTerminated_on = !state.DEV_isSessionTerminated_on;
    },


  }

});

export default store
<template>
<transition name="fade" mode="out-in">

<div class="box-flex-backgrounded-3 p-fixed">

  <div class="windowed-2-medium init-sign_in">

  <transition name="fade" mode="out-in">


  <!-- SIGN IN .......................................................................... -->
  <div v-if="switchSignInOrForgotPassword">


    <!-- TOP ........................................................... -->
    <div class="box-2-top">

      <div>
        <h3 class="theme-light-title-1">Kirjaudu</h3>
      </div>

      <span class="button-close" @click="$emit('current-box-close')" title="Sulje ikkuna"></span> 

    </div>


    <!-- MIDDLE ........................................................ -->
    <div class="box-2-middle">


    <!-- VALIDATION BOX from SERVER -->
    <box-validation-server-basic v-show            = "mixinValidationServerBasicShow"
                                :messages          = "mixinValidationServerBasicMessages"
                                @current-box-close = "mixinBoxValidationServerBasicClose"></box-validation-server-basic>


  <form class      = "init-sign_in-form"
        novalidate = "true">


    <!-- EMAILADDRESS ........................ -->
    <div>

      <input type         = "text"
             class        = "input-text-3"
            :class        = "inputTextTheme1"
             v-model      = "dataMain.emailAddress"
            @input        = "mixinValidation_emailAddress(dataMain.emailAddress)"
            @blur         = "mixinValidation_emailAddress_Start(dataMain.emailAddress)"
            @keyup.enter  = "enter_emailAddress"
            :maxlength    = "mixinValidation_emailAddress_inputMaxLength"
             autocomplete = "email"
             placeholder  = "Sähköpostiosoite">
      
      <!-- VALIDATION BOX -->
      <div :class="mixinClassVal.emailAddress">{{ mixinValidError.emailAddress }}</div>
    
    </div>


    <!-- PASSWORD ............................ -->
    <div class="relative">
    
      <span :class = "mixinClassIconEye"
            @click = "mixinPasswordShowHide"
             title = "Näytä/piilota salasana painike"></span>
    
      <input :type        = "mixinPasswordType"
              ref         = "inputPasswordRef"
              class       = "input-text-3"
             :class       = "inputTextTheme1"
              v-model     = "dataMain.password"
             @input       = "mixinValidation_password(dataMain.password)"
             @blur        = "mixinValidation_password_Start(dataMain.password)"
             @keyup.enter = "submitMain"
             :maxlength   = "mixinValidation_password_inputMaxLength" 
              placeholder = "Salasana">
    
      <!-- VALIDATION BOX -->
      <div :class="mixinClassVal.password">{{ mixinValidError.password }}</div>
    
    </div>


    <!-- SUBMIT .............................. -->
    <!--<div><input type="button" class="button-3" :class="buttonTheme1" @click="submitMain" value="Kirjaudu"></div>-->
    <box-button-submit-main submitButtonValue = "Kirjaudu"
                           :submitAllow       = "submitAllow"
                           @submit-main       = "submitMain"></box-button-submit-main>

    <!-- DEV: test ..................... -->
    <!-- <div><input type="button" class="button-3" :class="buttonTheme1" @click="$emit('window-reload-needed-open');" value="DEV_TEST"></div> -->

    <!-- Forget Password? ..................... -->
    <div>
      <a @click="signInOrForgotPassword">Unohditko salasanasi?</a>
    </div>


  </form>
  
  </div>
  </div>


  <!-- FORGOT PASSWORD .......................................................................... -->
  <item-forgot-password v-else 
                       @current-box-close = "signInOrForgotPassword"></item-forgot-password>

  
  </transition>

</div>


</div>

</transition>
</template>


<script> // -----------------------------------------------------------------------------------------------------------

import mixinValidationCommon        from '@/mixins/mixinValidation/mixinValidationCommon' 
import mixinValidation_emailAddress from '@/mixins/mixinValidation/mixinValidation_emailAddress'
import mixinValidation_password     from '@/mixins/mixinValidation/mixinValidation_password'

import mixinXhrPostAsyncApp          from '@/mixins/mixinXhrPostAsyncApp'
import mixinBoxValidationServerBasic from '@/mixins/mixinBoxValidationServerBasic'
import mixinTrim                     from '@/mixins/mixinTrim'
import mixinPasswordShowHide         from '@/mixins/mixinPasswordShowHide'

import itemForgotPassword       from '@/components/itemForgotPassword'
import boxValidationServerBasic from '@/components/boxValidationServerBasic'
import boxButtonSubmitMain      from '@/components/boxButtonSubmitMain'


export default {
  name:        'itemSignin',
  mixins:     [ 
                mixinValidationCommon,
                mixinValidation_emailAddress,
                mixinValidation_password,
                mixinXhrPostAsyncApp,
                mixinBoxValidationServerBasic,
                mixinTrim,
                mixinPasswordShowHide
              ],
  components: { 
                itemForgotPassword,
                boxValidationServerBasic,
                boxButtonSubmitMain
              },
  emits:      [
               'current-box-close',
               'window-reload-needed-open'
              ],

  
  data () {

    return {

      buttonTheme1:    'button-theme-1',
      inputTextTheme1: 'input-text-theme-1',

      windowClass: 'theme-light-block-3',

      submitAllow: true, // Prevent multiclicking of Submit

      switchSignInOrForgotPassword: true, // Shows SignIn as a default. Switch between SIGN IN and FORGOT PASSWORD

      dataMain: { // Values of INPUTS
        emailAddress: '',
        password:     '',
      },

    } // return
  }, // data


  // ===========================================================================================================
  created () {

    document.body.classList.add('overflow-hidden'); // For NOT to SCROLL whole page at opened POP-UP WINDOW

    // If "DARK" THEME
    if (this.$store.state.userTheme == 'dark') {
      this.windowClass = 'theme-dark-block-3';
    }

  }, // created


  // ===========================================================================================================
  beforeUnmount () {

    document.body.classList.remove('overflow-hidden'); // After closing POP-UP WINDOW there is no need in that CLASS

  }, // beforeUnmount


  // ===========================================================================================================
  methods: {


    //--- Create from SERVER response user friendly ERROR MSGs -------------------------------------------------
    xhrPostAsyncAppResponseMsgs (response) {
      
      if (response.passed == true) {

        // this.$store.commit('userTypeSet', response.userType);
        // Could be this.$router.push({ name: 'client', params: { userId } })

        // Define usetType first
        if      (response.userType == 'private_person')           { this.$store.commit('userTypeSet', 'pp'); }
        else if (response.userType == 'private_company')          { this.$store.commit('userTypeSet', 'pc'); }
        else if (response.userType == 'insurance_representative') { this.$store.commit('userTypeSet', 'ir'); }
        else if (response.userType == 'insurance_company')        { this.$store.commit('userTypeSet', 'ic'); }
        else if (response.userType == 'super_admin')              { this.$store.commit('userTypeSet', 'sa'); }
        // else                                                      { alert('ERROR! itemSignin says: userType "' + response.userType + '" is not connected or does not exist'); }

        if (response.reloadNeeded == true) { // If there is a new Project version avaliable
          this.$emit('window-reload-needed-open');
        }
        else {
          if      (response.userType == 'private_person')           { /*this.$store.commit('userTypeSet', 'pp');*/ this.$router.push({ path: '/client/pp' }); }
          else if (response.userType == 'private_company')          { /*this.$store.commit('userTypeSet', 'pc');*/ this.$router.push({ path: '/client/pc' }); }
          else if (response.userType == 'insurance_representative') { /*this.$store.commit('userTypeSet', 'ir');*/ this.$router.push({ path: '/seller/ir' }); }
          else if (response.userType == 'insurance_company')        { /*this.$store.commit('userTypeSet', 'ic');*/ this.$router.push({ path: '/seller/ic' }); }
          else if (response.userType == 'super_admin')              { /*this.$store.commit('userTypeSet', 'sa');*/ this.$router.push({ path: '/admin/sa' }); }
          else                                                      { alert('ERROR! itemSignin says: userType "' + response.userType + '" is not connected or does not exist'); }
          // else if (response.userType == 'root')                     { alert('itemSignin: RSA is not connected'); /*this.$store.commit('userTypeSet', 'rsa'); this.$router.push({ path: 'admin/rsa' });*/ }
        }

      }
      else if (response.passed == false) {

        this.mixinValidationServerBasicShow = true;
        this.mixinValidationServerBasicMessages.push(response.message); // Here we use B MSGs

      }

      this.submitAllow = true;

    },


    //--- Submit INPUTs and sent its to the SERVER -------------------------------------------------------------
    submitMain () {

      if (this.submitAllow) {

        this.mixinBoxValidationServerBasicClose(); // RESET ERROR msgs from SERVER

        var v = this.dataMain;
        var e = this.mixinValidError;

        this.mixinTrimObject(v); // To get rid of spaces from start/end of the strings

        if (v.emailAddress 
        &&  v.password 
        && !e.emailAddress
        && !e.password) {

          this.submitAllow = false;

          var url    = 'kirjauduSisaan'; // URL address for HTTPXMLrequest in mixinXhrPostAsyncApp
          var header = 'app';
          
          // Request to SERVER
          var obj = 'email='     + encodeURIComponent(v.emailAddress) + 
                    '&password=' + encodeURIComponent(v.password) +
                    '&version='  + this.$store.state.projectVersion; // We need project version to know then to make hard page reload

          this.mixinXhrPostAsyncApp(obj, url, header);

        }

        else { 
          this.mixinIsEmptyBeforeSubmit(this.dataMain); // mixinValidation: Show error MSG at empty INPUTs
        }

      }
    },


    //---   -------------------------------
    enter_emailAddress () {

      (this.dataMain.password) ? this.submitMain() : this.$refs.inputPasswordRef.focus();

    },


    //--- RESET all values and DATA after SIGN IN window is closed ---------------------------------------------
    initSigninResetDefault () {

      Object.assign(this.$data, this.$options.data.apply(this)); 
      
    },


    //--- Hide or Show FORGOT PASSWORD (CHILD uses)-------------------------------------------------------------
    signInOrForgotPassword () { 

      this.switchSignInOrForgotPassword = !this.switchSignInOrForgotPassword;
      if (this.switchSignInOrForgotPassword == true) this.initSigninResetDefault();

    },


    //---  ----
    // DEV_test () {
    //   this.$store.commit('userTypeSet', 'pp');
    //   this.$router.push({ path: '/client/pp' });
    //   console.log('HARD RESET');
    // },



  }, // methods


}
</script>


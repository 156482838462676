
export default {

  /*
  ATTENTION! Do not forget to define VALIDATION rules in documentation:
  https://insured.atlassian.net/wiki/spaces/VAKUUTUSTI/pages/290947073/Validation+rules+F+B
  */

  data () {
    return {
    } // return
  }, // data


  methods: {
		
    //--- ----------------------------------------------------------------------------------------
    mixinValidation_recaptcha (recaptcha) {

      if (!recaptcha) {
        this.mixinValidationServerBasicShow = true; // SHOWs validation box
        this.mixinValidationServerBasicMessages.push('ReCAPTCHA virhe. Päivitä sivu ja yritä uudelleen.');
      }

    }

  } // methods

}




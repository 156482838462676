
export default {


  data () {
    return {

        mixinTrimRegExBasic: /^\s+|\s+$/gm,
      
    } // return
  }, // data


	methods: {
		
		
    //--- Delete spaces from start and end -------------------------------------------------------------------
		
    mixinTrimObject (obj) {

      for (let type in obj) {
        //console.log('TRIM-2 works. Type: ' + typeof obj[type]);
        if (typeof obj[type] == 'string') {
          obj[type] = obj[type].replace(this.mixinTrimRegExBasic,'');
        }
      }

    },


    //--- Delete spaces from start and end -------------------------------------------------------------------
    
    mixinTrimArray (ar) {
      
      // eslint-disable-next-line
      ar.forEach(value => { value = value.replace(this.mixinTrimRegExBasic,''); });

    }


	} // methods
}

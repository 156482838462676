<template>

    <!-- INIT BOX: FAQ ............................................................................. -->
    <section class="init-box-faq">

      <div>
        <h4 class="theme-light-title-2">Usein kysyttyä palvelusta</h4>
      </div>
      
      <div>
        <h1 class="theme-light-title-1">Usein kysytyt kysymykset</h1>
      </div>
      
      <div class = "init-box-faq-question"
           v-for = "faq in faqs"
          :key   = "'init-box-faq-question-' + faq.id">

        <div :ref   = "'faqTitleMaiRef-' + faq.id"
             @click = "faqToggle(faq.id)">
          <span>{{ faq.question }}</span>
          <div class="button-toggle-plus_minus">
            <div class="button-toggle-plus" :ref="'faqButtonToggleRef-' + faq.id"></div>
          </div>
        </div>
        
        <div class="box-minimized-2" :ref="'faqAnswerRef-' + faq.id">{{ faq.answer }}</div>
      
      </div>
     
    </section>

</template>


<script> // -------------------------------------------------------------------------------------------------------

//import boxPrelaunchMain from '@/components/pageInit/boxPrelaunchMain'

export default {
  name:        'boxInitBoxFaq',
  components: {},


  data () {

    return {

      //titleMain: 'Näin kilpailutat vakuutuksesi helposti', // DEFAULT: for "pp"

      faqs: [], // To copy here FAQs according to user's type

      faqsPp: [
        { 
          id:       0,
          question: 'Onko palvelun käyttäminen ilmaista?',
          answer:   'Vakuutustarjousten pyytäminen Vakuutustiedot.fi palvelun kautta on täysin maksutonta henkilö-, yritys- ja yhdistysasiakkaille. Vakuutusasiamiehille palvelun käyttö on maksullista.'
        },
        {
          id:       1,
          question: 'Mistä vakuutusyhtiöistä voin pyytää tarjouksia?',
          answer:   'Oletuksena kaikki halukkaat vakuutusyhtiöt tekevät sinulle tarjouksen tarjouspyyntöösi, mutta voit halutessasi estää joitakin yhtiöitä näkemästä tarjouspyyntöäsi.'
        }
      ],

      faqsIr: [
        { 
          id:       0,
          question: 'Paljonko palvelun käyttö maksaa?',
          answer:   'Vakuutusasiamies maksaa vain vastaanottamistaan kelvollisista tarjouspyynnöistä voimassa olevan hinnaston mukaan. Hinnasto kerrotaan profiilin luomisen jälkeen. Pelkän profiilin luominen on ilmaista eikä sido mihinkään.'
        },
        {
          id:       1,
          question: 'Voinko poistaa profiilini palvelusta milloin tahansa?',
          answer:   'Voit irtisanoa palvelusopimuksen koska tahansa kuukauden irtisanomisajalla. Pystyt myös keskeyttämään tarjouspyyntöjen vastaanottamisen milloin tahansa, jolloin kuluja pelkästä profiilista ei synny lainkaan.'
        }
      ],

      faqAnswerShow: [ false, false ]

    } // return

  }, // data

 
  // ===========================================================================================================
  created () {

    if (this.$store.state.userTypeInit == 'pp') {
      this.faqs = this.faqsPp;
    }
    else if (this.$store.state.userTypeInit == 'pc') {
      this.faqs = this.faqsPp;
    }
    if (this.$store.state.userTypeInit == 'ir') {
      this.faqs = this.faqsIr;
    }


  }, // created


  // ===========================================================================================================
  methods: {

    //--- ---------------------------------------------------------------------------
    faqToggle (id) {
      
      //this.$refs.faqAnswerRef[id].classList.toggle('box-minimized-2');

      if ( this.faqAnswerShow[id] == false ) {
        this.faqAnswerShow[id] = true;
        this.$refs['faqAnswerRef-' + id].classList.remove('box-minimized-2');
        this.$refs['faqAnswerRef-' + id].classList.add('box-maximized-2');
        this.$refs['faqTitleMaiRef-' + id].classList.add('title-5');
        this.$refs['faqButtonToggleRef-' + id].classList.remove('button-toggle-plus');
        this.$refs['faqButtonToggleRef-' + id].classList.add('button-toggle-minus');
      }
      else {
        this.faqAnswerShow[id] = false;
        this.$refs['faqAnswerRef-' + id].classList.remove('box-maximized-2');
        this.$refs['faqAnswerRef-' + id].classList.add('box-minimized-2');
        this.$refs['faqTitleMaiRef-' + id].classList.remove('title-5');
        this.$refs['faqButtonToggleRef-' + id].classList.remove('icon-plus');
        this.$refs['faqButtonToggleRef-' + id].classList.remove('button-toggle-minus');
        this.$refs['faqButtonToggleRef-' + id].classList.add('button-toggle-plus');
      }

    },


  }, // methods

}
</script>
<template>
        
  <div class="timer-countdown-basic timer-countdown-basic-style_1">

    <transition name="fade" mode="out-in">
      <div v-if  = "d_show"
           class = "timer-countdown-basic-1">
        <span>{{ t.d }}</span><span>pv</span>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div v-if  = "h_show"
           class = "timer-countdown-basic-1">
        <span>{{ t.h }}</span><span>t</span>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div v-if  = "m_show"
           class = "timer-countdown-basic-1">
        <span>{{ t.m }}</span><span>min</span>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div v-if  = "s_show"
           class = "timer-countdown-basic-1">
        <span>{{ t.s }}</span><span>s</span>
      </div>
    </transition>

  </div>

</template>


<script> // ----------------------------------------------------------------------------------------------------------

// import mixinBoxValidationServerBasic from '@/mixins/mixinBoxValidationServerBasic'
// import boxValidationServerBasic      from '@/components/boxValidationServerBasic'


export default {
  name: 'boxTimerCountdownBasic',
  // mixins:     [ mixinBoxValidationServerBasic ],
  // components: { boxButtonSubmitMain },
  props: [ 'time' ],
  emits: [
          'timer-over'
         ],

  data () {
    return {

      d_show: false,
      h_show: false,
      m_show: false,
      s_show: false,

      timer: null,

      t: { d: '', h: '', m: '', s: '' }


    } // return
  }, // data


  // ===========================================================================================================
  created () {

    this.t_set();
    this.timer_set();

    // If "DARK" THEME
    /*
    if (this.$store.state.userTheme == 'dark') {
      this.windowClass = 'theme-dark-block-3';
    }
    */

  }, // created


  // ===========================================================================================================
  beforeUnmount () {

    this.timer_stop();

  }, // beforeUnmount


  // ===========================================================================================================
  methods: {


    //---  -----------------------------
    timer_set () {
      
      this.timer = setInterval(this.s_act, 1000);

    },


    //---  -----------------------------
    s_act () {

      if (this.t.s > 0) {
        this.t.s--;
      }
      else if (this.t.s == 0) {
        if (this.m_show) {
          this.t.s = 59;
          this.m_act();
        }
        else {
          this.$emit('timer-over');
          this.timer_stop();
        }
      }

    },

    
    //---  -----------------------------
    m_act () {

      if (this.t.m > 0) {
        this.t.m--;
        this.m_set();
      }
      else if (this.t.m == 0) {
        if (this.h_show) {
          this.t.m = 59;
          this.h_act();
        }
      }

    },


    //---  -----------------------------
    h_act () {

      if (this.t.h > 0) {
        this.t.h--;
        this.h_set();
      }
      else if (this.t.h == 0) {
        if (this.d_show) {
          this.t.h = 23;
          this.d_act();
        }
      }

    },


    //---  -----------------------------
    d_act () {

      if (this.t.d > 0) {
        this.t.d--;
        this.d_set();
      }

    },


    //---  -----------------------------
    timer_stop () {
      
      clearInterval(this.timer);

    },


    //---  -----------------------------
    t_set () {

      this.t.d = this.time.d;
      this.t.h = this.time.h;
      this.t.m = this.time.m;
      this.t.s = this.time.s;
      
      this.d_set();
      this.h_set();
      this.m_set();
      this.s_set();

      // // DAY
      // if (this.time.d) {
      //   this.t.d = this.time.d;
      // }
      // else {
      //   this.t.d = '';
      // }

      // // HOUR
      // if (this.time.h) {
      //   this.t.h = this.time.h;
      // }
      // else {
      //   if (this.t.d) {
      //     this.t.h = 0;
      //   }
      //   else {
      //     this.t.h = '';
      //   }
      // }

      // // MINUTE
      // if (this.time.m) {
      //   this.t.m = this.time.m;
      // }
      // else {
      //   if (this.t.d || this.t.h) {
      //     this.t.m = 0;
      //   }
      //   else {
      //     this.t.m = '';
      //   }
      // }

      // // SECOND
      // if (this.time.s) {
      //   this.t.s = this.time.s;
      // }
      // else {
      //   if (this.t.d || this.t.h || this.t.m) {
      //     this.t.s = 0;
      //   }
      //   else {
      //     this.t.s = '';
      //     alert('boxTimerCountdownBasic: parent time is empty');
      //   }
      // }

    },


    //---  -----------------------------
    d_set () {

      if (this.t.d) {
        this.d_show = true;
      }
      else { // if (this.t.d == 0 || this.t.d == '') {
        this.d_show = false;
      }

    },


    //---  -----------------------------
    h_set () {

      if (this.t.h) {
        this.h_show = true;
      }
      else {
        if (!this.d_show) {
          this.h_show = false;
        }
        else {
          this.h_show = true;
        }
      }

    },


    //---  -----------------------------
    m_set () {

      if (this.t.m) {
        this.m_show = true;
      }
      else {
        if (!this.d_show && !this.h_show) {
          this.m_show = false;
        }
        else {
          this.m_show = true;
        }
      }

      // if (this.t.m) {
      //   this.m_show = true;
      // }
      
      // if (this.t.m == 0 || this.t.m == '' && !this.d_show && !this.h_show) {
      //   this.m_show = false;
      // }

    },


    //---  -----------------------------
    s_set () {

      // No need to show sec then with days
      this.s_show = (this.d_show) ? false : true;

    }


  }, // methods


}
</script>


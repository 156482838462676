<template>
  <span class="optional-1">valinnainen</span>
</template>


<script> // -------------------------------------------------------------------------------------------------------

//import mixinXhrPostAsyncApp from '@/mixins/mixinXhrPostAsyncApp'


export default {
  name:    'boxOptionalMain',
  //mixins: [ mixinXhrPostAsyncApp ],
  //components: {itemSignup},

  data () {
    return {
       //userType: '', // Type of the clicked buttom's id
    } // return
  }, // data

  
  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>


<template>

  <!-- TOOLTIPS MAIN .................................................... -->
  <div v-if="tooltipsMainShow" class="relative">
  <transition name="fade">

    <div class="tooltip tooltip-input init-signup-tooltip">
      <!--<span class="button-close-4 m_l-10" @click="$emit('current-box-close')" title="Sulje ikkuna"></span>-->
      {{ tooltipsMainText }}
    </div>

  </transition>
  </div>

</template>


<script> // -----------------------------------------------------------------------------------------------------------

//import itemSignup from '@/components/itemSignup'


export default {
  name:   'boxTooltipsInput',
  props: [ 
          'tooltipsMainShow',
          'tooltipsMainText'
         ],
  //components: {},

  data () {
    return { 
    
      //tooltipsInfoClass:       '',

    } // return
  }, // data


  // ===========================================================================================================
  created () {
  }, // created


  // ===========================================================================================================
  methods: {

    //tooltipsInfoWindowShowHide () {},

  }, // methods

}
</script>



/*
ATTENTION! This mixin should be declared first: before all others validation mixins and components.
*/

export default {

  /*
  ATTENTION! Do not forget to define VALIDATION rules in documentation:
  https://insured.atlassian.net/wiki/spaces/VAKUUTUSTI/pages/290947073/Validation+rules+F+B
  */

  data () {
    return {

      // mixinFlagAllowValidate:  {}, // Defined here for SHARED mixins
      // mixinValidError:         {}, // Defined here for SHARED mixins
      // mixinClassVal:           {}, // Defined here for SHARED mixins
      // mixinValidationOptional: {}  // Defined here for SHARED mixins

    } // return
  }, // data


  methods: {
		
    //--- Show error MSG at empty INPUTs -----------------------------------------------------------------------------------------
    mixinIsEmptyBeforeSubmit (obj) { /* RENAME TO: mixinValidationCommon_IsAnyEmpty */

      for (let inputType in obj) {
        // if (typeof obj[inputType] === 'boolean') { continue; } // No need for now
        if (obj[inputType] == '') {
          this.mixinValidationErrorIfEmpty(inputType);
          this.mixinValidationErrorBoxShowHide(inputType);
        }
        // else {
        //   alert('mixinIsEmptyBeforeSubmit: property type is not string: ' + inputType);
        // }
      }

    },


    //--- Show error MSG at empty INPUTs -----------------------------------------------------------------------------------------
    mixinValidationErrorIfEmpty (inputType, msg = 'Pakollinen tieto') { /* RENAME TO: mixinValidationCommon_IfEmpty */

      if (!this.mixinValidationOptional[inputType]) { this.mixinValidError[inputType] = msg; } // If INPUT is empty and NOT set as an OPTIONAL
      else                                          { this.mixinValidError[inputType] = ''; }
      this.mixinValidationErrorBoxShowHide(inputType);

    },


    //--- If there are ERRORs it shows ERROR BOX ----------------------------------------------------------------------------------
    mixinValidationErrorBoxShowHide (inputType) { /* RENAME TO: mixinValidationCommon_ErrorBoxShowHide */
      
      if (this.mixinValidError[inputType]) { this.mixinClassVal[inputType] = 'validation'; }
      else                                 { this.mixinClassVal[inputType] = 'display-hide'; }

    },


    //---  -----------------------------------------------------------------------------------------
    mixinValidationCommon_ErrorMsgReset (inputType) {

      this.mixinValidError[inputType] = '';

    },


    //---  -----------------------------------------------------------------------------------------
    mixinValidationCommon_Reset (objClass, objError) {

      for (const property in objClass) {
        objClass[property] = 'display-hide';
        objError[property] = '';
      }

    }


    //---  -----------------------------------------------------------------------------------------
    /*
    mixinValidationCommon_ValueAdd (inputType) {

      this.mixinFlagAllowValidate[inputType]  =  false; // Stop from VALIDATION at the first INPUT editing
      this.mixinValidError[inputType]         = ''; // ERROR MSG of VALIDATION
      this.mixinClassVal[inputType]           = 'display-hide'; // SHOW/HIDE ERROR BOX
      this.mixinValidationOptional[inputType] =  false; // For making INPUT as an OPTIONAL:
      
    },
    */
    

  } // methods
}




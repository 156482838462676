<template>
<transition name="fade" mode="out-in">

  <div class="box-flex-backgrounded-3 p-fixed">

    <!-- SIGN UP: FORM ................ -->
    <item-sign-up-form v-if                    = "signUpFormShow"
                       parentType              = "pop-upped"
                      :currentPageType         = "currentPageType" 
                      @sign-up-successful-show = "signUpFormShow=false"
                      @current-box-close       = "$emit('current-box-close')"></item-sign-up-form>

    <!-- SIGN UP: SUCCESSFUL .......... -->
    <item-sign-up-successful v-else
                             parentType        = "pop-upped"
                            @current-box-close = "$emit('current-box-close')"></item-sign-up-successful>

  </div>

</transition>
</template>


<script> // ----------------------------------------------------------------------------------------------------------

import itemSignUpForm       from '@/components/itemSignUp/itemSignUpForm'
import itemSignUpSuccessful from '@/components/itemSignUp/itemSignUpSuccessful'


export default {
  name:        'itemSignUpPopUpped',
  components: { 
                itemSignUpForm,
                itemSignUpSuccessful
              },
  props:      [
                'currentPageType'
              ],
  emits:      [
               'current-box-close'
              ],
  //mixins:     [ mixinXhrPostAsyncApp ],


  data () {

    return {

      buttonTheme1:    'button-theme-1',
      //buttonTheme2:    'button-theme-2',
      inputTextTheme1: 'input-text-theme-1',

      signUpFormShow: true,

    }

  }, // data


  // ===========================================================================================================
  created () {
    document.body.classList.add('overflow-hidden'); // For NOT to SCROLL whole page at opened POP-UP WINDOW
  },


  // ===========================================================================================================
  beforeUnmount () {
    document.body.classList.remove('overflow-hidden'); // After closing POP-UP WINDOW there is no need in that CLASS
  }, // beforeUnmount


  // ===========================================================================================================
  methods: {

    //--- Reset reCaptcha VALIDATION ---------------------------------------------
    //recaptchaValidationReset () {},

  }, // methods


} // default

</script>

export default {

  /*
  ATTENTION! Do not forget to define VALIDATION rules in documentation:
  https://insured.atlassian.net/wiki/spaces/VAKUUTUSTI/pages/290947073/Validation+rules+F+B
  */

  data () {
    return {

      mixinValidation_companyName_inputMaxLength: 100,

      mixinFlagAllowValidate:  { companyName:  false         }, /*mixinValidationAllows*/     // Stop from VALIDATION at the first INPUT editing
      mixinValidError:         { companyName: ''             }, /*mixinValidationErrorTexts*/ // ERROR MSG of VALIDATION
      mixinClassVal:           { companyName: 'display-hide' }, /*mixinValidationClasses*/    // SHOW/HIDE ERROR BOX
      mixinValidationOptional: { companyName:  false         },                               // For making INPUT as an OPTIONAL:

    } // return
  }, // data


  methods: {
		
    //---  -----------------------------------------------------------------------------------------------
    mixinValidation_companyName (inputValue) { 

      var inputType = 'companyName';

      // Need to allow VALIDATION after finding empty INPUTS at pushing "SUBMIT" button 
      if (this.mixinValidError[inputType]) this.mixinFlagAllowValidate[inputType] = true;

      if (this.mixinFlagAllowValidate[inputType]) {
        this.mixinValidation_companyName_Check(inputValue);
        this.mixinValidationErrorBoxShowHide(inputType);
      }

    },

    //--- Validation starts only after user has filles INPUT for the first time ---------------------------
    mixinValidation_companyName_Start (inputValue) {

      var inputType = 'companyName';

      this.mixinFlagAllowValidate[inputType] = true;
      this.mixinValidation_companyName(inputValue);

    },
 
    //-----------------------------------------------------------------------------------------------------
    //--- INNER WORKER ------------------------------------------------------------------------------------
    mixinValidation_companyName_Check (inputValue) {

      var inputType = 'companyName';

      var validRegex = (value) => { 
        // At least 1 words 2-100, ANY character is allowed
        var re = /^(\s|\S)*(\S){2,100}(\s|\S)*$/gm;
        // var re = /^[a-zäöåA-ZÄÖÅ.\s]{2,25}$/gm;
        return re.test(value);
      }

      if (!inputValue) { // If empty
        this.mixinValidationErrorIfEmpty(inputType);
      } 
      else {
        if (!validRegex(inputValue)) {
          this.mixinValidError[inputType] = 'Tarkista yrityksen nimi';
        }
        else {
          this.mixinValidationCommon_ErrorMsgReset(inputType);
        }
      }
  
    },

  } // methods

}




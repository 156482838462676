
// import  { router } from '@/router/index' // Because this.$router.push({ path: 'user' }) do not work here
// import { store } from '@/store' // Because this.$store.commit('userTypeReset') do not work here


export default {


  data () {
    return {

      mixinSignOut_automatically: {
        titleMain: 'Sinut on kirjattu ulos automaattisesti',
        textMain:  'Järjestelmän aikakatkaisu kirjasi sinut ulos'
      },

    } // return
  }, // data


	methods: {
		

		//--- SING OUT ----------------------------------------------------------------------
		mixinSignOut (windowNotificationSimpleBasic_show/*=false*/) {


      let succeeded = () => {
        
        //console.log('mixinSignOut works.');
        this.$store.commit('userTypeReset'); // Because this.$store.commit('userTypeReset') do not work here
        // console.log(':::VUE3-BUG-FIXING-1::: mixinSignOut: store.state.userType IS SET TO ' + this.$store.state.userType);    

        this.$router.push({ path: '/' }); // Because this.$router.push({ path: 'user' }) do not work here
        // console.log('mixinSignOut succeeded: DONE');

        if (windowNotificationSimpleBasic_show === true) {
          this.$store.commit('windowNotificationSimpleBasic_Set', this.mixinSignOut_automatically);
          // console.log('windowNotificationSimpleBasic_show = ' + windowNotificationSimpleBasic_show);
        }

        //this.$router.push({ path: 'user' });
        //this.$router.go({ path: '/' });
        
      }


      if (this.$store.state.DEV_Show) {
        if (process.env.NODE_ENV == 'development') {
        succeeded(); // To simulate activity
        }
      }
      else {

        var xhr = new XMLHttpRequest();

        xhr.onreadystatechange = function() {
          if (xhr.readyState == 4 && xhr.status == 200) {
            succeeded();
          } 
        };

        xhr.open('POST', '/kirjauduUlos', true);
        xhr.onerror = function () { alert('An error occurred during the Sign Out'); };
        xhr.send();

      }


    },  


	} // methods
}

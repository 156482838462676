<template>
  <!-- FOOTER MAIN ................................................................................................ -->
  <footer class="footer-container">
  
    <div class="footer-main">

      <div class="footer-main-1">
        <div title="Vakuutustiedot.fi logo"></div> <!-- LOGO here (look at CSS) -->
        <div>Kilpailuta vakuutukset helposti ja ilmaiseksi netissä</div>
        <div>Vakuutustiedot.fi on kätevin tapa kilpailuttaa nykyisiä ja uusia vakuutuksia</div>
      </div>
      
      <div class="footer-main-2">
        <div>
          <div>YRITYSTIEDOT</div>
          <div @click="$router.push({ path: '/tietoameista' })"><a>Tietoa meistä</a></div>
          <div @click="$router.push({ path: '/tietosuojaseloste' })"><a>Tietosuojaseloste</a></div>    
          <div @click="$router.push({ path: '/kayttoehdot' })"><a>Käyttöehdot</a></div>
          <div @click="$router.push({ path: '/evastekaytannot' })"><a>Evästekäytäntö</a></div>
        </div>
        <div>
          <div>TUKI</div>
          <div @click="$router.push({ path: '/useinkysyttya' })"><a>Usein kysyttyä</a></div>
          <div @click="$router.push({ path: '/unohditkosalasanan' })"><a>Unohditko salasanan?</a></div>
          <div @click="$router.push({ path: '/tilinaktivointiin' })"><a>Tilin aktivointiin</a></div>
          <div @click="$router.push({ path: '/asiakastukijapalaute' })"><a>Asiakastuki ja palaute</a></div>
        </div>
        <div>
          <div>OHJEET</div>
          <div @click="$router.push({ path: '/yleisetsaannot' })"><a>Yleiset säännöt</a></div>
          <div @click="$router.push({ path: '/kilpailutusprosessi' })"><a>Kilpailutusprosessi</a></div>
          <div @click="$router.push({ path: '/turvallisuusohjeet' })"><a>Turvallisuusohjeet</a></div>
          <div @click="$router.push({ path: '/tienaasuosittelemalla' })"><a>Tienaa suosittelemalla</a></div>
        </div>
        <div>
          <div>VINKIT</div>
          <div @click="$router.push({ path: '/yleisetvinkit' })"><a>Yleiset vinkit</a></div>
          <div @click="$router.push({ path: '/tietoavakuutuksista' })"><a>Tietoa vakuutuksista</a></div>
          <div @click="$router.push({ path: '/yksityisetvakuutukset' })"><a>Yksityiset vakuutukset</a></div>
          <div @click="$router.push({ path: '/yritysvakuutukset' })"><a>Yritysvakuutukset</a></div>
        </div>
      </div>
      
      <div class="footer-main-3">
      <!--
        <div>
         <a class="footer-main-social" id="footer-main-social-facebook" href="#"></a>
         <a class="footer-main-social" id="footer-main-social-twitter"  href="#"></a>
         <a class="footer-main-social" id="footer-main-social-linkedin" href="#"></a>
        </div>
        <div>Copyright &#169; 2020. Vakuutustiedot.fi on <a @click="$router.push({ path: '/tietoameista' })">Insured Oy</a>:n kehittämä ja ylläpitämä palvelu.</div>
      -->
        <div class = "footer-main-logo-insured"
            @click = "$router.push({ path: '/tietoameista' })"
             title = "Insured Oy logo"></div>
      </div>

      <!-- <div class="footer-version">{{ $store.state.projectVersion }}</div> -->

    </div>
  
  </footer>
</template>


<script> // -----------------------------------------------------------------------------------------------------------

//import mixinBoxValidationServerBasic from '@/mixins/mixinBoxValidationServerBasic'

//import boxValidationServerBasic      from '@/components/boxValidationServerBasic'


export default {
  name:        'itemFooterMain',
  mixins:     [ 
                //mixinXhrPostAsyncApp,
              ],
  components: { 
                //boxValidationServerBasic
              },
  
  data () {

    return {

      //test: true,

    } // return
  }, // data


  // ===========================================================================================================
  methods: {
    
    /*
    //--- RESET all values and DATA after SIGN IN window is closed ---------------------------------------------
    initSigninResetDefault () { 
      Object.assign(this.$data, this.$options.data.apply(this)); 
    },
    */

  }, // methods


}
</script>


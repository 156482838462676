
// import   router  from '@/router/index' // Because this.$router.push({ path: 'user' }) do not work here
// import { store } from '@/store/index' // Because this.$store.commit('userTypeReset') do not work here


export default {

  /*
  ATTENTION!
  There is no "this.$" before "store" and "state", it just does not work here.
  Use instead:
  "this.$router" -> "router"
  "this.$store"  -> "store"
  */

	methods: {
		

    //--- ---------------------------------------------------------------------------------------------------------
    mixinUserDefine_TypeGet (parentType) {
      
      if (this.$store.state.DEV_Show) { // "DEV MODE"
        if (process.env.NODE_ENV == 'development') {
          this.$store.commit('pageShowSet', true); // Just let it be shown
        }
      }
      else { // "REAL WORLD"

          // console.log('::: 1. mixinUserDefine_TypeGet STARTS.');

          // Until USER is not defined, F does not show any PAGE CONTENT
          this.$store.commit('pageShowSet', false);

          var xhr = new XMLHttpRequest();
          
          xhr.onreadystatechange = () => {

            if (xhr.readyState == 4) {

              if (xhr.status == 200) {
                var response = JSON.parse(xhr.response);
                if (response.userTypeName || response.userTypeName !== null) {
                  if      (response.userTypeName == 'private_person')           { this.$store.commit('userTypeSet', 'pp'); } // PRIVATE_CLIENT
                  else if (response.userTypeName == 'private_company')          { this.$store.commit('userTypeSet', 'pc'); } // PRIVATE_COMPANY
                  else if (response.userTypeName == 'insurance_representative') { this.$store.commit('userTypeSet', 'ir'); } // INSURANCE_COMPANY_REPRESENTATIVE
                  else if (response.userTypeName == 'insurance_company')        { alert('mixinUserDefine: IC is not connected'); } // INSURANCE_COMPANY_EMPLOYEE
                  else if (response.userTypeName == 'super_admin')              { this.$store.commit('userTypeSet', 'sa'); } // ADMIN
                  else if (response.userTypeName == 'root')                     { this.$store.commit('userTypeSet', 'rsa'); } // ROOT
                  else { this.mixinUserDefine_toErrorPage(); /*console.log(':::VUE3-BUG-FIXING-1::: mixinUserDefine_TypeGet: response.userTypeName IS NOT SET');*/ }
                }
                else { this.mixinUserDefine_userTypeReset(); }

                // console.log('::: 2. mixinUserDefine_TypeGet DONE: ' + this.$store.state.userType);

              } 
              else if (xhr.status >= 400) { this.mixinUserDefine_userTypeReset(); }

              if      (parentType == 'mixinUserDefine_RedirectFromSharedPages')  { this.mixinUserDefine_RedirectFromSharedPages(true); }
              else if (parentType == 'mixinUserDefine_RedirectFromAccountPages') { this.mixinUserDefine_RedirectFromAccountPages(true); }

            }

          };

          xhr.onerror = () => {
            // console.log(url + ": An error occurred during mixinUserDefine_TypeGet");
            this.mixinUserDefine_toErrorPage();
            // console.log(':::VUE3-BUG-FIXING-1::: mixinUserDefine_TypeGet: xhr.onerror');
          };

          xhr.open('POST', '/getUserType', false); // ATTENTION: SYNC REQUEST here
          xhr.send();

      }

    },


    //--- ---------------------------------------------------------------------------------------------------------
    mixinUserDefine_RedirectFromSharedPages (typeGetIsReady) { // Like any of INIT pages


      // this.$store.commit('pageShowSet', false); // RESET it

      if (typeGetIsReady) {
        if (this.$store.state.userType) { // If "userType" (session) exist: means USER was logged in
          if      (this.$store.state.userType == 'pp')  { this.$router.push({ path: '/client/pp' }); }
          else if (this.$store.state.userType == 'pc')  { this.$router.push({ path: '/client/pc' }); }
          else if (this.$store.state.userType == 'ir')  { this.$router.push({ path: '/seller/ir' }); }
          else if (this.$store.state.userType == 'sa')  { /* For SA is allowed. Just do nothing */ }
          else if (this.$store.state.userType == 'rsa') { /* For RSA is allowed. Just do nothing */ }
          //else if (this.$store.state.userType == 'ic') { this.$router.push({ path: '/seller/ic' }); }
          else                                   { this.mixinUserDefine_toErrorPage(); /*console.log(':::VUE3-BUG-FIXING-1::: mixinUserDefine_RedirectFromSharedPages: store.state.userType IS EMPTY');*/ }
        }
        // this.$store.commit('pageShowSet', true);
      }
      else { this.mixinUserDefine_TypeGet('mixinUserDefine_RedirectFromSharedPages'); } // Not ready? Get and wait for it!

    },


    //--- ---------------------------------------------------------------------------------------------------------
    mixinUserDefine_userTypeReset () {

      this.$store.commit('userTypeReset');
      //if (!this.$store.state.DEV_Show) this.$store.commit('userTypeReset');

    },


    //--- ---------------------------------------------------------------------------------------------------------
    mixinUserDefine_toErrorPage () {

      this.$router.push({ path: '/status404' });

    },


    //--- ---------------------------------------------------------------------------------------------------------
    mixinUserDefine_RedirectFromAccountPages (typeGetIsReady) {
      
      if (typeGetIsReady) {
        // console.log('::: 3. mixinUserDefine_RedirectFromAccountPages CONTINUE...');
        // console.log('::: 4. mixinUserDefine_TypeGet STORE: ' + this.$store.state.userType);
        // console.log('::: 5. mixinUserDefine_TypeGet ROUTE: ' + this.$route.params.userType);
        if (this.$store.state.userType) { // If "userType" (session) exist: means USER was logged in
          if (this.$store.state.userType == this.$route.params.userType) { this.$store.commit('pageShowSet', true); } // user type and page match, F can show the page
          else                                                     { this.mixinUserDefine_toErrorPage(); /*console.log(':::VUE3-BUG-FIXING-1::: mixinUserDefine_RedirectFromAccountPages: store.state.userType IS NOT THE SAME AS: this.$route.params.userType');*/ } // ... not match: to the Error page
        }
        else { this.mixinUserDefine_toErrorPage(); /*console.log(':::VUE3-BUG-FIXING-1::: mixinUserDefine_RedirectFromAccountPages: store.state.userType IS EMPTY');*/ } // NOT signed in USER
      }
      else { this.mixinUserDefine_TypeGet('mixinUserDefine_RedirectFromAccountPages'); } // Not ready? Get and wait for it!

    },


    //--- If USER is signed in, when he/she cannot pass to any of INIT pages --------------------------------------
    /*
    mixinUserIsSignedIn () {

      if (this.$store.state.userType) { // If "userType" exist
        if      (this.$store.state.userType == 'pp') { this.$router.push({ path: '/client/pp' }); }
        else if (this.$store.state.userType == 'pc') { this.$router.push({ path: '/client/pc' }); }
        else if (this.$store.state.userType == 'ir') { this.$router.push({ path: '/seller/ir' }); }
        //else if (this.$store.state.userType == 'ic') { this.$router.push({ path: '/seller/ic' }); }
        else                                   { this.mixinUserDefine_toErrorPage(); }
      }

    },
    */

    //--- ---------------------------------------------------------------------------------------------------------
    /*
    TEST () {

      this.$store.commit('userTypeSet', 'XX');

      alert(this.$route.params.userType);
      alert(this.$store.state.userType);

    }
    */

	} // methods
}

<template>

  <!-- BUTTON: SUBMIT MAIN ............................................................................. -->
  <div>
    <div class="relative"><!-- The 2nd DIV is for dealing with all this possible paddings in PARENTS -->
    
      <div :class = "loadingIconClass"
            v-if  = "!submitAllow">
      </div>
    
      <input type        = "button"
            :class       = "[{ 'box-button-submit-main-disable' : !submitAllow }, buttonTheme, buttonClassMain]"
            :value       = "submitButtonValueSet"
            :disabled    = "!submitAllow||parentDisabled"
            :data-action = "dataActionValue"
            @click       = "$emit('submit-main')">
            <!-- @keyup.enter = "keyup_enter" -->
            
      <!--
      <input type        = "button"
             class       = "button-3"
            :class       = "[{ 'box-button-submit-main-disable': !submitAllow }, buttonTheme1]"
            :value       = "submitButtonValueSet"
            :disabled    = "!submitAllow||parentDisabled"
            :data-action = "dataActionValue"
            @click       = "$emit('submit-main')">
      -->
    
    </div>
  </div>

</template>


<script> // -------------------------------------------------------------------------------------------------------

//import mixinXhrPostAsyncApp from '@/mixins/mixinXhrPostAsyncApp'


export default {
  name:    'boxButtonSubmitMain',
  //mixins: [ mixinXhrPostAsyncApp ],
  //components: {itemSignup},

  props:      [
               'submitAllow', 
               'submitButtonValue',
               'parentDisabled', // OPTIONAL: when PARENT has "disabled" attr for this INPUT
               'dataAction', // OPTIONAL: only when reCaptcha in use
               'theme' // OPTIONAL: 'light', 'admin', 'light-smaller'
               // 'parentType', // OPTIONAL: for ex. "admin", "admin-bigger"
               // 'keyupEnterAllow', // OPTIONAL
              ],
  emits:      [
               'submit-main'
              ],


  data () {
    return {

      buttonTheme:      'button-theme-1',
      buttonClassMain:  'button-3',
      loadingIconClass: 'box-loading-icon-button-3',
      //buttonClassMainDisabled: 'box-button-submit-main-disable',

      dataActionValue: false,

      submitButtonValue_sending: 'Lähetetään...'

    } // return
  }, // data


  // ===========================================================================================================
  mounted () {

    if (this.dataAction) this.dataActionValue = this.dataAction;

    // if (this.parentType == 'admin-bigger') {
      // this.buttonClassMain = 'admin-button-1-bigger';
      // this.buttonTheme1    = 'admin-button-theme-1'
    // }
    
    // THEME:
    if (this.theme == 'light') {
      this.buttonTheme = "button-theme-2";
    }
    else if (this.theme == 'basic-smaller') {
      this.buttonClassMain  = 'button-4 w-auto';
      // this.buttonTheme      = "button-theme-1";
      // this.loadingIconClass = 'box-loading-icon-button-4';
      // this.submitButtonValue_sending = "Odota..."
    }
    else if (this.theme == 'light-smaller') {
      this.buttonTheme      = "button-theme-2";
      this.buttonClassMain  = 'button-4 w-auto';
      this.loadingIconClass = 'box-loading-icon-button-4';
      this.submitButtonValue_sending = "Odota..."
    }
    else if (this.theme == 'admin-bigger') {
      this.buttonClassMain = 'admin-button-1-bigger';
      this.buttonTheme     = "admin-button-theme-1";
    }

  }, // mounted


  // ===========================================================================================================
  computed: {
    
    submitButtonValueSet () {
      return (this.submitAllow) ? this.submitButtonValue : this.submitButtonValue_sending;
      // if (this.submitAllow) { return this.submitButtonValue } else { return 'Lähetetään...' }
    },
/*
    submitButtonDisabled () {
      if (this.submitAllow) { return this.submitButtonValue } else { return 'Lähetetään...' }
    }
*/
  }, // computed


  // ===========================================================================================================
  methods: {
    
    
    // keyup_enter () {
    //   if (this.keyupEnterAllow) this.$emit('submit-main');
    // }
    

  }, // methods

}
</script>


<template>

    <!-- All msg of succesful requests goes here ............................................................................. -->

    <div v-show = "$store.state.requestSuccessfulMain.length"
         class  = "request-successful-main relative">

      <transition-group name="list">

        <div class = "request-successful-main-msg" 
             v-for = "msg in $store.state.requestSuccessfulMain" 
            :key   = "'boxRequestSuccessfulMain-' + msg.id">
          <span class="button-close-4-light m_l-10" @click="$store.commit('requestSuccessfulMainDelete', msg.id)" title="Sulje ikkuna"></span>
          {{ msg.msg }}
        </div>

      </transition-group>

    </div>

</template>


<script> // -------------------------------------------------------------------------------------------------------

//import mixinXhrPostAsyncApp from '@/mixins/mixinXhrPostAsyncApp'


export default {
  name:    'boxRequestSuccessfulMain',
  //mixins: [ mixinXhrPostAsyncApp ],
  //components: {itemSignup},

  data () {
    return {
       //userType: '', // Type of the clicked buttom's id
    } // return
  }, // data

  
  // ===========================================================================================================
  methods: {
    
    /*
    // --- --------------------------------------
    msgClose (index) {
      this.$store.commit('requestSuccessfulMainDelete', index);
    }
    */

  }, // methods

}
</script>


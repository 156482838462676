
/*
It is a kind of common simple validation for TEXTS.
*/

export default {

  /*
  ATTENTION! Do not forget to define VALIDATION rules in documentation:
  https://insured.atlassian.net/wiki/spaces/VAKUUTUSTI/pages/290947073/Validation+rules+F+B
  */

  data () {
    return {

      //TODO: For some reason reactivity does not work by adding values into this objects. Need to find out a way to do it.

      mixinValidation_SharedNameSimple_inputMaxLength: 100,

      mixinFlagAllowValidate: { /*mixinValidationAllows*/ // Stop from VALIDATION at the first INPUT editing
        firstName: false,
        lastName:  false
      },

      mixinValidError: { /*mixinValidationErrorTexts*/ // ERROR MSG of VALIDATION
        firstName: '',
        lastName:  ''
      },

      mixinClassVal: { /*mixinValidationClasses*/ // SHOW/HIDE ERROR BOX
        firstName: 'display-hide',
        lastName:  'display-hide'
      },

      mixinValidationOptional: { // For making INPUT as an OPTIONAL:
        firstName: false,
        lastName:  false
      }

    } // return
  }, // data


  methods: {
		
    //---  -----------------------------------------------------------------------------------------------
    mixinValidation_SharedNameSimple (inputValue, inputType) {

      // Need to allow VALIDATION after finding empty INPUTS at pushing "SUBMIT" button 
      if (this.mixinValidError[inputType]) this.mixinFlagAllowValidate[inputType] = true;

      if (this.mixinFlagAllowValidate[inputType]) {
        this.mixinValidation_SharedNameSimple_Check(inputValue, inputType);
        this.mixinValidationErrorBoxShowHide(inputType);
        //console.log('mixinValidation_SharedNameSimple: ' + this.mixinValidError[inputType]);
        //console.log('mixinValidation_SharedNameSimple: ' + this.mixinClassVal[inputType]);
      }

    },

    //--- Validation starts only after user has filles INPUT for the first time ---------------------------
    mixinValidation_SharedNameSimple_Start (inputValue, inputType) {
      
      //this.mixinValidationCommon_ValueAdd(inputType); // In use only for SHARED mixins
      this.mixinFlagAllowValidate[inputType] = true;
      this.mixinValidation_SharedNameSimple(inputValue, inputType);

    },
 
    //-----------------------------------------------------------------------------------------------------
    //--- INNER WORKER ------------------------------------------------------------------------------------
    mixinValidation_SharedNameSimple_Check (inputValue, inputType) {

      var validRegex = () => {
        // Match at least 1 symbol at least one word, any letter or ' sign from list:
        // eslint-disable-next-line
        var re = /^((\s)*[A-Za-zŠŒŽŸÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßšœžàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ\'\-]+(\s)*)+$/gm;
        return re.test(inputValue);
      }

      if (!inputValue) { // If empty
        this.mixinValidationErrorIfEmpty(inputType);
      } 
      else {
        if (!validRegex()) {
          switch (inputType) { 
            // case 'icName':                this.mixinValidError[inputType] = 'Tarkista vakuutusyhtiön nimi'; break;
            case 'firstName': this.mixinValidError[inputType] = 'Tarkista nimi'; break;
            case 'lastName':  this.mixinValidError[inputType] = 'Tarkista sukunimi'; break;
            default: alert('ERROR! mixinValidation_SharedNameSimple_Check: inputType "' + inputType + '" was not found');
          }
        }
        else {
          this.mixinValidationCommon_ErrorMsgReset(inputType);
        }
      }
  
    },

  } // methods

}




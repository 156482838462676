<template>
<transition name="slide" mode="out-in">  
<div>

<!-- FORGOT PASSWORD ..................................................................... -->
<div v-if="switchForgotPasswordOrMsgEmailSent">

  <!-- TOP ........................................................... -->
  <div class="box-2-top">
    <div><h3 class="theme-light-title-1">Unohditko salasanasi?</h3></div>
    <span v-if="buttonCloseShow" class="button-close" @click="$emit('current-box-close')" title="Sulje ikkuna"></span>
  </div>

  <!-- MIDDLE ........................................................ -->
  <div class="box-2-middle">

    <!-- VALIDATION BOX from SERVER -->
    <box-validation-server-basic v-show            = "mixinValidationServerBasicShow"
                                :messages          = "mixinValidationServerBasicMessages"
                                @current-box-close = "mixinBoxValidationServerBasicClose"></box-validation-server-basic>

    <form class="forgot-password" novalidate="true">
    
    <div>Kirjoita sähköpostiosoitteesi ja klikkaa "Lähetä pyyntö"-painiketta. Saat sähköpostiisi linkin, jota klikkaamalla voit vaihtaa salasanasi uuteen.</div>

      <!-- EMAILADDRESS ......................... -->
      <div>
        <input type         = "text"
               class        = "input-text-3"
              :class        = "inputTextTheme1"
               v-model      = "forgotPassword.emailAddress"
              @input        = "mixinValidation_emailAddress(forgotPassword.emailAddress)"
              @blur         = "mixinValidation_emailAddress_Start(forgotPassword.emailAddress)"
              :maxlength    = "mixinValidation_emailAddress_inputMaxLength"
               autocomplete = "email"
               placeholder  = "Sähköpostiosoite">
        <!-- VALIDATION BOX -->
        <div :class="mixinClassVal.emailAddress">{{ mixinValidError.emailAddress }}</div>
      </div>

      <!-- reCAPTCHA ............................ -->
      <!--<div id="recaptcha" class="g-recaptcha" data-sitekey="6Lco4zoUAAAAAOZ33P2TVXjidLKYvpK2oA5Evscb"></div>-->

      <!-- SUBMIT ............................... -->
      <!--<input type="button" class="button-3" :class="buttonTheme1" @click="forgotPasswordSubmit" data-action="forgotPasswordSubmit" value="Lähetä pyyntö">-->
      <box-button-submit-main submitButtonValue = "Lähetä pyyntö"
                             :submitAllow       = "submitAllow"
                              dataAction        = "forgotPasswordSubmit"
                             @submit-main       = "forgotPasswordSubmit"></box-button-submit-main>

    </form>

  </div>

</div>

<!-- MSG "EMAIL HAS BEEN SENT"....................................................................................... -->
<div v-else class="windowed-2-small">

  <div><h3 class="theme-light-title-1">Pyynnön lähettäminen onnistui</h3></div>
  <div class="box-plain_text-1 w-100">Jos sähköpostiosoitteesi löytyy palvelustamme, saat hetken kuluttua siihen linkin, jota klikkaamalla voit vaihtaa salasanasi uuteen.<br/><br/>Linkin saapuminen sähköpostiisi voi viedä muutaman minuutin. Tarvittaessa tarkista sähköpostisi roskapostikansio.</div>
  <input type  = "button"
         class = "button-3"
        :class = "buttonTheme1" 
        @click = "msgSuccessfulClose"
         value = "Sulje">
  
</div>

</div>
</transition>
</template>


<script> // -----------------------------------------------------------------------------------------------------------

import mixinValidationCommon        from '@/mixins/mixinValidation/mixinValidationCommon' 
import mixinValidation_emailAddress from '@/mixins/mixinValidation/mixinValidation_emailAddress'
import mixinValidation_recaptcha    from '@/mixins/mixinValidation/mixinValidation_recaptcha' 

import mixinXhrPostAsyncApp          from '@/mixins/mixinXhrPostAsyncApp'
import mixinBoxValidationServerBasic from '@/mixins/mixinBoxValidationServerBasic'
import mixinTrim                     from '@/mixins/mixinTrim'

import boxValidationServerBasic from '@/components/boxValidationServerBasic'
import boxButtonSubmitMain      from '@/components/boxButtonSubmitMain'


export default {
  name:        'itemForgotPassword',
  mixins:     [ 
                mixinValidationCommon,
                mixinValidation_emailAddress,
                mixinValidation_recaptcha,
                mixinXhrPostAsyncApp,
                mixinBoxValidationServerBasic,
                mixinTrim
              ],
  components: {
                boxValidationServerBasic,
                boxButtonSubmitMain
              },
  props:      [
               'parentType' // OPTIONAL: to make some changes according to PARENT
              ],
  emits:      [
               'current-box-close'
              ],

  
  data () {

    return {

      buttonTheme1:    'button-theme-1',
      inputTextTheme1: 'input-text-theme-1',

      submitAllow: true, // Prevent multiclicking of Submit

      forgotPassword: { // Values of INPUTS
        emailAddress: ''
      },

      recaptcha: '', // Value of RECAPTCHA

      buttonCloseShow: true,

      switchForgotPasswordOrMsgEmailSent: true, // Show FORGOT PASSWORD INPUT as a default, switch to MSG: "email have been sent" 
      //serverValidationBoxClassMain:      'display-hide', // SHOW/HIDE validation box  with BAD REQUEST to SERVER
      //serverValidationBoxMessage:        '', // SHOW/HIDE ERROR MSG       with BAD REQUEST to SERVER

    } // return
  }, // data


  // ===========================================================================================================
  created () { 
    //this.$nextTick( function () { grecaptcha.render("recaptcha"); } ); // Render reCAPTCHa
  }, // created


  // ===========================================================================================================
  mounted () {

    // Not need to show "Close" button in FOOTER's information page "Unohditko salasanasi?"
    if (this.parentType == 'pageInfo') this.buttonCloseShow = false;

  }, // mounted


  // ===========================================================================================================
  methods: {


    //--- Create from SERVER response user friendly ERROR MSGs -------------------------------------------------
    xhrPostAsyncAppResponseMsgs (response) {
      
      if (response.succeeded == true) {
        this.switchForgotPasswordOrMsgEmailSent = false; // Switch to MSG: "email have been sent"
      }
      else if (response.succeeded == false) {
        if (response.summaryMessage == 'Väärä käyttäjä.') {
          this.switchForgotPasswordOrMsgEmailSent = false; // Even if email is invalid, show "email have been sent"  
        }
        else {
          this.mixinValidationServerBasicShow = true;
          this.mixinValidationServerBasicMessages.push(response.summaryMessage); // In case of some Server problems
        }
      }

      this.submitAllow = true;

    },


    //--- Submit INPUTs and sent its to the SERVER -------------------------------------------------------------
    async forgotPasswordSubmit () {

      if (this.submitAllow) {

        this.mixinBoxValidationServerBasicClose(); // RESET ERROR msgs from SERVER

        var v = this.forgotPassword;
        var e = this.mixinValidError;

        this.mixinTrimObject(v); // To get rid of spaces from start/end of the strings

        if (v.emailAddress 
        && !e.emailAddress) {

          await this.$recaptchaLoaded(); // (optional) Wait until recaptcha has been loaded.
          this.recaptcha = await this.$recaptcha('forgotPasswordSubmit'); // Execute reCAPTCHA with action "submit".
          //this.recaptcha = grecaptcha.getResponse(); // Put reCaptcha value into ibject method

          //alert(this.recaptcha);

          if (this.recaptcha) {

            this.submitAllow = false;

            var url    = 'requestPasswordResetToken' // URL address for HTTPXMLrequest in mixinXhrPostAsyncApp
            var header = 'app';
            
            var obj    = 'email='                 + encodeURIComponent(v.emailAddress) + 
                         '&g-recaptcha-response=' + this.recaptcha; // Request to SERVER

            this.mixinXhrPostAsyncApp(obj, url, header);

          }
          else { this.mixinValidation_recaptcha(this.recaptcha); }
          
        }
        else { this.mixinIsEmptyBeforeSubmit(v); } // Show error MSG at empty INPUTs 

      }

    },


    //--- -----------------------------------------------------------------------------------------
    msgSuccessfulClose () {

      if (this.parentType == 'pageInfo') { this.switchForgotPasswordOrMsgEmailSent = true; }
      else                               { this.$emit('current-box-close'); }

    }


    //--- ----------------------------------------------------------------------------------------
    /*
    recaptchaValidation (recaptcha) {
      
      console.log('...recaptchaValidation STARTS');
      if (!recaptcha) {
        this.mixinValidationServerBasicShow = true; // SHOWs validation box
        this.mixinValidationServerBasicMessages.push('ReCAPTCHA virhe. Päivitä sivu ja yritä uudelleen.');
      }
      
    },
    */


  }, // methods


}
</script>


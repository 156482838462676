<template>
<transition name="fade" mode="out-in">

    
    <!--  ............................................................................... -->

    <div class="box-flex-backgrounded-3 p-fixed"><!-- DARKBLUE BACKGROUNDED -->
       
      <div class = "windowed-2-small window-notification-simple-basic"
          :class = "windowClass">
        
        <div>
          <h3 class="theme-light-title-1">{{ info_default.titleMain }}</h3>
        </div>

        <div>{{ info_default.textMain }}</div>
          
        <box-timer-countdown-basic :time       = "time"
                                   @timer-over = "$emit('timer-over')"></box-timer-countdown-basic>

        <div>
          <input type  = "button"
                 class = "button-3" 
                :class = "buttonTheme1"
                 value = "Haluan jatkaa"
                @click = "$emit('activity-wake-up')">
        </div>


      </div>
  
  </div>


</transition>
</template>


<script> // ----------------------------------------------------------------------------


// import mixinTimerCountdownSignOut    from '@/mixins/mixinTimerCountdownSignOut'

import boxTimerCountdownBasic from '@/components/boxTimerCountdownBasic'


export default {
  name:        'windowTimeLeft',
  components: { boxTimerCountdownBasic },
  props:      [
                'bodyOverflowHidden',
                'parentType' // OPTIONAL: "sellerOffer",
              ],
  emits:      [
               'timer-over',
               'activity-wake-up'
              ],
  // mixins:     [ mixinTimerCountdownSignOut ],


  data () {
    return {

      info_default: {
        titleMain: 'Haluatko jatkaa palvelun käyttöä?',
        textMain:  'Sinut kirjataan ulos alla esitetyn ajan kuluessa'
      },

      info_sellerOffer: {
        titleMain: 'Haluatko jatkaa lomakkeen täyttämistä?',
        textMain:  'Lomake sulkeutuu automaattisesti alla esitetyn ajan kuluessa'
      },

      buttonTheme1: 'button-theme-1',
      windowClass:  'theme-light-block-3',

      time: { d: 0, h: 0, m: 5, s: 0 } // HUOM! Keep that style
      // buttonTheme2:    'button-theme-2',
      // inputTextTheme1: 'input-text-theme-1',

      // submitAllow: true, // Prevent multiclicking of Submit
      
      
      // accountDeleteFormShow:     true,
      // buttonDeleteAccountValue: 'Poista profiili',
  
    } // return
  }, // data


  // ===========================================================================================================
  created () {

    if (this.bodyOverflowHidden) document.body.classList.add('overflow-hidden'); // For NOT to SCROLL whole page at opened POP-UP WINDOW
  
    // If "DARK" THEME
    if (this.$store.state.userTheme == 'dark') {
      this.windowClass = 'theme-dark-block-3';
    }

    // if (this.$store.state.DEV_Show) {
    //  this.time = { d: 0, h: 0, m: 0, s: 5 }; // HUOM! Keep that style
    // }

    if (this.parentType == 'sellerOffer') {
      this.info_default.titleMain = this.info_sellerOffer.titleMain;
      this.info_default.textMain  = this.info_sellerOffer.textMain;
    }

  }, // created


  // ===========================================================================================================
  beforeUnmount () {
    if (this.bodyOverflowHidden) document.body.classList.remove('overflow-hidden'); // After closing POP-UP WINDOW there is no need in that CLASS
  }, // beforeUnmount


  // ===========================================================================================================
  methods: {


    //---   ----------------------------------------------------
    // timer_over () {}


  }, // methods


}
</script>


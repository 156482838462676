
<template>
<transition name="fade" mode="out-in">

    
    <!--  ............................................................................... -->

    <div class="box-flex-backgrounded-3 p-fixed"><!-- DARKBLUE BACKGROUNDED -->
       
      <div class = "windowed-2-small window-notification-simple-basic"
          :class = "windowClass">
        
        <div>
          <h3 class="theme-light-title-1">Uusi versio on saatavilla</h3>
        </div>

        <div>Palvelusta on saatavilla uusi versio, joka päivitetään automaattisesti</div>
          
        <box-timer-countdown-basic :time       = "time"
                                   @timer-over = "reload"></box-timer-countdown-basic>

        <!-- <div>sekunnin kuluttua.</div> -->

        <div>
          <input type  = "button"
                 class = "button-3"
                :class = "buttonTheme1"
                 value = "Päivitä heti"
                @click = "reload">
        </div>


      </div>
  
  </div>


</transition>
</template>


<script> // ----------------------------------------------------------------------------



import boxTimerCountdownBasic from '@/components/boxTimerCountdownBasic'


export default {
  name:        'windowReloadNeeded',
  components: { boxTimerCountdownBasic },
  // emits:      [ 'windowReloadNeeded_open' ],
  // props:      [ 'bodyOverflowHidden' ],
  // mixins:     [ mixinTimerCountdownSignOut ],


  data () {
    return {

      buttonTheme1: 'button-theme-1',
      windowClass:  'theme-light-block-3',

      time: { d: 0, h: 0, m: 0, s: 3 } // HUOM! Keep that style
  
    } // return
  }, // data


  // ===========================================================================================================
  created () {

    if (this.bodyOverflowHidden) document.body.classList.add('overflow-hidden'); // For NOT to SCROLL whole page at opened POP-UP WINDOW
  
    // If "DARK" THEME
    if (this.$store.state.userTheme == 'dark') {
      this.windowClass = 'theme-dark-block-3';
    }


  }, // created


  // ===========================================================================================================
  beforeUnmount () {
    if (this.bodyOverflowHidden) document.body.classList.remove('overflow-hidden'); // After closing POP-UP WINDOW there is no need in that CLASS
  }, // beforeUnmount


  // ===========================================================================================================
  methods: {


    //---   ----------------------------------------------------
    reload () {

      // Admin acocunt has right to visit initial page being signed in, so F needs to redirect him manualy to his page after update...
      if (this.$store.userType == 'sa') {
        this.$router.push({ path: '/admin/sa' });
        setTimeout( () => location.reload(true), 1000 );
      }
      else { // ... other account has to be redirected to their page automatically.
        location.reload(true);
      }

      // this.$forceUpdate();
      // this.$store.commit('requestSuccessfulMainAdd', { msg: 'forceUpdate DONE', time: '' });
      // console.log('forceUpdate DONE');
      // alert('DONE');

    }


  }, // methods


}
</script>


<template>
<div>

  <div class="client-container info">

    <!-- NAVBAR .............................................................................. -->
    <item-init-nav-bar parentType="pageInfo"></item-init-nav-bar>

    <!-- TITLE ............................................................................... -->
    <div class="info-box-title-main">
      <div class="info-box-main">
        <div><h1 class="title-h1-bigger theme-light-title-1">Eväste&shy;käytännöt</h1></div>
      </div>
    </div>

    <!-- MAIN ................................................................................ -->
    <div class="info-box-main info-box-1">

      <div class="info-cookies-1">
        
        <div><h4>Tietoa evästeistä</h4></div>
        <div>
          <p>Vakuutustiedot.fi verkkosivusto käyttää evästeitä. Käyttämällä tätä sivustoa ja hyväksymällä tämän käytännön annat Insured Oy:lle suostumuksesi evästeiden käyttöön tämän käytännön ehtojen mukaisesti. Evästeet ovat tiedostoja, joita verkkopalvelimet lähettävät verkkoselaimiin ja jotka tallentuvat verkkoselaimiin. Sen jälkeen selain lähettää tiedot takaisin palvelimeen aina pyytäessään sivua palvelimelta. Näin verkkopalvelin pystyy tunnistamaan ja seuraamaan verkkoselaimia. Evästeitä on kahta päätyyppiä: istuntoevästeitä ja pysyviä evästeitä:</p>
          <ul>
            <li>Istuntoevästeet häviävät tietokoneesta heti, kun selain suljetaan.</li>
            <li>Pysyvät evästeet pysyvät tallennettuina tietokoneessa, kunnes ne erikseen poistetaan tai niiden voimassaolo päättyy.</li>
          </ul>
        </div>

        <div><h4>Vakuutustiedot.fi evästeet</h4></div>
        <div>
          <p>Insured Oy käyttää tällä sivustolla evästeitä seuraaviin tarkoituksiin:</p>
          <ul>
            <li>Tietojen keräämiseen käyttäjästä Google Analytics -työkalujen avulla.</li>
            <li>Visuaalisten käyttäytymistietojen keräämiseeen verkkosivuvierailusta Hotjar -työkalun avulla sekä näyttäkseen käyttäytymiseen perustuvia sisältöjä.</li>
            <li>Sisällön jakamisen mahdollistamiseen Facebookin avulla.</li>
            <li>Käyttäjän tunnistamiseen tämän kirjautuessa sisään; anonyymit käyttäjät eivät saa tätä evästettä.</li>
            <li>Käyttäjäkohtaisen samankaltaisten tuotteiden luettelon tallentamiseen seuraavaa istuntoa varten.</li>
          </ul>
        </div>

        <div><h4>Googlen evästeet</h4></div>
        <div>
          <p>Insured Oy käyttää Google Analytics -järjestelmää verkkosivuston käytön analysointiin. Google Analytics tuottaa tilastoja ja muita tietoja verkkosivuston käytöstä käyttäjien tietokoneisiin tallennettujen evästeiden avulla. Vakuutustiedot.fi verkkosivustosta kerättyjä tietoja käytetään verkkosivuston käyttöä koskevien raporttien laatimiseen. Tässä ovat lyhyesti Googlen evästeiden suorittamat tehtävät:</p>
          <ul>
            <li>Seurattavan verkkoalueen määrittäminen.</li>
            <li>Yksittäisten käyttäjien erottaminen.</li>
            <li>Edellisten käyntien määrän ja ajankohdan muistaminen.</li>
            <li>Liikenteen lähdetietojen muistaminen.</li>
            <li>Istunnon alun ja lopun määrittäminen.</li>
            <li>Vierailijatason mukautettujen muuttujien arvon muistaminen.</li>
          </ul>
          <p>Google tallentaa ja käyttää näitä tietoja 30 minuutista kahteen vuoteen evästeen tyypin mukaan. Googlen tietosuojakäytäntö on saatavilla osoitteessa <a href="http://www.google.com/privacypolicy.html" target="_blank">http://www.google.com/privacypolicy.html</a></p></div>

        <div><h4>Evästeiden estäminen</h4></div>
        <div>
          <p>Useimmissa selaimissa evästeet voi estää.</p>
          <ul>
            <li>Edgessä kaikki evästeet voi estää napsauttamalla “Työkalut”, “Internet-asetukset”, “Tietosuoja” ja valitsemalla “Estä kaikki evästeet” liukusäätimellä.</li>
            <li>Firefoxissa voit säätää evästeasetuksia napsauttamalla “Työkalut”, “Asetukset” ja “Tietosuoja”.</li>
            <li>Safarissa voit säätää evästeasetuksia napsauttamalla “Asetukset” ja “Yksityisyys”.</li>
            <li>Google Chromessa voit säätää evästeasetuksia napsauttamalla “Asetukset” ja “Tietosuoja”.</li>
          </ul>
          <p>Evästeiden estäminen haittaa joidenkin verkkosivustojen käytettävyyttä.</p>
        </div>

        <div><h4>Lisätietoa</h4></div>
        <div>
          <p>Jos haluat lisätietoa Insured Oy:n tietosuojakäytännöstä ja tallentamiemme tietojen tyypistä tai jos haluat meidän poistavan kaikki tiedot sinusta, ota meihin yhteyttä.</p>
        </div>

      </div>

    </div>

  </div>

  <!-- FOOTER ................................................................................ -->
  <item-footer-main></item-footer-main>


  <!-- Just add this whre you need to warch for user's ativity .................................. -->
  <!-- On this page it has to turn this "activity watvhing" on only if user was signed in -->
  <box-user-keep-active v-if="$store.state.userType!=''"></box-user-keep-active>


</div>
</template>


<script> // -------------------------------------------------------------------------------------------------------

import itemInitNavBar from '@/components/pageInit/itemInitNavBar'
import itemFooterMain from '@/components/itemFooterMain'
import boxUserKeepActive from '@/components/boxUserKeepActive'


export default {
  name:        'pageInfoCookies',
  components: {
                itemInitNavBar,
                itemFooterMain,
                boxUserKeepActive
              },

  data () {

    return {

       //signUpShow: false,

    } // return

  }, // data


  // ===========================================================================================================
  created () {
  }, // created


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>
<template>

    <!-- All msg of succesful requests goes here ............................................................................. -->

    <transition name="list">

    <div v-show = "$store.state.requestFailedMainShow"
         class  = "request-failed-main">

        <div class = "request-failed-main-msg">
          <span class="button-close-4 m_l-10" @click="$store.commit('requestFailedMainDelete')" title="Sulje ikkuna"></span>
          {{ $store.state.requestFailedMainMsg }}
        </div>

    </div>

  </transition>

</template>


<script> // -------------------------------------------------------------------------------------------------------

//import mixinXhrPostAsyncApp from '@/mixins/mixinXhrPostAsyncApp'


export default {
  name:    'boxRequestFailedMain',
  //mixins: [ mixinXhrPostAsyncApp ],
  //components: {itemSignup},

  data () {
    return {
       //userType: '', // Type of the clicked buttom's id
    } // return
  }, // data

  
  // ===========================================================================================================
  methods: {
    
    /*
    // --- --------------------------------------
    msgClose (index) {
      this.$store.commit('requestSuccessfulMainDelete', index);
    }
    */

  }, // methods

}
</script>


<template>

    <!-- This div is ONLY FOR TESTING .......................................................... -->
    <div class="DEV-box-1 right">
         <!-- v-if  = "devMenu_show"> -->

      <!-- <div class="DEV-box-1-nested-1">STORE: userType: {{ $store.state.userType }}</div> -->
      <!-- <div class="DEV-box-1-nested-1">windowPopUp_isAnyOpened: {{ $store.state.windowPopUp_isAnyOpened }}</div> -->
      <div class="DEV-box-1-nested-1">userType: {{ $store.state.userType ? $store.state.userType : 'empty' }}</div>

      <div class="DEV-box-1-nested-2">
        
        <div>C</div>
        
        <div class="DEV-box-1-window-1">
          <h5 class="m_b-10">C's tendering blocks</h5>
          <div>
            <input type  = "button"
                   class = "DEV-button-1" 
                  :class = "DEV_clientTenderingBlocked_1_ClassSet"
                   value = "incompleteProfile"
                  @click = "DEV_clientTenderingBlocked_set('incompleteProfile')">
          </div>
          <div>
            <input type  = "button"
                   class = "DEV-button-1" 
                  :class = "DEV_clientTenderingBlocked_2_ClassSet"
                   value = "ageUnderLimit"
                  @click = "DEV_clientTenderingBlocked_set('ageUnderLimit')">
          </div>
          <div>
            <input type  = "button"
                   class = "DEV-button-1" 
                  :class = "DEV_clientTenderingBlocked_3_ClassSet"
                   value = "limitOfOngoingTenderingsReached"
                  @click = "DEV_clientTenderingBlocked_set('limitOfOngoingTenderingsReached')">
          </div>
          <div>
            <input type  = "button"
                   class = "DEV-button-1" 
                  :class = "DEV_clientTenderingBlocked_4_ClassSet"
                   value = "NOT BLOCKED (DEFAULT for DEV MODE)"
                  @click = "DEV_clientTenderingBlocked_reset">
          </div>
          
        </div>
        
      </div>


      <div class="DEV-box-1-nested-2">

        <div>IR</div>
        
        <div class="DEV-box-1-window-1">
          <h5 class="m_b-10">IR's registration/activation stages</h5>
          <div>
            <input type  = "button"
                   class = "DEV-button-1" 
                  :class = "DEV_sellerBegin_1_ClassSet"
                   value = "1/3 Requred data: not filled"
                  @click = "DEV_sellerBegin_1">
          </div>
          
          <!-- ATTENTION! STRONG IDENTIFICATIONS if off for a while -->
          <!--
          <div>
            <input type  = "button"
                   class = "DEV-button-1" 
                  :class = "DEV_sellerBegin_2_ClassSet"
                   value = "2/3 Requred data: filled; strong identification: no"
                  @click = "DEV_sellerBegin_2">
          </div>
          -->
          <div>
            <input type  = "button"
                   class = "DEV-button-1" 
                  :class = "DEV_sellerBegin_2_ClassSet"
                   value = "(OFF FOR A WHILE) 2/3 Requred data: filled; strong identification: no">
          </div>

          <div>
            <input type  = "button"
                   class = "DEV-button-1" 
                  :class = "DEV_sellerBegin_3_ClassSet"
                   value = "3/3 Strong identification: done, SA activation: no"
                  @click = "DEV_sellerBegin_3">
          </div>
          <div>
            <input type  = "button"
                   class = "DEV-button-1" 
                  :class = "DEV_sellerBegin_4_ClassSet"
                   value = "DONE: Strong identification: done, SA activation: done (DEFAULT for DEV MODE)"
                  @click = "DEV_sellerBegin_4">
          </div>
        </div>

      </div>


      <div class="DEV-box-1-nested-2">

        <div>POP-UPs</div>
        
        <div class="DEV-box-1-window-1">
          <h5 class="m_b-10">Pop-up windows examples</h5>
          <div>
            <input type  = "button"
                   class = "DEV-button-1 DEV-button-style-1" 
                   value = "Pop-up window at FAILED request"
                  @click = "$store.commit('windowNotificationSimpleBasic_Set', DEV_windowNotificationSimpleBasic)">
          </div>
          <div>
            <input type  = "button"
                   class = "DEV-button-1 DEV-button-style-1" 
                   value = "Message at FAILED request"
                  @click = "$store.commit('requestFailedMainSet')">
          </div>
          <div>
            <input type  = "button"
                   class = "DEV-button-1 DEV-button-style-1" 
                   value = "Message at SUCCESSFUL request"
                  @click = "$store.commit('requestSuccessfulMainAdd', { msg: 'Jotain päivitetty onnistuneesti', time: Math.floor(Math.random() * 15) + 1})">
                  <!-- @click = "$store.commit('requestSuccessfulMainAdd', { msg: 'Jotain päivitetty onnistuneesti', time: 'infinite'})"> -->
          </div>
          <!-- <div>
            <input type  = "button"
                   class = "DEV-button-2 DEV-button-style-2"
                  :value = "$store.state.client.dataPersonal.id"
                  @click = "$store.commit('client/data_set', { name: 'id', value: '33'})">
          </div> -->
        </div>

      </div>


      <div>
        <input type  = "button"
               class = "DEV-button-1 DEV-button-style-1"
              :value = "DEV_ShowButtonValue"
              @click = "DEV_ShowSwitch">
      </div>


      <div>
        <input type  = "button"
               class = "DEV-button-2 DEV-button-style-1"
               title = "Session termination: ON/OFF"
              :value = "DEV_isSessionTerminated_buttonValue"
              @click = "DEV_isSessionTerminated_set">
      </div>


      <div>
        <router-link to="/" style="color:white;">Koti</router-link>
        <span style="color:#44485a;"> • </span>
        <router-link to="/client/pp" style="color:white;">PP</router-link>
        <!--<span style = "color:white; cursor: pointer;" @click = "testShowPageUserClientPp" title = "HENKILÖASIAKAS">PP</span>-->
        <span style="color:#44485a;"> • </span>
        <router-link to="/client/pc" style="color:white;">PC</router-link>
        <!--<span style = "color:white; cursor: pointer;" @click = "testShowPageUserClientPc" title = "YRITYSASIAKAS">PC</span>-->
        <span style="color:#44485a;"> • </span>
        <router-link to="/seller/ir" style="color:white;">IR</router-link>
        <span style="color:#44485a;"> • </span>
        <router-link to="/admin/sa" style="color:white;">SA</router-link>
      </div>


      <div class="DEV-versio-1">{{ $store.state.projectVersion }}</div>

      <!-- <xxxMock></xxxMock> -->


    </div>

    
</template>


<script> // -------------------------------------------------------------------------------------------------------

  // import xxxMock from '@/components/xxxMock'
  // import mixinOfferStatus from '@/mixins/mixinData/mixinOfferStatus'


export default {
  name:   'devMenu',
  // components: { xxxMock: () => { if (process.env.NODE_ENV == 'development') import(/*webpackChunkName:'xxxMock'*/'@/components/xxxMock'); } },
  // mixins: [ mixinOfferStatus ],


  data () {

    return {

      // devMenu_show: false,

      DEV_ShowButtonValue: '',
      DEV_isSessionTerminated_buttonValue: '',

      DEV_windowNotificationSimpleBasic: {
        titleMain: 'Testataan ikkuna',
        textMain:  'Kaikki toimii.'
      },

      DEV_incompleteProfile_windowExtra: {
        titleMain: 'Täytä pakolliset tiedot',
        textMain:  'Voit aloittaa kilpailutuksen vasta kun olet täyttänyt kaikki pakolliset tiedot profiiliisi. Täytä puuttuvat tiedot kohdassa "Omat tiedot ja asetukset".'
      },


    } // return

  }, // data
  

  // ===========================================================================================================
  created () {
    
    this.DEV_ShowButtonValueSet();
    this.DEV_isSessionTerminated_buttonValue_set();

    // For DEV as a DEFAULt we use totaly activated IR page view
    this.DEV_sellerBegin_4();

    // alert (this.mixinOfferStatus[1].value);

    // this.devMenu_show = (process.env.NODE_ENV == 'development') ? true : false;

  }, // created


  // ===========================================================================================================
  computed: {

    DEV_sellerBegin_1_ClassSet () {
      return (!this.$store.state.seller.isAllRequiredPersonalDataFilled
           && !this.$store.state.seller.isStrongIdentificationCompleted) ?
      'DEV-button-style-active-1' : 'DEV-button-style-1';
    },

    DEV_sellerBegin_2_ClassSet () {
      return (this.$store.state.seller.isAllRequiredPersonalDataFilled
          && !this.$store.state.seller.isStrongIdentificationCompleted) ?
      'DEV-button-style-active-1' : 'DEV-button-style-1';
    },

    DEV_sellerBegin_3_ClassSet () {
      return (this.$store.state.seller.isAllRequiredPersonalDataFilled
           && this.$store.state.seller.isStrongIdentificationCompleted
          && !this.$store.state.seller.isActivatedByAdmin) ?
      'DEV-button-style-active-1' : 'DEV-button-style-1';
    },

    DEV_sellerBegin_4_ClassSet () {
      return (this.$store.state.seller.isAllRequiredPersonalDataFilled
           && this.$store.state.seller.isStrongIdentificationCompleted
           && this.$store.state.seller.isActivatedByAdmin) ?
      'DEV-button-style-active-1' : 'DEV-button-style-1';
    },

    DEV_clientTenderingBlocked_1_ClassSet () {
      return (this.$store.state.client.tenderingBlocked.reason.incompleteProfile) ? 'DEV-button-style-active-1' : 'DEV-button-style-1';
    },
    DEV_clientTenderingBlocked_2_ClassSet () {
      return (this.$store.state.client.tenderingBlocked.reason.ageUnderLimit) ? 'DEV-button-style-active-1' : 'DEV-button-style-1';
    },
    DEV_clientTenderingBlocked_3_ClassSet () {
      return (this.$store.state.client.tenderingBlocked.reason.limitOfOngoingTenderingsReached) ? 'DEV-button-style-active-1' : 'DEV-button-style-1';
    },
    DEV_clientTenderingBlocked_4_ClassSet () {
      return (!this.$store.state.client.tenderingBlocked.isIt) ? 'DEV-button-style-active-1' : 'DEV-button-style-1';
    },

  }, // computed
  

  // ===========================================================================================================
  methods: {


    //--- Requred data: not filled -----------------------------------------------------------
    DEV_sellerBegin_1 () {
      this.$store.commit('seller/isAllRequiredPersonalDataFilled_set', false);
      this.$store.commit('seller/isStrongIdentificationCompleted_set', false);
    },


    //--- Requred data: filled; strong identification: no ------------------------------------
    DEV_sellerBegin_2 () {
      this.$store.commit('seller/isAllRequiredPersonalDataFilled_set', true);
      this.$store.commit('seller/isStrongIdentificationCompleted_set', false);      
    },


    //--- Strong identification: done, SA activation: no -------------------------------------
    DEV_sellerBegin_3 () {
      this.$store.commit('seller/isAllRequiredPersonalDataFilled_set', true);
      this.$store.commit('seller/isStrongIdentificationCompleted_set', true);
      this.$store.commit('seller/isActivatedByAdmin_set', false);
    },


    //--- Strong identification: done, SA activation: done -----------------------------------
    DEV_sellerBegin_4 () {
      this.$store.commit('seller/isAllRequiredPersonalDataFilled_set', true);
      this.$store.commit('seller/isStrongIdentificationCompleted_set', true);
      this.$store.commit('seller/isActivatedByAdmin_set', true);      
    },

    
    //--- ----------------------------------------
    DEV_clientTenderingBlocked_set (what) {

      this.$store.commit('client/tenderingBlockedIsIt_set', true);

      this.$store.commit('client/tenderingBlockedMsg_reset'); // (?) RESET it
      this.$store.commit('client/tenderingBlockedReason_reset'); // (?) RESET it

      let msg  = '';
      let name = '';

      switch (what) {
        case 'incompleteProfile':
          msg  = 'Voit kilpailuttaa täytettyäsi ensin pakolliset tiedot profiiliisi klikkaamalla "Omat tiedot ja asetukset".';
          name = 'incompleteProfile'; // This is F own names, but they could be the same as B has
          break;
        case 'ageUnderLimit':
          msg  = 'Alle 15-vuotiaat eivät voi kilpailuttaa vakuutuksia palvelussamme.';
          name = 'ageUnderLimit'; // This is F own names, but they could be the same as B has
          break;
        case 'limitOfOngoingTenderingsReached':
          msg  = 'Sinulla voi olla kerralla enintään kaksi kilpailutusta käynnissä.';
          name = 'limitOfOngoingTenderingsReached'; // This is F own names, but they could be the same as B has
          break;
        default:
          msg  = 'Kilpailuttaminen estetty. Ota tarvittaessa yhteyttä asiakaspalveluun.';
          name = 'other'; // When no one of the name is not matched
          break;
      }
          
      this.$store.commit('client/tenderingBlockedReason_set', name);
      this.$store.commit('client/tenderingBlockedMsg_set', msg);

      if (this.$store.state.client.tenderingBlocked.isIt&&this.$store.state.client.tenderingBlocked.reason.incompleteProfile) {
          this.$store.commit('windowNotificationSimpleBasic_Set', this.DEV_incompleteProfile_windowExtra);
      }
      // alert(what + ': ' + this.$store.state.client.tenderingBlocked.isIt + ', ' + this.$store.state.client.tenderingBlocked.reason.incompleteProfile);

    },


    //--- ----------------------------------------
    DEV_clientTenderingBlocked_reset () {
      this.$store.commit('client/tenderingBlockedIsIt_set', false); // RESET it
      this.$store.commit('client/tenderingBlockedReason_reset'); // RESET it
    },


    //--- DEV MODE -----------------------------------------------------------
    DEV_ShowSwitch () {

      this.$store.commit('DEV_ShowHde');
      this.DEV_ShowButtonValueSet();
      //this.$router.go();

    },


    //--- DEV MODE -----------------------------------------------------------
    DEV_ShowButtonValueSet () {

      this.DEV_ShowButtonValue = (this.$store.state.DEV_Show) ? 'DEV MODE' : 'REAL WORLD';

    },


    //---   ------------------------------
    DEV_isSessionTerminated_set () {
      this.$store.commit('DEV_isSessionTerminated_set');
      this.DEV_isSessionTerminated_buttonValue_set();
    },


    //---   ------------------------------
    DEV_isSessionTerminated_buttonValue_set () {
      this.DEV_isSessionTerminated_buttonValue = (this.$store.state.DEV_isSessionTerminated_on) ? 'ST:ON' : 'ST:OFF';
    }

    
  }, // methods

}

</script>

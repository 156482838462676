<template>
<transition name="fade" mode="out-in">
  <div class="box-flex-backgrounded-3 p-fixed"> <!-- DARKBLUE BACKGROUNDED -->
        
    <div class = "windowed-2-medium window-password-reset"
        :class = "windowClass"> 

  <!-- RESET PASSWORD WINDOW .......................................................................... -->
  <div><!--<div v-if="passwordResetShow">-->

    <!-- TOP ........................................................... -->
    <div class="box-2-top">
      <div><h3 class="theme-light-title-1">Vaihda salasana</h3></div>
      <span class="button-close" @click="$emit('current-box-close')" title="Sulje ikkuna"></span> 
    </div>

    <!-- MIDDLE ........................................................ -->
    <div class="box-2-middle">

    <!-- VALIDATION BOX from SERVER -->
    <box-validation-server-basic v-show            = "mixinValidationServerBasicShow"
                                :messages          = "mixinValidationServerBasicMessages"
                                @current-box-close = "mixinBoxValidationServerBasicClose"></box-validation-server-basic>

  <form class="window-password-reset-form" novalidate="true">

          <!-- PASSWORD NEW ......................................................................................... -->
          <div class="relative">

              <!-- Show/hide PASSWORD and RASSWORD REPEAT -->
              <span  :class = "mixinClassIconEye" 
                     @click = "mixinPasswordShowHide"></span>

              <input :type        = "mixinPasswordType"
                      placeholder = "Uusi salasana"
                      class       = "input-text-3"
                     :class       = "inputTextTheme1"
                      v-model     = "passwordReset.password" 
                     @focus       = "mixinTooltipsInputShowSimple('password')"
                     @focusout    = "mixinTooltipsInputHide('password')"
                     @input       = "mixinValidation_password(passwordReset.password, passwordReset.passwordRepeat)"
                     @blur        = "mixinValidation_password_Start(passwordReset.password, passwordReset.passwordRepeat)"
                     :maxlength   = "mixinValidation_password_inputMaxLength">

            <!-- TOOLTIPS MAIN -->
            <box-tooltips-input :tooltipsMainShow  = "mixinTooltipsInput.password"
                                :tooltipsMainText  = "mixinTooltipsInputText.password"
                                @current-box-close = "mixinTooltipsInputHide('password')"></box-tooltips-input>

            <!-- VALIDATION BOX -->
            <div :class="mixinClassVal.password">{{ mixinValidError.password }}</div>

          </div>

          <!-- PASSWORD REPEAT .................................................................................... -->

          <div>

            <input :type        = "mixinPasswordType"
                    placeholder = "Uusi salasana uudelleen"
                    class       = "input-text-3" 
                   :class       = "inputTextTheme1"
                    v-model     = "passwordReset.passwordRepeat" 
                   @input       = "mixinValidation_passwordRepeat(passwordReset.passwordRepeat, passwordReset.password)"
                   @blur        = "mixinValidation_passwordRepeat_Start(passwordReset.passwordRepeat, passwordReset.password)"
                   :maxlength   = "mixinValidation_passwordRepeat_inputMaxLength">

            <!-- VALIDATION BOX -->
            <div :class="mixinClassVal.passwordRepeat">{{ mixinValidError.passwordRepeat }}</div>

          </div>

          <!-- SEND BUTTON .......................................................................................... -->
          
          <!--<div><input type="button" class="button-3" :class="buttonTheme1" @click="passwordResetSubmit" value="Tallenna uusi salasana"></div>-->
          <box-button-submit-main submitButtonValue = "Tallenna uusi salasana"
                                 :submitAllow       = "submitAllow"
                                 @submit-main       = "passwordResetSubmit"></box-button-submit-main>

  </form>
  
  </div>
  </div>

  <!-- PASSWORD RESET "SUCCESSFUL" WINDOW .......................................................................... -->
  <!--<window-notification-simple-basic v-else
                                   :textMain          = "passwordResetSuccessfulTextMain"
                                   @current-box-close = "$emit('current-box-close')"></window-notification-simple-basic>-->

        </div>
      
      </div>

</transition>
</template>


<script> // ----------------------------------------------------------------------------------------------------------

import mixinValidationCommon          from '@/mixins/mixinValidation/mixinValidationCommon'
import mixinValidation_password       from '@/mixins/mixinValidation/mixinValidation_password'
import mixinValidation_passwordRepeat from '@/mixins/mixinValidation/mixinValidation_passwordRepeat'

import mixinXhrPostAsyncApp          from '@/mixins/mixinXhrPostAsyncApp'
import mixinPasswordShowHide         from '@/mixins/mixinPasswordShowHide'
import mixinBoxValidationServerBasic from '@/mixins/mixinBoxValidationServerBasic'
import mixinTooltipsInput            from '@/mixins/mixinTooltipsInput'
import mixinTrim                     from '@/mixins/mixinTrim'

import boxValidationServerBasic      from '@/components/boxValidationServerBasic'
import boxTooltipsInput              from '@/components/boxTooltipsInput'
import boxButtonSubmitMain           from '@/components/boxButtonSubmitMain'
//import windowNotificationSimpleBasic from '@/components/windowNotificationSimpleBasic'



export default {
  name:        'windowPasswordReset',
  props:      [
               'token'
              ],
  emits:      [
               'current-box-close',
               'window-succeeded'
              ],
  components: {
                boxValidationServerBasic,
                boxTooltipsInput,
                //windowNotificationSimpleBasic,
                boxButtonSubmitMain
              },
  mixins:     [ 
                mixinValidationCommon,
                mixinValidation_password,
                mixinValidation_passwordRepeat,
                mixinXhrPostAsyncApp,
                mixinPasswordShowHide,
                mixinBoxValidationServerBasic,
                mixinTooltipsInput,
                mixinTrim
              ],


  data () {
    return {

      buttonTheme1:    'button-theme-1',
      windowClass:     'theme-light-block-3',
      inputTextTheme1: 'input-text-theme-1',

      submitAllow: true, // Prevent multiclicking of Submit
      
      //passwordResetShow:                true,
      passwordResetSuccessfulTextMain: 'Salasana vaihdettu onnistuneesti',

      passwordReset: { // Values of INPUTS
        password:       '',
        passwordRepeat: ''
      }

    } // return
  }, // data

  
  // ===========================================================================================================
  created () {

    document.body.classList.add('overflow-hidden'); // For NOT to SCROLL whole page at opened POP-UP WINDOW

    // If "DARK" THEME
    if (this.$store.state.userTheme == 'dark') {
      this.windowClass = 'theme-dark-block-3';
    }

  }, // created


  // ===========================================================================================================
  beforeUnmount () {

    document.body.classList.remove('overflow-hidden'); // After closing POP-UP WINDOW there is no need in that CLASS
    
  }, // beforeUnmount


  // ===========================================================================================================
  methods: {

    //--- Create from SERVER response user friendly ERROR MSGs -------------------------------------------------
    xhrPostAsyncAppResponseMsgs (response) {

      if (response.succeeded == true) {
        this.$emit('window-succeeded', response.summaryMessage/*'Salasana vaihdettu onnistuneesti'*/);
      }
      else if (response.succeeded == false) {
        this.mixinValidationServerBasicShow = true;
        // Here we use B MSGs
        this.mixinValidationServerBasicMessages.push(response.summaryMessage/*this.$store.state.msg.responseFalse*/);
      }

      this.submitAllow = true;

    },

    //--- Submit INPUTs and sent its to the SERVER --------------------------------------------------------------
    passwordResetSubmit () {

      if (this.submitAllow) {

        this.mixinBoxValidationServerBasicClose(); // RESET ERROR msgs from SERVER
  
        var v = this.passwordReset;
        var e = this.mixinValidError;

        this.mixinTrimObject(v); // To get rid of spaces from start/end of the strings

        if (v.password
        &&  v.passwordRepeat 
        && !e.password
        && !e.passwordRepeat) {

          this.submitAllow = false;

          var url    = 'resetPassword'; // URL address for HTTPXMLrequest in mixinXhrPostAsyncApp
          var header = 'app';
          var obj    = 'resetToken='    + this.token +
                       '&newPassword1=' + encodeURIComponent(v.password) +
                       '&newPassword2=' + encodeURIComponent(v.passwordRepeat);
    
            this.mixinXhrPostAsyncApp(obj, url, header);
        }
        else { this.mixinIsEmptyBeforeSubmit(v); } // mixinValidation: Show error MSG at empty INPUTs
      
      }

    },

  }, // methods


}
</script>


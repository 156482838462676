
import mixinValidation_password_regexs from '@/mixins/mixinValidation/mixinValidation_password_regexs'

/*
ATTENTION! Do not forget to define VALIDATION rules in documentation:
https://insured.atlassian.net/wiki/spaces/VAKUUTUSTI/pages/290947073/Validation+rules+F+B
*/

export default {


  mixins: [ mixinValidation_password_regexs ],


  data () {
    return {

      mixinValidation_passwordRepeat_inputMaxLength: 30,

      mixinFlagAllowValidate:  { passwordRepeat:  false         }, /*mixinValidationAllows*/     // Stop from VALIDATION at the first INPUT editing
      mixinValidError:         { passwordRepeat: ''             }, /*mixinValidationErrorTexts*/ // ERROR MSG of VALIDATION
      mixinClassVal:           { passwordRepeat: 'display-hide' }, /*mixinValidationClasses*/    // SHOW/HIDE ERROR BOX
      mixinValidationOptional: { passwordRepeat:  false         },                               // For making INPUT as an OPTIONAL:

    } // return
  }, // data


  methods: {
		
    //---  -----------------------------------------------------------------------------------------------
    mixinValidation_passwordRepeat (inputValue, passwordValue) { 

      var inputType = 'passwordRepeat';

      // Need to allow VALIDATION after finding empty INPUTS at pushing "SUBMIT" button 
      if (this.mixinValidError[inputType]) this.mixinFlagAllowValidate[inputType] = true;

      if (this.mixinFlagAllowValidate[inputType]) {
        this.mixinValidation_passwordRepeat_Check(inputValue, passwordValue);
        this.mixinValidationErrorBoxShowHide(inputType);
      }

    },

    //--- Validation starts only after user has filles INPUT for the first time ---------------------------
    mixinValidation_passwordRepeat_Start (inputValue, passwordValue) {

      var inputType = 'passwordRepeat';

      this.mixinFlagAllowValidate[inputType] = true;
      this.mixinValidation_passwordRepeat(inputValue, passwordValue);

    },
 
    //-----------------------------------------------------------------------------------------------------
    //--- INNER WORKER ------------------------------------------------------------------------------------
    mixinValidation_passwordRepeat_Check (inputValue, passwordValue) {

      var inputType = 'passwordRepeat';
      
      /*
      var validRegex = () => {
        // Just comparing values
        if (inputValue == passwordValue) { return true; } else { return false; }
      }
      */

      if (!inputValue) { // If empty
        this.mixinValidationErrorIfEmpty(inputType);
      } 
      else {

        if (!passwordValue) {
          this.mixinValidError[inputType] = 'Täytä "Salasana" kenttä ensin';
          this.mixinValidation_passwordRepeat_ResetPasswordValidationErrors();
        }
        else {
          if (inputValue != passwordValue) {
            this.mixinValidError[inputType] = 'Salasanat eivät täsmää';
            if (this.mixinValidation_password_regexTotal.test(passwordValue)) {
              this.mixinValidation_passwordRepeat_ResetPasswordValidationErrors();
            }
          }
          else {
            // Testing with general rules regex:
            if (!this.mixinValidation_password_regexTotal.test(inputValue)) {
              this.mixinValidError[inputType] = 'Tarkista salasana';
            }
            else {
                this.mixinValidationCommon_ErrorMsgReset(inputType);
                this.mixinValidation_passwordRepeat_ResetPasswordValidationErrors();
            }
          }
        }

      }
  
    },

    //--- INNER WORKER ------------------------------------------------------------------------------------
    mixinValidation_passwordRepeat_ResetPasswordValidationErrors () {
      this.mixinValidError.password = '';
      this.mixinClassVal.password   = '';
    }    

  } // methods

}




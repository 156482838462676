<template>

  <!-- NAVBAR ............................................................................ -->
  <div class="navbar-main">
  
    <div class="init-navbar-main_inner">

      <div title = "Vakuutustiedot.fi logo"
           class = "init-navbar-logo"
          @click = "$router.push({ path: '/' })"></div> <!-- LOGO is here -->
      
      <!-- INFO PAGE SIGNED IN ... -->
      <div v-if="navBarInfoPageSignedInShow" class="init-navbar-bookmarks">
        <div class="navbar-bookmark init-navbar-bookmark-active" @click="$emit('go-to-footer')">Info ja ohjeet</div>
      </div>

      <!-- INFO PAGE NOT SIGNED IN ... -->
      <div v-else-if="navBarInfoPageNotSignedInShow" class="init-navbar-bookmarks">
        <div class="navbar-bookmark init-navbar-bookmark-active" @click="$emit('go-to-footer')">Info ja ohjeet</div>
      </div>

      <!-- INIT PAGE ... -->
      <div v-else class="init-navbar-bookmarks">
          <div class="navbar-bookmark" :class="navbarBookmarkClass.pp" @click="initTypeShowHide('pp')">Henkilöasiakkaille</div>
          <div class="navbar-bookmark" :class="navbarBookmarkClass.pc" @click="initTypeShowHide('pc')">Yritysasiakkaille</div>
          <div class="navbar-bookmark" :class="navbarBookmarkClass.ir" @click="initTypeShowHide('ir')">Vakuutusasiamiehille</div>
      </div>

      <div>

        <!-- INFO PAGE SIGNED IN ... -->
        <div v-if="navBarInfoPageSignedInShow" :class="navbarButtonsClass">
          <div class="navbar-button-go_to_profile" @click="goToProfile">Takaisin profiiliin</div>
          <item-sign-out></item-sign-out>
        </div>
        
        <!-- INFO NOT SIGNED IN / INIT PAGE ... -->
        <div v-else :class="navbarButtonsClass">
          <div class="init-sing_in-button" @click="signInShowHide">KIRJAUDU SISÄÄN</div>
          <div class="init-sing_up-button" @click="signUpShowHide">REKISTERÖIDY</div>
        </div>

        <div :class="hamburgerButtonClass" @click="hamburgerMenuShowHide"></div><!--SHOWS at SMALL DEVICES only -->

      </div>

      <!-- INFO PAGE NOT SIGNED IN ... -->
      <!--
      <div v-else-if="navBarInfoPageNotSignedInShow">
        <div class="init-sing_in-button" @click="signInShowHide">KIRJAUDU SISÄÄN</div>
        <div class="init-sing_up-button" @click="signUpShowHide">REKISTERÖIDY</div>
      </div>
      -->

      <!-- "HAMBURGER"-button is visible only for moblile devices: look for "media" in css files -->
      <!--<div class="init-hamburger-button" :id="hamburgerButtonIdClicked" @click="hamburgerMenuShowHide"></div>-->
   
    </div>

    <!-- WINDOW: RELOAD NEEDED ............................ -->
    <window-reload-needed v-if = "windowReloadNeeded_show"></window-reload-needed>

     
    <!-- SIGN IN forms ............................ -->
    <item-signin v-if                      = "signInShow"
                @current-box-close         = "signInShowHide"
                @window-reload-needed-open = "windowReloadNeeded_open"></item-signin>

    <!-- SIGN UP forms ............................ -->
    <item-sign-up-pop-upped v-if              = "signUpShow"
                           :currentPageType   = "parentType"
                           @current-box-close = "signUpShowHide"></item-sign-up-pop-upped>

  </div>

</template>


<script> // -------------------------------------------------------------------------------------------------------

import itemSignUpPopUpped from '@/components/itemSignUp/itemSignUpPopUpped'
import itemSignin         from '@/components/itemSignin'
import itemSignOut        from '@/components/itemSignOut'
import windowReloadNeeded from '@/components/windowReloadNeeded'


export default {
  name:        'itemInitNavBar',
  components: { 
                itemSignUpPopUpped,
                itemSignin,
                itemSignOut,
                windowReloadNeeded
              },
  props:      [
                'parentType', // OPTIONAL: to make some changes for different PARENT types
              ],
  emits:      [
                'go-to-footer'
              ],

  data () {

    return {

      signUpShow: false,
      signInShow: false,

      windowReloadNeeded_show: false,

      navBarInfoPageNotSignedInShow: false,
      navBarInfoPageSignedInShow: false,
      
      navbarBookmarkClass: {
        pp: '',
        pc: '',
        ir: '',
        //ic: ''
      },

      hamburgerButtonClass: 'init-hamburger-button',
      navbarButtonsClass:   'init-navbar-buttons',
      //hamburgerMenuShow:     false,
      //initNavbarBookmarksClicked: '',

    } // return

  }, // data


  // ===========================================================================================================
  created () {

    this.bookmarkClassSet();

    // At "pageInfo" PARENT: RESET bookmarks' class
    //if (this.parentType == 'pageInfo') this.bookmarkClassReset();

    if (this.parentType == 'pageInfo') {
      if (this.$store.state.userType) {
        this.navBarInfoPageSignedInShow = true;
      }
      else {
        this.navBarInfoPageNotSignedInShow = true;
      }
    }

  }, // created


  // ===========================================================================================================
  mounted () {
  }, // mounted


  // ===========================================================================================================
  methods: {


    //--- Switching between NAVBAR's links ---------------------------------------------------------------------
    initTypeShowHide (type) {

      //this.bookmarkClassReset();
      this.$store.commit('userTypeInitSet', type); // In Store: SET type of Init User Type: in use in several places
      //this.bookmarkClassSet();
      
      if      ( type == 'pp' )  { this.$router.push({ path: '/' }); }
      else if ( type == 'pc' )  { this.$router.push({ path: '/yritysasiakkaille' }); }
      else if ( type == 'ir' )  { this.$router.push({ path: '/vakuutusasiamiehille' }); }      
      else { alert('Virhellinen store.state.userTypeInit: ' + this.$store.state.userTypeInit ); }
      //if (this.parentType == 'pageInfo') this.$router.push({ path: '/' }); // GO HOME (need for footer's pages)

    },


    //--- CLOSE SIGN UP (CHILD uses)-----------------------------------------------------------------------
    signUpShowHide () {
      this.signUpShow = !this.signUpShow;
    },


    //--- CLOSE SIGN IN MENU (CHILD uses) -----------------------------------------------------------------------
    signInShowHide () {
      this.signInShow = !this.signInShow;
    },


    //---  -----------------------------------------------------------------------------------
    windowReloadNeeded_open () {

      this.signInShow = false;
      this.windowReloadNeeded_show = true;

    },


    //--- RESET bookmarks' class -----------------------------------------------------------------------------------
    bookmarkClassReset () {
      for (const typeClass in this.navbarBookmarkClass) this.navbarBookmarkClass[typeClass] = ''; // RESET firstly
    },


    //--- SET bookmarks' class -----------------------------------------------------------------------------------
    bookmarkClassSet () {
      this.navbarBookmarkClass[this.$store.state.userTypeInit] = 'init-navbar-bookmark-active';
    },


    //---  -----------------------------------------------------------------------------------
    goToProfile () {

      if      ( this.$store.state.userType == 'pp' )  { this.$router.push({ path: '/client/pp' }); }
      else if ( this.$store.state.userType == 'pc' )  { this.$router.push({ path: '/client/pc' }); }
      else if ( this.$store.state.userType == 'ir' )  { this.$router.push({ path: '/seller/ir' }); }
      else if ( this.$store.state.userType == 'ic' )  { this.$router.push({ path: '/seller/ic' }); }
      else if ( this.$store.state.userType == 'sa' )  { this.$router.push({ path: '/admin/sa'  }); }
      else if ( this.$store.state.userType == 'sra' ) { this.$router.push({ path: '/admin/sra' }); }
      else { alert('Virhellinen store.state.userType: ' + this.$store.state.userType ); }

    },


    //--- Show/hide HAMBURGER MENU -----------------------------------------------------------------------------
    hamburgerMenuShowHide () { 

      if (this.hamburgerButtonClass == 'init-hamburger-button') {
        this.hamburgerButtonClass = 'init-hamburger-button-clicked';
        this.navbarButtonsClass   = 'init-navbar-buttons-clicked';
      }
      else {
        this.hamburgerButtonClass = 'init-hamburger-button';
        this.navbarButtonsClass   = 'init-navbar-buttons';
      }
      
    },


  }, // methods

}
</script>
<template>
<transition name="fade" mode="out-in">

      <!-- WINDOW: ................................................................. -->
      <div class="box-flex-backgrounded-3 z-maxTop p-fixed"> <!-- DARKBLUE BACKGROUNDED -->
        
        <div class = "windowed-2-small window-notification-simple-basic"
            :class = "windowClass">

          <div v-if="titleMain">
            <h3 class="theme-light-title-1">{{ titleMain }}</h3>
          </div>
          
          <div>{{ textMain }}</div>
      
          <div>
            <input type  = "button"
                   class = "button-3"
                  :class = "buttonTheme1"
                   value = "Sulje ikkuna"
                  @click = "$emit('current-box-close')">
          </div>

        </div>
      
      </div>

</transition>
</template>


<script> // ----------------------------------------------------------------------------------------------------------

//import mixinXhrPostAsyncApp from '@/mixins/mixinXhrPostAsyncApp'


export default {
  name:    'windowNotificationSimpleBasic',
  props: [
           'textMain',
           'titleMain' // OPTIONAL
         ],
emits:      [
             'current-box-close'
            ],

  //components: { boxValidationServerBasic },
  //mixins:     [mixinXhrPostAsyncApp ],

  data () {
    return {

      buttonTheme1: 'button-theme-1',
      windowClass:  'theme-light-block-3',

      //textMain: 'Jotain meni pieleen.',

    } // return
  }, // data


  // ===========================================================================================================
  created () {

    document.body.classList.add('overflow-hidden'); // For NOT to SCROLL whole page at opened POP-UP WINDOW

    // If "DARK" THEME
    if (this.$store.state.userTheme == 'dark') {
      this.windowClass = 'theme-dark-block-3';
    }

  }, // created


  // ===========================================================================================================
  beforeUnmount () {

    document.body.classList.remove('overflow-hidden'); // After closing POP-UP WINDOW there is no need in that CLASS
  
  }, // beforeUnmount


  // ===========================================================================================================
  methods: {

    //---  -------------------------------------------------------------
    //insuranceExistingDeleteSubmit () {}

  }, // methods


}
</script>


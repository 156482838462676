
export default {

  data () {
    return {

      mixinData_rulesGeneral_ir: [
        'Vakuutusasiamiehellä (näissä ehdoissa "vakuutusasiamies" tai "asiamies") täytyy olla voimassa oleva asiamiessopimus Suomessa toimivan vakuutusyhtiön kanssa.',
        'Asiamies voi tarjota asiakkaille ainoastaan edustamiensa yhtiöiden vakuutustuotteita.',
        'Vakuutustiedot.fi palvelulla on oikeus olla aktivoimatta asiamiehen profiilia.',
        'Vakuutustiedot.fi palvelun käyttö aktivoidulla profiililla on vakuutusasiamiehille maksullista. Asiamies hyväksyy kulloinkin voimassa olevan hinnaston sekä maksuehdot. Hinnaston ja maksuehtojen muutoksista ilmoitetaan asiamiehelle vähintään 30 vuorokautta ennen niiden voimaantuloa.',
        'Asiamies on vastuussa profiilinsa tietojen paikkansapitävyydestä ja ajantasaisuudesta.',
        'Asiamiehen on oltava yhteydessä tarjouspyynnön lähettäneeseen asiakkaaseen kohtuullisessa ajassa.',
        'Vakuutustiedot.fi palvelu ei ota kantaa pyydettyihin eikä tehtyihin tarjouksiin. Vakuutustiedot.fi ei ole vastuussa asiakkaiden huolimattomuudesta, pyydettyjen tarjousten luonteesta tai asiakkaiden tahallisista väärinkäytöksistä.',
        'Asiamies on itse vastuussa edustamansa yhtiön vakuutusten esittelystä ja tarjoamisesta asiakkaalle.',
        'Asiamies on itse velvollinen seuraamaan kilpailutusten määräaikoja sekä antamaan tai muokkaamaan tarjoustaan ajoissa.',
        'Asiamies on itse velvollinen asettamaan ja tarvittaessa muuttamaan omaa kuukausittaista asiakkaiden enimmäismäärää.',
        'Asiamies ei saa antaa toisten henkilöiden tehdä tarjouksia vastaanottamiinsa tarjouspyyntöihin tai käyttää omaa profiiliaan.',
        'Asiamies on velvollinen huolehtimaan, että hänen profiilinsa salasana ei joudu ulkopuolisille. Asiamiehen huolimattomasta tai tahallisesta toiminnasta aiheutuvat tietojen päätymiset ulkopuolisille tahoille ovat hänen omalla vastuullaan.',
        'Asiamiehen profiili voidaan jäädyttää jos maksamattomia laskuja kertyy vähintään kahden kuukauden ajalta. Profiili aktivoidaan taas kun maksamattomat laskut on hoidettu.',
        'Asiamies on velvollinen käsittelemään palvelussa näkemiään asiakkaiden henkilö- ja muita profiilitietoja asianmukaisella huolellisuudella.',
        'Asiamiehen tulee keskustella asiakkaiden kanssa ammattimaisesti ja asiallisesti sekä edustaa yhtiötään sopimuksensa mukaisesti.',
        'Asiamies ei saa välittää tai myydä Vakuutustiedot.fi palvelun kautta saamiaan tarjouspyyntöjä eteenpäin.',
        'Asiamies voi poistaa profiilinsa oman profiilinsa kautta itsenäisesti eikä poistettuja tietoja voida enää palauttaa. Poistetun profiilin tietoja voidaan säilyttää Vakuutustiedot.fi palvelun tietokannassa vielä 12 kuukauden ajan profiilin poistamisen jälkeen, jotta mahdolliset laskutusepäselvyydet tai ilmi tulleet väärinkäytökset saadaan selvitettyä.',
        'Vakuutustiedot.fi palvelulla on oikeus poistaa asiamiehen profiili välittömästi, jos käyttöehtojen vastaista toimintaa ilmenee. Tällaisessa tilanteessa Vakuutustiedot.fi ei ole asiamiehelle korvausvelvollinen mahdollisten haittojen tai kulujen syntymisestä.',
        'Vakuutustiedot.fi palvelulla on oikeus olla hyväksymättä ehtojen vastaisesta toiminnasta poistetun asiamiehen lisäämistä uudelleen palveluun.',
        'Vakuutustiedot.fi palvelu voi muuttaa sivuston rakennetta, ulkoasua ja toiminnallisuuksia sekä hyödyntää vakuutusasiamiehen palvelussa olevia tietoja palvelun kehittämiseksi ja parantamiseksi.',
        'Vakuutustiedot.fi palvelu ei ole vastuussa eikä korvausvelvollinen teknisistä ongelmista johtuvista asiamiehelle syntyvistä haitoista tai kuluista. Tällainen tekninen ongelma voi syntyä esimerkiksi käyttämämme palvelimen kaatumisesta tai palvelunestohyökkäyksen kohteeksi joutumisesta.',
        'Vakuutustiedot.fi palvelu pidättää itsellään oikeuden käyttöehtojen muutoksiin.',
        'Näihin käyttöehtoihin sovelletaan Suomen lakia.',
        'Mahdolliset riita-asiat pyritään ensisijaisesti selvittämään ja sopimaan vakuutusasiamiehen ja Insured Oy:n välisin neuvotteluin. Jos osapuolet eivät saavuta ratkaisua neuvottelemalla, riita-asiat ratkaistaan Helsingin käräjäoikeudessa.'
      ]

    } // return
  }, // data


  methods: {
  
    //--- --------------------
    // xxx () { return x; },

  } // methods
}

<template>

    <!-- SIGN OUT BUTTON ...................................................................................-->

    <!-- For ADMIN -->
    <div v-if  = "parentType=='admin'"
         class = "sign_out">
      <input type  = "button"
            @click = "mixinSignOut()"
             class = "admin-button-1 admin-button-logout-blue"
             title = "Katso profiilin tietoja">
    </div>

    <!-- For PP/PC/IR -->
    <div v-else
        @click = "mixinSignOut()"
         class = "sign_out sign_out-main">
      <div title="Riippulukko"></div> <!-- ICON here -->
      <div>Kirjaudu ulos</div>
    </div>

</template>


<script> // ---------------------------------------------------------------------------------------------------

import mixinSignOut from '@/mixins/mixinSignOut'


export default {
  name:    'itemSignOut',
  mixins: [ mixinSignOut ],
  props:  [ 
           'parentType' // OPTIONAL
           // 'classButton' // OPTIONAL: when there is need to change button's style
          ], 
  //components: {itemSignup},

  data () {
    return {
      
      // buttonTheme2: 'admin-button-theme-2',

      // buttonThemeDisabled1: 'admin-button-theme-disabled-1',
      // signOutIconClass: 'sign_out-main',
    
    } // return
  }, // data


  // ===========================================================================================================
  created () {

    // if (this.classButton == 'admin') { this.signOutIconClass = 'sign_out-main'; } // Old version is "admin-sign_out-main"
    
  },


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>


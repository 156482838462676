<template>
<div class="w-100">

  <div class="client-container init">

    <!-- NAVBAR .............................................................................. -->
    <item-init-nav-bar></item-init-nav-bar>

<div>

  <!-- BOX-1 ............................................................................. -->
    <section class="init-box-1">
      <div><h1 class="title-h1-bigger theme-light-title-1">Kilpailuta vakuutuksesi ja säästä rahaa</h1></div>
      <div><p>Kilpailuttamalla vakuutuksesi säästät rahaa ja saat parempaa vakuutusturvaa. Pyydä tarjous valitsemistasi vakuutusyhtiöistä</p></div>
      <div class="init-box-1-1">
        <div><img src="@/assets/img/ill-01.jpg" alt="Onnellinen nuori perhe kaupungissa"></div>
        <!-- SIGN UP: NESTED ... -->
        <item-sign-up-nested></item-sign-up-nested>
      </div>
    </section>

  <!-- MAIN ............................................................................. -->
  <div class="init-box-main">

    <!-- BOX-2 ............................................................................. -->
    <section class="init-box-2">
      <div class="init-box-2-1"> 
        <div><h4 class="theme-light-title-2">Kilpailuta vakuutuksesi</h4></div>
        <div><h1 class="theme-light-title-1 m_b-0">Kilpailuta vaikka kaikki vakuutuksesi kerralla</h1></div>
        <div><p>Palvelussa voit helposti kilpailuttaa yksittäisen tai vaikka kaikki vakuutuksesi yhdellä kertaa kotisohvalta käsin. Helppous korostuu etenkin säännöllisessä kilpailutuksessa</p></div>
      </div>
      <div><img src="@/assets/img/ill-02.jpg" alt="Iloinen mies työskentelemässä kannettavallaan"></div>
    </section>


    <!-- BOX: HOW IT WORKS ................................................................. -->
    <box-init-box-how-it-works></box-init-box-how-it-works>

    
    <!-- BOX-3 ............................................................................. -->
    <section class="init-box-3">
      <div><img src="@/assets/img/ill-03.jpg" alt="Iloinen pariskunta matkustamassa ympäri maailmaa"></div>
      <div class="init-box-3-1">
        <div><h4 class="theme-light-title-2">Hanki uusia vakuutuksia</h4></div>
        <div><h1 class="theme-light-title-1 m_b-0">Uusi vakuutus kilpailutettuna ja nopeasti</h1></div>
        <div><p>Pyydä tarjous yhdestä tai useammasta uudesta vakuutuksesta haluamistasi vakuutusyhtiöistä. Kilpailuta uusi vakuutus jo ennen sen hankintaa, niin saat jo alusta saakka itsellesi sopivimman vaihtoehdon</p></div>
      </div>
    </section>


    <!-- BOX: WHY .......................................................................... -->
    <box-init-box-why></box-init-box-why>


    <!-- PP-6 ............................................................................. -->
    <!--
    <section class="init-pp-6">

      <div><h4 class="theme-light-title-2">Vakuutustiedot.fi on riippumaton kaupankäyntialusta</h4></div>
      <div><h1 class="theme-light-title-1">Vertaa kaikkia vakuutusyhtiöitä</h1></div>
      
      <div class="init-pp-6-1">
        <div><img src="@/assets/img/logo-IC-If.svg"></div>
        <div><img src="@/assets/img/logo-IC-Lahitapiola.png"></div>
        <div><img src="@/assets/img/icon-insurance.svg"></div>
        <div><img src="@/assets/img/logo-IC-If.svg"></div>
        <div><img src="@/assets/img/logo-IC-OP.jpg"></div>
        <div><img src="@/assets/img/logo-IC-If.svg"></div>
        <div><img src="@/assets/img/logo-IC-Lahitapiola.png"></div>
        <div><img src="@/assets/img/logo-IC-OP.jpg"></div>
        <div><img src="@/assets/img/logo-IC-Lahitapiola.png"></div>
        <div><img src="@/assets/img/logo-IC-OP.jpg"></div>
        <div><img src="@/assets/img/icon-insurance.svg"></div>
      </div>

    </section>
    -->


    <!-- BOX: FAQ .......................................................................... -->
    <box-init-box-faq></box-init-box-faq>


  </div>

</div>
  </div>

  <!-- FOOTER ................................................................................ -->
  <item-footer-main></item-footer-main>

  <!-- .......................................................................... -->
  <window-notification-simple-basic v-if              = "windowNotificationSimpleBasic.show"
                                   :titleMain         = "windowNotificationSimpleBasic.titleMain"
                                   :textMain          = "windowNotificationSimpleBasic.textMain"
                                   @current-box-close = "windowNotificationSimpleBasicClose"></window-notification-simple-basic>

  <!-- ............................................................................... -->
  <window-password-reset v-if              = "passwordReset.show"
                        :token             = "passwordReset.token"
                        @window-succeeded  = "passwordResetSucceededShow" 
                        @current-box-close = "passwordResetClose"></window-password-reset>

  <!-- .......................................................................... -->
  <!--<window-account-activation v-if              = "accountActivationShow"
                            @current-box-close = "accountActivationClose"
                            :textMain          = "accountActivationTextMain"></window-account-activation> -->

  <!-- PASSWORD RESET "TOKEN ERROR" WINDOW .................................................. -->
  <!--<window-password-reset-error v-if               = "passwordResetErrorShow"
                              :tokenFalseTextMain = "passwordResetTextMain"
                              @current-box-close  = "passwordResetErrorShow=false"></window-password-reset-error>-->

</div>
</template>


<script> // -------------------------------------------------------------------------------------------------------

import itemFooterMain                from '@/components/itemFooterMain'
import itemInitNavBar                from '@/components/pageInit/itemInitNavBar'
import itemSignUpNested              from '@/components/itemSignUp/itemSignUpNested'
import boxInitBoxHowItWorks          from '@/components/pageInit/boxInitBoxHowItWorks'
import boxInitBoxWhy                 from '@/components/pageInit/boxInitBoxWhy'
import boxInitBoxFaq                 from '@/components/pageInit/boxInitBoxFaq'
import windowPasswordReset           from '@/components/pageInit/windowPasswordReset'
import windowNotificationSimpleBasic from '@/components/windowNotificationSimpleBasic'
//import windowAccountActivation  from '@/components/pageInit/windowAccountActivation'
//import windowPasswordResetError from '@/components/pageInit/windowPasswordResetError'

import mixinXhrPostAsyncApp from '@/mixins/mixinXhrPostAsyncApp'
import mixinUserDefine      from '@/mixins/mixinUserDefine'


export default {
  name:        'pageInitPp',
  components: {
                itemFooterMain,
                itemInitNavBar,
                itemSignUpNested,
                boxInitBoxHowItWorks,
                boxInitBoxWhy,
                boxInitBoxFaq,
                windowPasswordReset,
                windowNotificationSimpleBasic
                //windowAccountActivation,
                //windowPasswordResetError,
              },
  mixins:     [
                mixinXhrPostAsyncApp,
                mixinUserDefine
              ],


  data () {

    return {

      signUpShow: false,

      accountActivation: {
        name:        'aktivointiTunnus',
        url:         'activateAccount',
        textMain_ir: 'Profiilisi on nyt luotu. Kirjaudu palveluun ja täytä pakolliset tiedot. Tämän jälkeen profiilisi tarkastetaan ennen aktivointia.',
        titleMain:   'Profiilin aktivointi'
      },

      emailEdit: {
        name:              'vaihdaTunnus',
        url:               'changeEmail',
        titleMain:         'Sähköpostiosoitteen vaihtaminen',
        textMainSucceeded: 'Sähköpostiosoite on vaihdettu onnistuneesti.'
      },

      // "Vaihda salasana"
      passwordEdit: { /* ATTENTION! Do not mess it with "passwordReset" */
        name:      'vaihdaSalasana',
        url:       'changePassword',
        titleMain: 'Salasanan vaihtaminen'
      },
      
      // "Unohditko salasanasi?"
      passwordReset: { /* ATTENTION! Do not mess it with "passwordEdit" */
        show:      false,
        name:      'resetointiTunnus',
        token:     '',
        url:       'checkPasswordResetToken',
        titleMain: 'Salasanan nollaus'
      },

      // "Adtraction"
      adtraction: {
        name:                  'at_gd',
        url:                   'setAdtractionId',
        textMainFailed:        'Adtraction ID on tyhjä tai väärässä muodossa',
        textMainRequestFailed: 'Adtractionin tunnistautuminen epäonnistui. Päivitä sivu ja kokeile uudelleen'
        // token:     '',
        // titleMain: 'Salasanan vaihtaminen'
      },

      // "Suosittele palvelua ja tienaa rahaa"
      recommendAndEarn: {
        name:                  'r_code',
        url:                   'setRecommendationCode',
        textMainFailed:        '"Suosittele palvelua ja tienaa rahaa" linkki on tyhjä tai väärässä muodossa',
        textMainRequestFailed: '"Suosittele palvelua ja tienaa rahaa" tunnistautuminen epäonnistui. Päivitä sivu'
        // token:     '',
        // titleMain: 'Salasanan vaihtaminen'
      },

      
      windowNotificationSimpleBasic: {
        show:            false,
        textMain:        '',
        textMainDefault: 'Jotain meni pieleen.',
        titleMain:       '',
      },

      irIdentificationStrong_url: 'insuranceRepresentativeStrongIdentification',

      identificationStrong_failed: {
        titleMain: 'Tunnistautuminen epäonnistui',
        textMain:  'Kirjaudu sisään ja yritä uudelleen'
      },

      identificationStrong_succeeded: {
        titleMain: 'Tunnistautuminen onnistui',
        textMain:  'Saat ilmoituksen sähköpostiisi kun profiilisi on käyttövalmis'
      },
      
      location: '', // Current URL

      /*
      // Turned off as STRONG IDENTIFICAION for PP/PC in off
      clientIdentification_noSession: {
        titleMain: 'Tarjouksen hyväksyminen keskeytyi',
        textMain:  'Pääset jatkamaan hyväksymistä kirjautumalla palveluun uudelleen. Älä päivitä sivua.'
      },
      */
      
      /*
      identificationStrongResponce: {
        show:      false,
        name:      'resetointiTunnus',
        token:     '',
        url:       'checkPasswordResetToken',
        titleMain: 'Salasanan nollaus'
      },*/

      //passwordResetTextMain:  '',
      //passwordResetTokenIsValid:        false,
      //passwordResetUrl: 'resetPassword',
      //accountActivationShow:      false,
      //accountActivationTextMain: '',
      //passwordResetErrorShow: false,

    } // return

  }, // data


  // ===========================================================================================================
  beforeMount() {}, // beforeMount


  // ===========================================================================================================
  created () {


    this.location = window.location.href; // Get current URL, like "www.vakuutustiedot.fi?aktivointiTunnus=xyz"
    

    // (WAS) with STRONG IDENTIFICATION... 
    /*
    // ATTENTION (!) this code has to be before "mixinUserDefine_RedirectFromSharedPages"
    // CATCH Client's Strong Identification Responce from TELIA
    if (this.location.indexOf(this.$store.state.identificationStrong.clientIdentification) > -1) {
      
      this.$store.commit('identificationStrong/location_set', this.location);
      
      // It has to be here, before "nextTick"
      this.mixinUserDefine_RedirectFromSharedPages(); // ONLY for SIGNED IN USER: redirect user from here to his profile page
      
      this.$nextTick( () => {
        if (this.$store.state.userType == '') this.$store.commit('windowNotificationSimpleBasic_Set', this.clientIdentification_noSession);
      }); 

    }
    else {
      this.mixinUserDefine_RedirectFromSharedPages(); // ONLY for SIGNED IN USER: redirect user from here to his profile page
    }
    */

    // (NOW) ... as STRONG IDENTIFICATION is off for PP/PC only
    this.mixinUserDefine_RedirectFromSharedPages(); // ONLY for SIGNED IN USER: redirect user from here to his profile page


    this.$store.commit('userTypeInitSet', 'pp'); // In Store: SET type of Init User Type: in use in several places

    
    // Get current URL
    // this.location = window.location.href; // Esim: "www.vakuutustiedot.fi?aktivointiTunnus=xyz"
    
    if      (this.location.indexOf(this.accountActivation.name) > -1) { this.accountActivationSubmit(); } // ACCOUNT ACTIVATION action
    else if (this.location.indexOf(this.passwordReset.name) > -1)     { this.passwordResetTokenSubmit(); } // PASSWORD RESET action
    else if (this.location.indexOf(this.emailEdit.name) > -1)         { this.emailEditSubmit(); } // EMAIL EDIT action
    else if (this.location.indexOf(this.passwordEdit.name) > -1)      { this.passwordEdit_submit(); } // PASSWORD EDIT action
    else if (this.location.indexOf(this.$store.state.identificationStrong.irRegistration) > -1) { this.irRegistrationSubmit(); } // IR's Strong Identification Responce
    else if (this.location.indexOf(this.adtraction.name) > -1)        { this.adtraction_submit(); } // "Adtraction"
    else if (this.location.indexOf(this.recommendAndEarn.name) > -1)  { this.recommendAndEarn_submit(); } // "Suosittele palvelua ja tienaa rahaa"

  
  }, // created


  // ===========================================================================================================
  methods: {


    //--- Create from SERVER response user friendly ERROR MSGs -------------------------------------------------
    xhrPostAsyncAppResponseMsgs (response, url) {

      if (url == this.accountActivation.url) {
        let textMain = (response.userType == 'ir') ? this.accountActivation.textMain_ir : response.summaryMessage;
        this.windowNotificationSimpleBasicShow(textMain, this.accountActivation.titleMain);
      }

      else if (url == this.passwordReset.url) {
        if (response.resetTokenValid == true) {
          this.passwordReset.show = true;
        }
        else if (response.resetTokenValid == false) {
          this.windowNotificationSimpleBasicShow(response.summaryMessage, this.passwordReset.titleMain);
        }
      }

      else if (url == this.emailEdit.url) {
        if (response.succeeded == true)  {
          this.windowNotificationSimpleBasicShow(this.emailEdit.textMainSucceeded/*response.summaryMessage*/, this.emailEdit.titleMain);
        }
        else if (response.succeeded == false) {
          this.windowNotificationSimpleBasicShow(response.summaryMessage, this.emailEdit.titleMain);
        }
      }

      else if (url == this.passwordEdit.url) {
        this.windowNotificationSimpleBasicShow(response.summaryMessage, this.passwordEdit.titleMain);
      }

      else if (url == this.irIdentificationStrong_url) {
        if (response.succeeded == true) {
          this.$store.commit('windowNotificationSimpleBasic_Set', this.identificationStrong_succeeded);
        }
        else if (response.succeeded == false) {
          this.$store.commit('windowNotificationSimpleBasic_Set', this.identificationStrong_failed);
        }
      }

      else if (url == this.adtraction.url) {
        if (response.succeeded == false) { // ATTENTION (!) Here need to be detected FALSE state only
          this.$store.commit('requestFailedMainSet', this.adtraction.textMainRequestFailed);
        }
      }

      else if (url == this.recommendAndEarn.url) {
        if (response.succeeded == false) { // ATTENTION (!) Here need to be detected FALSE state only
          this.$store.commit('requestFailedMainSet', this.recommendAndEarn.textMainRequestFailed);
        }
      }

    },


    //--- Request for ACCOUNT ACTIVATION -----------------------------------------------------------------------
    accountActivationSubmit () {

      var token = this.location.split(this.accountActivation.name + '=')[1];

      if (token) {
        var url    = this.accountActivation.url; // URL address for HTTPXMLrequest in mixinXhrPostAsyncApp
        var header = 'app';
        var obj    = 'activationToken=' + token;
        //alert('token here: ' + token);
  
        this.mixinXhrPostAsyncApp(obj, url, header);
      }
      else {
        this.windowNotificationSimpleBasicShow(this.windowNotificationSimpleBasic.textMainDefault, this.accountActivation.titleMain);
      }

    },


    //--- Request for checking PASSWORD RESET TOKEN -------------------------------------------------------------
    passwordResetTokenSubmit () {

      this.passwordReset.token = this.location.split(this.passwordReset.name + '=')[1];
      // console.log('--- TOKEN: ' + this.passwordReset.token);
     
      //if (this.passwordResetToken) this.passwordResetShow = true;
      if (this.passwordReset.token) {
        var url    = this.passwordReset.url; // URL address for HTTPXMLrequest in mixinXhrPostAsyncApp
        var header = 'app';
        var obj    = 'resetToken=' + this.passwordReset.token;

        this.mixinXhrPostAsyncApp(obj, url, header);
      }
      else {
        this.windowNotificationSimpleBasicShow(this.windowNotificationSimpleBasic.textMainDefault, this.passwordReset.titleMain);
      }

    },


    //--- Request for checking EMAIL EDIT TOKEN -------------------------------------------------------------
    emailEditSubmit () {

      var token = this.location.split(this.emailEdit.name + '=')[1];

      if (token) {
        var url    = this.emailEdit.url; // URL address for HTTPXMLrequest in mixinXhrPostAsyncApp
        var header = 'app';
        var obj    = 'emailResetToken=' + token;
        //alert('token here: ' + token);
  
        this.mixinXhrPostAsyncApp(obj, url, header);
      }
      else {
        this.windowNotificationSimpleBasicShow(this.windowNotificationSimpleBasic.textMainDefault, this.emailEdit.titleMain);
      }
    },


    //--- Request for checking PASSWORD EDIT TOKEN -------------------------------------------------------------
    passwordEdit_submit () {

      var token = this.location.split(this.passwordEdit.name + '=')[1];

      if (token) {      
        var url    = this.passwordEdit.url; // URL address for HTTPXMLrequest in mixinXhrPostAsyncApp
        var header = 'app';
        var obj    = 'token=' + token;
        this.mixinXhrPostAsyncApp(obj, url, header);
      }
      else {
        this.windowNotificationSimpleBasicShow(this.windowNotificationSimpleBasic.textMainDefault, this.passwordEdit.titleMain);
      }

    },


    //--- "Adtraction" -----------------------------------------------------------------------------------------------
    adtraction_submit () {

      var token = this.location.split(this.adtraction.name + '=')[1];

      if (token) {

        // Send TOKEN to Server
        var url    = this.adtraction.url; // URL address for HTTPXMLrequest in mixinXhrPostAsyncApp
        var header = 'app';
        var obj    = 'id=' + token;
        this.mixinXhrPostAsyncApp(obj, url, header);

        // Save it in the STORE for using with Registration
        // this.$store.commit('adtractionToken_set', token);

      }
      else {
        this.$store.commit('requestFailedMainSet', this.adtraction.textMainFailed);
      }

    },

    //--- "Suosittele palvelua ja tienaa rahaa" ------------------------------------------------------------------
    recommendAndEarn_submit () {

      var token = this.location.split(this.recommendAndEarn.name + '=')[1];

      if (token) {

        // Send TOKEN to Server
        var url    = this.recommendAndEarn.url; // URL address for HTTPXMLrequest in mixinXhrPostAsyncApp
        var header = 'app';
        var obj    = 'code=' + token;
        this.mixinXhrPostAsyncApp(obj, url, header);

      }
      else {
        this.$store.commit('requestFailedMainSet', this.recommendAndEarn.textMainFailed);
      }

    },


    //---  -----------------------------------------------------------------------------------------------------
    irRegistrationSubmit () {
      
      // Get "code" value
      if (this.location.indexOf('code=') > -1) {
        
        // Get "code" value
        var code = this.location.split('code=')[1];
        code = code.split('&')[0]; // If there is any other parameters after "code"
        // console.log(code);

        if (code.length) {
          // Get "userId" value
          var userId = this.location.split(this.$store.state.identificationStrong.userIdParameter)[1];
          userId = userId.split('&')[0]; // If there is any other parameters after "code"
          // console.log(userId);
          this.irIdentificationStrong_submit(code, userId);
        }
        else {
          this.$store.commit('windowNotificationSimpleBasic_Set', this.identificationStrong_failed);
        }
      }
      else {
        this.$store.commit('windowNotificationSimpleBasic_Set', this.identificationStrong_failed);
      }

    },


    //---  -------------------------------------------------------------
    irIdentificationStrong_submit (code, userId) {

      var url    = this.irIdentificationStrong_url; // URL address for HTTPXMLrequest in mixinXhrPostAsyncApp
      var header = 'app';
      var obj    = 'code='    + code +
                   '&userId=' + userId;
  
      this.mixinXhrPostAsyncApp(obj, url, header);

    },


    //---  -----------------------------------------------------------------------------------------------------
    windowNotificationSimpleBasicClose () {

      this.windowNotificationSimpleBasic.show      = false; // RESET
      this.windowNotificationSimpleBasic.textMain  = ''; // RESET
      this.windowNotificationSimpleBasic.titleMain = ''; // RESET

      this.$router.push({ path: '/' }); // GO HOME (to get rid of URL postfix)

    },


    //---  -----------------------------------------------------------------------------------------------------
    windowNotificationSimpleBasicShow (textMain, titleMain) {

      this.windowNotificationSimpleBasic.show     = true;
      this.windowNotificationSimpleBasic.textMain = textMain;
      if (titleMain) this.windowNotificationSimpleBasic.titleMain = titleMain;

    },


    //---  -----------------------------------------------------------------------------------------------------
    passwordResetClose () {

      this.passwordReset.show = false;
      this.$router.push({ path: '/' }); // GO HOME (to get rid of URL postfix)

    },


    //---  -----------------------------------------------------------------------------------------------------
    passwordResetSucceededShow (textMain) {

      this.passwordReset.show = false;
      this.windowNotificationSimpleBasicShow(textMain);

    }


    //---  -----------------------------------------------------------------------------------------------------
    /*
    accountActivationClose () {

      this.accountActivationShow = false;
      this.$router.push({ path: '/' }); // GO HOME (to get rid of URL postfix)

    },*/


  }, // methods

}

</script>
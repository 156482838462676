
export default {

  data () {
    return {

      mixinData_rulesGeneral_pc: [
        'Yritysprofiili on tarkoitettu kaikille yrityksille ja yhdistyksille joilla on suomalainen y-tunnus (näissä ehdoissa "yritysasiakas"). Profiilia voi käyttää ainoastaan yritysasiakkaan edustusoikeuden omaava henkilö.',
        'Yritysprofiilin käyttäjällä tulee olla oikeus käsitellä palveluun lisäämänsä yritysasiakkaan vakuutustietoja. Toisten yritysten tai yhdistysten nimissä luodut profiilit sekä luvaton tarjousten pyytäminen niiden nimissä johtaa profiilin poistamiseen sekä asian ilmoittamiseen viranomaisille.',
        'Yritysprofiilin käyttäjän täytyy olla vähintään 18 vuotta täyttänyt käyttääkseen palvelua.',
        'Yritysasiakkaalla saa olla vain yksi profiili kerrallaan. Ylimääräiset profiilit voidaan poistaa ilman erillistä ilmoitusta.',
        'Kilpailutuksessa saa käyttää vain omia tietojaan tai tietoja, joiden käyttöön on saanut valtuutuksen.',
        'Valeprofiilien luominen sekä tarjouspyyntöjen lähettäminen ilman tosiasiallista kilpailutustarkoitusta on kiellettyä. Kilpailutuksen käynnistäminen ei velvoita hyväksymään yhtäkään saatua tarjousta.',
        'Vakuutustiedot.fi ei ole vakuutusten ostopalvelu, vaan vakuutustarjousten välityspalvelu. Palvelumme kautta ei ole mahdollista ostaa vakuutusta, vaan ainoastaan sopia esitetyn vakuutustarjouksen ottamisesta sitä tarjonneen vakuutusyhtiön rekisteröidyn vakuutusasiamiehen kanssa.',        
        'Yritysasiakas ei tee vakuutussopimusta Vakuutustiedot.fi palvelun kanssa tai sen kautta, vaan sopimus tehdään suoraan vakuutusyhtiön rekisteröidyn vakuutusasiamiehen kanssa. Vakuutustiedot.fi palvelu toimii vakuutustarpeiden käsittelyyn räätälöitynä tiedonvälityskanavana yritysasiakkaan ja vakuutusasiamiehen välillä.',
        'Yritysasiakas antaa täyden suostumuksensa kaikkien Vakuutustiedot.fi palveluun lisäämiensä tietojen ja vakuutustietojensa käyttöön palvelussamme mukana olevien vakuutusasiamiesten tarpeisiin yritysasiakkaille suunnattujen vakuutustarjousten laskemisen mahdollistamiseksi. Myös Vakuutustiedot.fi ylläpitohenkilöstöllä on oikeus tarvittaessa käsitellä kaikkia yritysasiakkaan profiiliin sisältyviä tietoja. Kaikkia yritysasiakkaan profiilissa olevia tietoja säilytetään ja käsitellään tietoturvallisesti eikä niitä luovuteta tai siirretä palvelumme ulkopuolelle ilman yritysasiakkaalta siihen saatua lupaa.',
        'Yritysasiakas on vastuussa profiilinsa tietojen ja sisällön ajantasaisuudesta ja paikkansapitävyydestä. Erityistä huomiota tähän tulee kiinnittää vakuutustarjousta pyydettäessä. Väärien tai vanhentuneiden tietojen ilmoittamisesta aiheutuneet mahdolliset epäselvyydet, haitat tai kulut ovat yritysasiakkaan omalla vastuulla.',
        'Yritysasiakas on vastuussa siitä, että hän antaa vakuutusasiamiehelle kaiken vakuutustarjouksen laskemiseen tarvittavan tiedon oikein, riittävällä laajuudella ja totuudenmukaisesti.',
        // 'Vakuutustiedot.fi palvelun käyttö edellyttää vahvaa tunnistautumista vakuutustarjousta hyväksyttäessä. Vahvalla tunnistautumisella ei allekirjoiteta vakuutussopimusta, vaan varmistetaan, että tarjouksen hyväksymisestä viestinyt henkilö on sama kuin sitä pyytänyt. Vahvaa tunnistautumista voidaan vaatia myös profiilin luomisen yhteydessä, tai milloin tahansa palvelun käytön aikana, jos järjestelmä tulkitsee yritysasiakkaan toiminnan palvelussa epäilyttäväksi tai käyttöehtojen vastaiseksi.',
        // 'Palvelumme kautta esitettyjen vakuutustarjousten hyväksyntä täytyy viestiä vakuutusasiamiehelle palvelumme välityksellä. Palvelumme kautta saadun tarjouksen sopiminen täysin palvelumme ulkopuolella johtaa ilmi käydessään sekä yritysasiakkaan että tarjouksen tehneen vakuutusasiamiehen poistamiseen palvelustamme.',
        'Yritysasiakas on itse velvollinen huolehtimaan että hän reagoi ajoissa haluamaansa vakuutustarjoukseen, ja että hän tarjoaa vakuutusasiamiehelle ajoissa kaikki tarvittavat ja oikeat tiedot kelvollisen tarjouksen laskemiseksi. Vakuutustiedot.fi ei ole vastuussa, jos yritysasiakas ei ehdi hyväksyä toivomaansa vakuutustarjousta annetussa määräajassa.',
        'Vakuutustiedot.fi palvelu ei ota minkäänlaista kantaa yritysasiakkaan saamiin tarjouksiin eikä tarjoa neuvoja vakuutustarjouksen valitsemiseen tai miten saatuihin tarjouksiin tulisi reagoida. Yritysasiakas on itse vastuussa itselleen sopivimman tarjouksen valitsemisesta. Vakuutustiedot.fi palvelulla ei ole velvollisuutta mahdollistaa yritysasiakkaan vakuutussopimusten paranemista millään mittarilla mitattuna.',
        'Vakuutustiedot.fi ei ole vastuussa yritysasiakkaalle aiheutuneesta vaivasta tai kuluista, jos vakuutusasiamies on tehnyt yritysasiakkaalle virheellisen tarjouksen jonka yritysasiakas on hyväksynyt.',
        'Vakuutustiedot.fi ei ole vastuussa jos palvelussamme vakuutusasiamiehen kanssa sovittua vakuutustarjousta ei myönnetä yritysasiakkaalle. Vakuutustiedot.fi ei myöskään ole vastuussa jos vakuutusasiamies jättää esitetyn tarjouksen hyväksynnän jälkeen vakuutussopimuksen tekemättä tai jos hän tekee virheen sopimuksen sisällön, laajuuden tai muun tarjoukseen liittyneen oleellisen seikan osalta.',
        'Yritysasiakas on itse velvollinen huolehtimaan, että hänelle ei tule vakuutustensa osalta päällekkäisyyksiä. Vakuutustiedot.fi palvelu ei irtisano yritysasiakkaan voimassa olevia vakuutussopimuksia. Yritysasiakas on itse vastuussa vanhojen vakuutussopimustensa irtisanomisesta ja uusien sopimusten voimaantulon tarkistamisesta.',
        // 'Yritysasiakas antaa vakuutustarjouksen hyväksymällä tarjouksen tehneelle vakuutusasiamiehelle suostumuksensa toimittaa uuteen vakuutussopimukseen liittyvät asiakirjat Vakuutustiedot.fi palveluun yritysasiakkaan profiilitietoihin.',
        // 'Vakuutustiedot.fi palvelun "Yrityksen nykyiset vakuutukset" osio ei edusta automaattisesti ajantasaista tietoa yritysasiakkaan vakuutustiedoista, vaan sen sisältö koostuu yritysasiakkaan itse lisäämistä ja hallinnoimista vakuutustiedoista tai palvelumme kautta saatujen ja hyväksyttyjen tarjousten tiedoista. "Yrityksen nykyiset vakuutukset" listalla voi siten olla näkyvillä tietoja vakuutuksista joista ei ole vielä tehtynä vakuutussopimusta tai joista ei voidakaan tehdä vakuutussopimusta yritysasiakkaalle. Vakuutustiedot.fi palvelu ei ole vastuussa yritysasiakkaan "Yrityksen nykyiset vakuutukset" osion tai muiden hänen profiilin tietojen ajantasaisena tai paikkansapitävänä pitämisestä.',
        'Yritysasiakas on velvollinen huolehtimaan, että hänen profiilinsa salasana ei joudu ulkopuolisille. Yritysasiakkaan huolimattomasta tai tahallisesta toiminnasta aiheutuvat tietojen päätymiset ulkopuolisille tahoille ovat yritysasiakkaan omalla vastuulla.',
        "Yritysasiakkaan tulee käsitellä omia luottamuksellisia tietojaan huolellisesti. Vakuutustiedot.fi ei ole vastuussa yritysasiakkaan itse lisäämiensä arkaluontoisten tietojen käyttöehtojen mukaisesta näkymisestä palvelussamme.",
        'Vakuutustiedot.fi palveluun ei saa lisätä mitään lainvastaista tai asiatonta sisältöä. Vakuutustiedot.fi palvelulla on oikeus poistaa havaitsemansa lainvastainen tai asiaton sisältö palvelusta välittömästi ilman yritysasiakkaan suostumusta, eikä Vakuutustiedot.fi palvelu ole velvollinen ilmoittamaan poistamisesta yritysasiakkaalle. Asiattomaksi sisällöksi luokitellaan esimerkiksi aikuisviihde, väkivaltasisältö tai muu palvelun luonteeseen ja tarkoitukseen sopimaton sisältö.',
        'Yritysasiakas voi poistaa profiilinsa oman profiilinsa kautta itsenäisesti eikä poistettuja tietoja voida enää palauttaa. Poistetun profiilin tietoja voidaan säilyttää Vakuutustiedot.fi palvelun tietokannassa vielä 12 kuukauden ajan profiilin poistamisen jälkeen, jotta mahdolliset palvelun käytössä ilmenneet epäselvyydet voidaan vielä jälkikäteen selvittää.',
        'Vakuutustiedot.fi palvelulla on oikeus poistaa yritysasiakkaan profiili välittömästi ilman ennakkoilmoitusta, jos käyttöehtojen vastaista toimintaa ilmenee. Tällaisessa tilanteessa Vakuutustiedot.fi ei ole yritysasiakkaalle korvausvelvollinen mahdollisten haittojen tai kulujen syntymisestä.',
        'Vakuutustiedot.fi palvelulla on oikeus olla hyväksymättä ehtojen vastaisesta toiminnasta poistetun yritysasiakkaan lisäämistä uudelleen palveluun.',
        'Vakuutustiedot.fi palvelu voi muuttaa sivuston rakennetta, ulkoasua ja toiminnallisuuksia sekä hyödyntää yritysasiakkaan palvelussa olevia tietoja palvelun kehittämiseksi ja parantamiseksi.',
        'Vakuutustiedot.fi palvelu ei ole vastuussa eikä korvausvelvollinen teknisistä ongelmista johtuvista yritysasiakkaalle syntyvistä haitoista tai kuluista. Tällainen tekninen ongelma voi syntyä esimerkiksi käyttämämme palvelimen kaatumisesta tai palvelunestohyökkäyksen kohteeksi joutumisesta.',
        'Vakuutustiedot.fi palvelu pidättää itsellään oikeuden käyttöehtojen muutoksiin.',
        'Näihin käyttöehtoihin sovelletaan Suomen lakia.',
        'Mahdolliset riita-asiat pyritään ensisijaisesti selvittämään ja sopimaan yritysasiakkaan ja Insured Oy:n välisin neuvotteluin. Jos osapuolet eivät saavuta ratkaisua neuvottelemalla, riita-asiat ratkaistaan Helsingin käräjäoikeudessa.',
      ]

    } // return
  }, // data


  methods: {
  
    //--- --------------------
    // xxx () { return x; },

  } // methods
}

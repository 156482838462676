
import { createApp/*, nextTick*/ } from 'vue'
import App                     from '@/App.vue'
import                              '@/registerServiceWorker'
import router                  from '@/router/index'
import store                   from '@/store/index'

// Plugins:
import { VueReCaptcha } from 'vue-recaptcha-v3'

// GLOBAL components
import boxOptionalMain from '@/components/boxOptionalMain'


const app = createApp(App)

app.use(store)
app.use(router)

app.use(VueReCaptcha, { siteKey: '6LeQcP8UAAAAAD77IPQZ31IUGmMM0iy7crL35svZ' })

app.component('box-optional-main', boxOptionalMain)

app.mount('#app')

// createApp(App).use(store).use(router).mount('#app')

export default {

  /*
  ATTENTION! Do not forget to define VALIDATION rules in documentation:
  https://insured.atlassian.net/wiki/spaces/VAKUUTUSTI/pages/290947073/Validation+rules+F+B
  */

  data () {
    return {

      mixinValidation_emailAddress_inputMaxLength: 100,

      mixinFlagAllowValidate:  { emailAddress:  false         }, /*mixinValidationAllows*/     // Stop from VALIDATION at the first INPUT editing
      mixinValidError:         { emailAddress: ''             }, /*mixinValidationErrorTexts*/ // ERROR MSG of VALIDATION
      mixinClassVal:           { emailAddress: 'display-hide' }, /*mixinValidationClasses*/    // SHOW/HIDE ERROR BOX
      mixinValidationOptional: { emailAddress:  false         },                               // For making INPUT as an OPTIONAL:

    } // return
  }, // data


  methods: {
		
    //---  -----------------------------------------------------------------------------------------------
    mixinValidation_emailAddress (inputValue) { 

      var inputType = 'emailAddress';

      // Need to allow VALIDATION after finding empty INPUTS at pushing "SUBMIT" button 
      if (this.mixinValidError[inputType]) this.mixinFlagAllowValidate[inputType] = true;

      if (this.mixinFlagAllowValidate[inputType]) {
        this.mixinValidation_emailAddress_Check(inputValue);
        this.mixinValidationErrorBoxShowHide(inputType);
      }

    },

    //--- Validation starts only after user has filles INPUT for the first time ---------------------------
    mixinValidation_emailAddress_Start (inputValue) {

      var inputType = 'emailAddress';

      this.mixinFlagAllowValidate[inputType] = true;
      this.mixinValidation_emailAddress(inputValue);

    },
 
    //-----------------------------------------------------------------------------------------------------
    //--- INNER WORKER ------------------------------------------------------------------------------------
    mixinValidation_emailAddress_Check (inputValue) {

      var inputType = 'emailAddress';

      var validRegex = (value) => { 
        // "Allow spaces at the start/end" is added
        // at least 1 number, char. or ".%+-", then "@", then at least 1 number, char. or ".-", then ".", then at least 2 char.
        var re = /^\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/;
        //var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value);
      }

      if (!inputValue) { // If empty
        this.mixinValidationErrorIfEmpty(inputType);
      } 
      else {
        if (!validRegex(inputValue)) {
          this.mixinValidError[inputType] = 'Tarkista sähköpostiosoite';
        }
        else {
          this.mixinValidationCommon_ErrorMsgReset(inputType);
        }
      }
  
    },

  } // methods

}




<template>
<div>

  <div class="client-container info">

    <!-- NAVBAR .............................................................................. -->
    <item-init-nav-bar parentType="pageInfo"></item-init-nav-bar>

    <!-- TITLE ............................................................................... -->
    <div class="info-box-title-main">
      <div class="info-box-main">
        <div><h1 class="title-h1-bigger theme-light-title-1">Yritys&shy;vakuutukset</h1></div>
      </div>
    </div>

    <!-- MAIN ................................................................................ -->
    <div class="info-box-main info-box-1">

      <div class="info-box-1_1">

        <p>Yritysvakuutuksia ovat kaikki yrityksille ja yhdistyksille tarjottavat vakuutukset.</p>

        <p>Yleisimpiä yritysvakuutuksia ovat erilaiset lakisääteiset eläkevakuutukset, joiden välillä ei ole eroa eri yhtiöiden kesken. Yritykset ja yhdistykset ottavat kuitenkin usein paljon vapaaehtoisia vakuutuksia erilaisia riskejä vähentääkseen, ja niiden vertailuun Vakuutustiedot.fi palvelu soveltuu erinomaisesti.</p>

        <p>Palvelumme sopii parhaiten alle 30 yritysvakuutuksen samanaikaiseen kilpailuttamiseen.</p>

      </div>

    </div>

  </div>

  <!-- FOOTER ................................................................................ -->
  <item-footer-main></item-footer-main>


  <!-- Just add this whre you need to warch for user's ativity .................................. -->
  <!-- On this page it has to turn this "activity watvhing" on only if user was signed in -->
  <box-user-keep-active v-if="$store.state.userType!=''"></box-user-keep-active>


</div>
</template>


<script> // -------------------------------------------------------------------------------------------------------

import itemInitNavBar from '@/components/pageInit/itemInitNavBar'
import itemFooterMain from '@/components/itemFooterMain'
import boxUserKeepActive from '@/components/boxUserKeepActive'


export default {
  name:        'pageInfoCompanyInsurances',
  components: {
                itemInitNavBar,
                itemFooterMain,
                boxUserKeepActive
              },

  data () {

    return {

       //signUpShow: false,

    } // return

  }, // data


  // ===========================================================================================================
  created () {
  }, // created


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>
<template>
<transition name="fade" mode="out-in">
  <div :class="backgroundMainClass">

    <!-- SIGN UP: "REGISTRATION SUCCESSFUL"................................................................ -->
    <div class="windowed-2-small theme-light-block-3 init-sign_up-successful">

      <div><h2 class="theme-light-title-1">Profiilisi on lähes valmis</h2></div>

      <div class="box-plain_text-1 w-100">Saat hetken kuluttua sähköpostiisi linkin, jota klikkaamalla aktivoit profiilisi ja pääset käyttämään palvelua.<br/><br/>Linkin saapuminen sähköpostiisi voi viedä muutaman minuutin. Tarvittaessa tarkista sähköpostisi roskapostikansio.</div>
  
      <input type  = "button" 
             class = "button-3"
            :class = "buttonTheme1" 
             value = "Sulje"
            @click = "$emit('current-box-close')">

    </div>

  </div>
</transition>
</template>


<script> // ----------------------------------------------------------------------------------------------------------

//import boxValidationServerBasic from '@/components/boxValidationServerBasic'


export default {
  name:        'itemSignUpSuccessful',
  //mixins:      [mixinXhrPostAsyncApp ],
  components: { 
                //boxValidationServerBasic
              },
  props:      [
               'parentType' // OPTIONAL: to make some changes according to parent type
              ],
  emits:      [
               'current-box-close'
              ],


  data () {

    return {

      buttonTheme1:    'button-theme-1',
      //buttonTheme2:    'button-theme-2',
      //inputTextTheme1: 'input-text-theme-1',

      backgroundMainClass: 'box-flex-backgrounded-3 p-fixed',

    }

  }, // data


  // ===========================================================================================================
  created () {

    document.body.classList.add('overflow-hidden'); // For NOT to SCROLL whole page at opened POP-UP WINDOW
    
    if (this.parentType == 'pop-upped') this.backgroundMainClass = ''; // No need at POP-UPPED window

  },


  // ===========================================================================================================
  beforeUnmount () {
    document.body.classList.remove('overflow-hidden'); // After closing POP-UP WINDOW there is no need in that CLASS
  }, // beforeUnmount


  // ===========================================================================================================
  methods: {
    
    //--- Reset reCaptcha VALIDATION ---------------------------------------------
    //recaptchaValidationReset () {},

  }, // methods


} // default

</script>
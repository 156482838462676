<template>
<div>

  <div class="client-container info">

    <!-- NAVBAR .............................................................................. -->
    <item-init-nav-bar parentType="pageInfo"></item-init-nav-bar>

    <!-- TITLE ............................................................................... -->
    <div class="info-box-title-main">
      <div class="info-box-main">
        <div><h1 class="title-h1-bigger theme-light-title-1">Usein kysyttyä</h1></div>
      </div>
    </div>

    <!-- MAIN ................................................................................ -->
    <div class="info-box-main info-box-1">

      <div class="info-faq-1">

        <div><h4>Maksaako palvelun käyttö jotakin?</h4></div>
        <div><p>Vakuutustiedot.fi palvelu on täysin maksuton henkilö-, yritys- ja yhdistysasiakkaille. Asiamiehille palvelun käyttö on maksullista.</p></div>

        <div><h4>Kauanko kilpailutus kestää?</h4></div>
        <div><p>Kilpailutuksen kesto riippuu pitkälti siitä kuinka nopeasti saatuihin tarjouksiin reagoidaan sekä asiakkaan että vakuutusasiamiehen puolelta. Tarjouspyynnöt välitetään vakuutusedustajille, jotka laskevat tarjouksia manuaalisesti, joten esim. viikonloppuna tai pyhäpäivänä aloitettuun tarjouspyyntöön saattaa tulla ensimmäiset tarjoukset vasta seuraavana työpäivänä. Helpoin tapa nopeuttaa prosessia on olla yhteydessä tarjouspyyntöä käsitteleviin edustajiin.</p></div>

        <div><h4>Onko minun pakko valita jokin saamani vakuutustarjous?</h4></div>
        <div><p>Ei ole. Voit halutessasi jättää valitsematta yhtäkään tarjousta.</p></div>

        <div><h4>Irtisanotteko vanhat vakuutukset puolestani?</h4></div>
        <div><p>Vanhojen vakuutussopimusten irtisanominen on asiakkaan omalla vastuulla. Osa vakuutusasiamiehistä voi sovittaessa irtisanoa vanhat vakuutuksesi puolestasi.</p></div>
      
      </div>

    </div>

  </div>

  <!-- FOOTER ................................................................................ -->
  <item-footer-main></item-footer-main>


  <!-- Just add this whre you need to warch for user's ativity .................................. -->
  <!-- On this page it has to turn this "activity watvhing" on only if user was signed in -->
  <box-user-keep-active v-if="$store.state.userType!=''"></box-user-keep-active>


</div>
</template>


<script> // -------------------------------------------------------------------------------------------------------

import itemInitNavBar from '@/components/pageInit/itemInitNavBar'
import itemFooterMain from '@/components/itemFooterMain'
import boxUserKeepActive from '@/components/boxUserKeepActive'


export default {
  name:        'pageInfoFaq',
  components: {
                itemInitNavBar,
                itemFooterMain,
                boxUserKeepActive
              },

  data () {

    return {

       //signUpShow: false,

    } // return

  }, // data


  // ===========================================================================================================
  created () {
  }, // created


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>
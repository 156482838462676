<template>
<div>

  <div class="client-container info">

    <!-- NAVBAR .............................................................................. -->
    <item-init-nav-bar parentType="pageInfo"></item-init-nav-bar>

    <!-- TITLE ............................................................................... -->
    <div class="info-box-title-main">
      <div class="info-box-main">
        <div><h1 class="title-h1-bigger theme-light-title-1">Yleiset säännöt</h1></div>
      </div>
    </div>

    <!-- MAIN ................................................................................ -->
    <div class="info-box-main info-box-1 info-general_rules-1">

      <div><p>Käyttäjätyyppikohtaisten käyttöehtojen lisäksi sovellamme sivustollamme yleisiä hyvän käytöstavan mukaisia sääntöjä, jotka koskevat myös käyttäjiä jotka eivät ole rekisteröityneet palveluumme.</p></div>
      
      <ol>
        <li>Kaikenlainen sivustollamme olevan materiaalin kopiointi on kiellettyä ilman erillistä Insured Oy:ltä saatua kirjallista lupaa siihen.</li>
        <li>Valeprofiilien luominen tai profiilien luonti toisen henkilön, yrityksen tai yhdistyksen nimissä on kiellettyä, ja voi johtaa oikeustoimiin tällaisen profiilin luojaa kohtaan.</li>
        <li>Toisten käyttäjien epäilyttävästä sääntöjen tai käyttöehtojen vastaisesta toiminnasta on ilmoitettava välittömästi asiakaspalveluumme.</li>
        <li>Saatuja tai annettuja tarjouksia ei saa esitellä julkisesti muille. Esimerkiksi kuvakaappausten ja videoiden tekeminen omasta tarjouspyynnöstä tai saadusta tarjouksesta sekä kyseisen aineiston jakaminen muille ilman erillistä kirjallista lupaa Insured Oy:ltä on kiellettyä.</li>
        <li>Kaikenlainen lainvastainen toiminta palvelussamme tuodaan välittömästi viranomaisten tietoon.</li>
      </ol>
    
    </div>

  </div>

  <!-- FOOTER ................................................................................ -->
  <item-footer-main></item-footer-main>


  <!-- Just add this whre you need to warch for user's ativity .................................. -->
  <!-- On this page it has to turn this "activity watvhing" on only if user was signed in -->
  <box-user-keep-active v-if="$store.state.userType!=''"></box-user-keep-active>


</div>
</template>


<script> // -------------------------------------------------------------------------------------------------------

import itemInitNavBar from '@/components/pageInit/itemInitNavBar'
import itemFooterMain from '@/components/itemFooterMain'
import boxUserKeepActive from '@/components/boxUserKeepActive'


export default {
  name:        'pageInfoGeneralRules',
  components: {
                itemInitNavBar,
                itemFooterMain,
                boxUserKeepActive
              },

  data () {

    return {

       //signUpShow: false,

    } // return

  }, // data


  // ===========================================================================================================
  created () {
  }, // created


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>
<template>
<div>

  <div class="client-container info">

    <!-- NAVBAR .............................................................................. -->
    <item-init-nav-bar parentType="pageInfo"></item-init-nav-bar> 

    <!-- TITLE ............................................................................... -->
    <div class="info-box-title-main">
      <div class="info-box-main">
        <div><h1 class="title-h1-bigger theme-light-title-1">Tietoa meistä</h1></div>
      </div>
    </div>

    <!-- MAIN ................................................................................ -->
    <div class="info-box-main info-box-1 info-about_us-1">

      <div><h4>Mikä Vakuutustiedot.fi palvelu on</h4></div>
      <div><p>Vakuutustiedot.fi on vakuutustarjousten välityspalvelu. Palvelumme kautta ei ole mahdollista ostaa vakuutuksia, vaan ainoastaan sopia esitetyn vakuutustarjouksen ottamisesta sitä tarjonneen vakuutusyhtiön rekisteröidyn edustajan kanssa. Vakuutustiedot.fi on Insured Oy:n kehittämä ja ylläpitämä palvelu. Vakuutustiedot.fi alusta välittää vakuutustarjouksia vain vakuutusyhtiöiden virallisille edustaille, joten saat samantasoista konsultaatiota vakuutusasioissa kuin mitä saisit mennessäsi vakuutuskonttorille.</p></div>

      <div><h4>Visiomme</h4></div>
      <div><p>Haluamme, että vakuutustarjousten saaminen ja tarjouksesta keskusteleminen asiantuntijan kanssa olisi helppoa. Kun vakuutustietosi ovat kootusti yhdessä paikassa, näet kokonaiskuvan yhdellä vilkaisulla, ja kun elämässäsi tapahtuu muutoksia, voit päivittää vakuutustarpeitasi nopeasti ja vaivattomasti. Haluamme myös että vakuutusasiamiehet voisivat keskittyä asiakashankinnan sijasta asiakkaiden palvelemiseen, ja käyttää vapautuneen ajan vakuutusten esittelemiseen. Kun asiakas tietää paremmin mitä kaikkea vakuutukset korvaavat ja millaisia korvaussummia he voivat saada, on vertailu muutakin kuin pelkän hinnan vertailua. Tavoitteenamme ovat paremmin vakuutetut henkilö- ja yritysasiakkaat.</p></div>
    
    </div>

  </div>

  <!-- FOOTER ................................................................................ -->
  <item-footer-main></item-footer-main>


  <!-- Just add this whre you need to warch for user's ativity .................................. -->
  <!-- On this page it has to turn this "activity watvhing" on only if user was signed in -->
  <box-user-keep-active v-if="$store.state.userType!=''"></box-user-keep-active>


</div>
</template>


<script> // -------------------------------------------------------------------------------------------------------

import itemInitNavBar from '@/components/pageInit/itemInitNavBar'
import itemFooterMain from '@/components/itemFooterMain'
import boxUserKeepActive from '@/components/boxUserKeepActive'


export default {
  name:        'pageInfoAboutUs',
  components: {
                itemInitNavBar,
                itemFooterMain,
                boxUserKeepActive
              },

  data () {

    return {

       //signUpShow: false,

    } // return

  }, // data


  // ===========================================================================================================
  created () {
  }, // created


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>
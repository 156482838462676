<template>
  <!-- NOTHING HERE -->
  <span hidden></span>
</template>


<script> // -------------------------------------------------------------------------------------------------------

import mixinXhrPostAsyncApp from '@/mixins/mixinXhrPostAsyncApp'


export default {
  name:    'boxIcNames',
  mixins: [ mixinXhrPostAsyncApp ],
  props:  [
           'pp', // true | false | "whatever"
           'pc', // true | false | "whatever"
           'active' // true | false | "whatever"
          ],
  emits:      [
               'ic-names-get',
              ],

  data () {
    return {

      // active: true,
      // pp: false,
      // pc: 'whatever',

      icNames: [],

    } // return
  }, // data


  // ===========================================================================================================
  mounted () {
    
    //--- "MOCK" ("DEV_") mode ---
    if (this.$store.state.DEV_Show) {
      if (process.env.NODE_ENV == 'development') {
        this.DEV_icNames_set();
      }
    }
    else {
      // Make request according to PARENT needs (from PROPs):
      this.submitMain();
    }

  }, // mounted

  
  // ===========================================================================================================
  methods: {


    //---   -----------------------------------
    submitMain () {

      /*
      Attention!
      Any of parameters ("active", "pp", "pc" can be omitted: that means that it is not taken into account at all, like "whatever")
      */

      const url    = 'listInsuranceCompanies'; // URL address for HTTPXMLrequest in mixinXhrPostAsyncApp
      const header = 'app';
      var   obj    = '';

      const helper = { active: this.active, pp: this.pp, pc: this.pc };

      for (const prop in helper) {     
        if (helper[prop] == true || helper[prop] == false) {
          let a = (obj == '') ? '' : '&'; // obj += (prop == 'active') ? '' : '&'; 
          obj += a;
          let b = prop + '=' + helper[prop];
          obj += b;
        }
        else if (helper[prop] == 'whatever') {
          // nothin'
        }
        else {
          alert('Error! boxIcNames component: "' + prop + '" is incorrect: "' + helper[prop] + '". It can be true, false or "whatever" only.');
        }
      }

      this.mixinXhrPostAsyncApp(obj, url, header);

    },

    
    //--- Create from SERVER response user friendly ERROR MSGs -------------------------------------------------------------
    xhrPostAsyncAppResponseMsgs (response) {

      if (response.succeeded == true)  {

        // Could be like:
        // this.icNames = response.insuranceCompanies;
        
        this.icNames = []; // RESET it

        response.insuranceCompanies.forEach(
          value => {
            this.icNames.push({
              id:   value.id,
              name: value.name
            });
          }
        );

        this.$emit('ic-names-get', this.icNames);

      }
      else if (response.succeeded == false) {
        this.$store.commit('requestFailedMainSet', 'Vakuutusyhtiöiden listan haku ei onnistunut. Päivitä sivu ja yritä uudelleen.');
      }

      this.submitAllow = true;

    },

    
    //---   --------------------------------------
    DEV_icNames_set () {

      if (process.env.NODE_ENV == 'development') {

        // console.log('DEV_icNames_set starts');

        let DEV_icNamesAll = [ // ALL TYPES of INSURANCE COMPANIES
          { id: '1',  name: 'A-Vakuutus'    },
          { id: '2',  name: 'Agria'         },
          { id: '3',  name: 'Aktia'         },
          { id: '4',  name: 'Alandia'       },
          { id: '5',  name: 'Elo'           },
          { id: '7',  name: 'Fennia'        },
          { id: '8',  name: 'If'            },
          { id: '9',  name: 'Ilmarinen'     },
          { id: '11', name: 'Lähitapiola'   },
          { id: '12', name: 'Mandatum Life' },
          { id: '13', name: 'Pohjantähti'   },
          { id: '14', name: 'Pohjola Vakuutus' },
          { id: '15', name: 'POP-Vakuutus'  },
          { id: '16', name: 'Turva'         },
          { id: '17', name: 'Varma'         },
          { id: '18', name: 'Veritas'       },
        ];

        let DEV_icNamesPp = [ // INSURANCE COMPANIES' names for PP ("PRIVATE_CLIENT")
          { id: '2',  name: 'Agria'         },
          { id: '3',  name: 'Aktia'         },
          { id: '4',  name: 'Alandia'       },
          { id: '5',  name: 'Elo'           },
          { id: '7',  name: 'Fennia'        },
          { id: '8',  name: 'If'            },
          { id: '9',  name: 'Ilmarinen'     },
          { id: '11', name: 'Lähitapiola'   },
          { id: '12', name: 'Mandatum Life' },
          { id: '13', name: 'Pohjantähti'   },
          { id: '14', name: 'Pohjola Vakuutus' },
          { id: '15', name: 'POP-Vakuutus'  },
          { id: '16', name: 'Turva'         },
          { id: '17', name: 'Varma'         },
          { id: '18', name: 'Veritas'       },
        ];

        let DEV_icNamesPc = [ // INSURANCE COMPANIES' names for PC ("PRIVATE_COMPANY")
          { id: '1',  name: 'A-Vakuutus'    },
          { id: '3',  name: 'Aktia'         },
          { id: '4',  name: 'Alandia'       },
          { id: '5',  name: 'Elo'           },
          { id: '7',  name: 'Fennia'        },
          { id: '8',  name: 'If'            },
          { id: '9',  name: 'Ilmarinen'     },
          { id: '11', name: 'Lähitapiola'   },
          { id: '12', name: 'Mandatum Life' },
          { id: '13', name: 'Pohjantähti'   },
          { id: '14', name: 'Pohjola Vakuutus' },
          { id: '16', name: 'Turva'         },
          { id: '17', name: 'Varma'         },
          { id: '18', name: 'Veritas'       },
        ];

        if      (this.userType == 'pp') { this.$emit('ic-names-get', DEV_icNamesPp); }
        else if (this.userType == 'pc') { this.$emit('ic-names-get', DEV_icNamesPc); }
        else                            { this.$emit('ic-names-get', DEV_icNamesAll); }

      }

    }


  }, // methods

}
</script>


<template>

  <!-- LOADING WINDOW: MAIN ............................................................................. -->
  <transition name="fade">
  <div class="box-loading-cover">­­­­­

    <div class="box-loading-icon-1 box-loading-icon-size_2"></div>

    <div class="box-loading-text-1">Ladataan...</div>
    
  </div>
  </transition>

</template>


<script> // -------------------------------------------------------------------------------------------------------

//import mixinXhrPostAsyncApp from '@/mixins/mixinXhrPostAsyncApp'


export default {
  name:    'boxLoadingCover',
  //mixins: [ mixinXhrPostAsyncApp ],
  //components: {itemSignup},

  data () {
    return {
       //userType: '', // Type of the clicked buttom's id
    } // return
  }, // data

  
  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>



export default {

  data () {
    return {

      mixinValidationServerBasicShow:     false,
      mixinValidationServerBasicMessages: [],

    } // return
  }, // data

	methods: {
		
		//--- Close SERVER VALIDATION BOX -----------------------------------------------------
		mixinBoxValidationServerBasicClose () {
      this.mixinValidationServerBasicShow    = false;
      this.mixinValidationServerBasicMessages = [];
    }

	} // methods
}

<template>

    <!-- INIT BOX: HOW IT WORKS ............................................................................. -->
    <section class="init-box-how_it_works">

      <div><h4 class="theme-light-title-2">Miten palvelu toimii?</h4></div>
      <div><h1 class="theme-light-title-1 m_b-0">{{ titleMain }}</h1></div>
      <div>Vakuutusten kilpailuttaminen ei ole koskaan ollut näin helppoa</div>
      
      <div class="init-box-how_it_works-1">

        <div class="init-box-iconed-1">
          <div>
            <div class="init-box-iconed-1-number">1</div>
            <img src="@/assets/img/icon-pp-register.svg" alt="Hymyilevä mies">
          </div>
          <div><h4 class="theme-light-title-2">Rekisteröidy</h4></div>
          <div>Luo profiili ja täydennä tiedot. Palvelun käyttäminen on täysin ilmaista</div>
        </div>

        <div class="init-box-iconed-1">
          <div>
            <div class="init-box-iconed-1-number">2</div>
            <img src="@/assets/img/icon-pp-tender.svg" alt="Täytetty lomake ja hammasratas">
          </div>
          <div><h4 class="theme-light-title-2">Kilpailuta</h4></div>
          <div>Voit valita mistä yhtiöistä haluat saada tarjouksen</div>
        </div>
      
        <div class="init-box-iconed-1">
          <div>
            <div class="init-box-iconed-1-number">3</div>
            <img src="@/assets/img/icon-vakuutus-tehdaan-sopimus.svg" alt="Kädenpuristus">
          </div>
          <div><h4 class="theme-light-title-2">Valitse haluamasi</h4></div>
          <div>Voit vapaasti valita itseäsi miellyttävän tarjouksen tai olla hyväksymättä yhtäkään</div>
        </div>

      </div>

    </section>

</template>


<script> // -------------------------------------------------------------------------------------------------------

//import boxPrelaunchMain from '@/components/pageInit/boxPrelaunchMain'

export default {
  name:        'boxInitBoxHowItWorks',
  components: {},


  data () {

    return {

       titleMain: 'Näin kilpailutat vakuutuksesi helposti', // DEFAULT: for "pp"

    } // return

  }, // data


  // ===========================================================================================================
  created () {

    if (this.$store.state.userTypeInit == 'pc') {
      this.titleMain = 'Näin kilpailutat yrityksen tai yhdistyksen vakuutukset helposti';
    }

  }, // created


  // ===========================================================================================================
  mounted () {
  }, // mounted


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>
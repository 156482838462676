<template>
<div>

  <div class="client-container info">

    <!-- NAVBAR .............................................................................. -->
    <item-init-nav-bar parentType="pageInfo"></item-init-nav-bar>

    <!-- TITLE ............................................................................... -->
    <div class="info-box-title-main">
      <div class="info-box-main">
        <div><h1 class="title-h1-bigger theme-light-title-1">Tietoa vakuutuksista</h1></div>
      </div>
    </div>

    <!-- MAIN ................................................................................ -->
    <div class="info-box-main info-box-1">

      <div class="info-about-insurances-1">

        <p>Tällä sivulla voit etsiä yleistietoa kaikista yleisimmistä Suomessa tarjolla olevista vakuutuksista. Pääsääntönä on kuitenkin hyvä muistaa, että eri vakuutusyhtiöt voivat käyttää omia nimikkeitä samoista vakuutuksista, ja että eri yhtiöiden samannimiset vakuutustuotteet eivät välttämättä pidä sisällään samoja vakuutusehtoja. Vakuutusten ehdot ja hinnat voivat myös muuttua ajan myötä, joten omia vakuutuksia on hyvä ajoittain tarkastaa. Huomioi että tämän sivun tekstejä ei ole kirjoittanut eikä tarkastanut vakuutusammattilainen. Suosittelemmekin konsultoimaan vakuutusasiamiehiä omien vakuutustesi vertailua tehdessä. Vakuutustiedot.fi palvelun välityksellä pääset keskustelemaan vakuutusasiamiesten kanssa, kun pyydät tarjouksen jostakin nykyisestä tai uudesta vakuutuksesta jonka haluat hankkia. Palvelumme on henkilö-, yritys- ja yhdistysasiakkaille täysin maksuton.</p>

        <h4>Kilpailuta ajoneuvovakuutus</h4>
        <p>Ajoneuvovakuutus on yleisnimike mille tahansa moottoriajoneuvon liikennevakuutukselle, joka on lakisääteisesti otettava jokaiselle liikennekäytössä olevalle ajoneuvolle. Ajoneuvovakuutukseen voi liittää myös kaskovakuutuksen, jolloin vakuutus on hintavampi, mutta kattaa enemmän riskejä ja siten korvaa useamman vahingon kohdalla.</p>

        <h4>Kilpailuta alusvakuutus</h4>
        <p>Alusvakuutus on vesillä toimivan aluksen vakuutus. Eri vakuutusyhtiöillä on erinimisiä ja erilaisilla ehdoilla varustettuja vakuutuksia, jotka kaikki voidaan kategorisoida alusvakuutuksiksi. Tähän luokkaan voi esimerkiksi kuulua laivavakuutus, ilma-alusvakuutus, purjevenevakuutus sekä vene- tai moottorivenevakuutus.</p>

        <h4>Kilpailuta arvoesinevakuutus</h4>
        <p>Arvoesinevakuutus tai toiselta nimeltään arvotavaravakuutus kattaa minkä tahansa arvokkaan esineen kuten vaikkapa soittimen, korut, kellon tai kameran. Arvoesinevakuutus otetaan pääsääntöisesti tilanteessa jossa arvoesineita kuljetetaan säännöllisesti mukana kodin ulkopuolelle, jolloin kotivakuutuksen korvausvastuu monesti lakkaa.</p>

        <h4>Kilpailuta asuntoauton vakuutus</h4>
        <p>Asuntoauton vakuutus Asuntoauton taikka toiselta nimeltään matkailuauton vakuutus on tarkoitettu nimensä mukaisesti asuntoautolle. Asuntoauton vakuutus sisältää pakollisen liikennevakuutuksen ja siihen saa liitettyä myös kaskovakuutuksen paremman vakuutusturvan saamiseksi, jolloin esim. hirvi- ja palovahingot sekä varkaudet korvataan. Yleensä asuntoauton vakuutus kattaa myös kaikki asuntoautoon kiinteästiasennetut varusteet.</p>

        <h4>Kilpailuta asuntovaunun vakuutus</h4>
        <p>Asuntovaunun vakuutus joka on toiselta nimeltään matkailuvaunun vakuutus, otetaan asuntovaunulle sekä sen varusteille. Osassa yhtiöistä asuntovaunun vakuutuksesta saatetaan käyttää myös matkailuperävaunun vakuutus nimikettä, ja se pitää sisällään pakollisen liikennevakuutuksen sekä vapaaehtoisen kaskon, jolloin esim. eläinkolarit, puun kaatumiset vaunun päälle tai hinauskustannukset saatetaan korvata.</p>

        <h4>Kilpailuta autovakuutus</h4>
        <p>Autovakuutuksella viitataan lähinnä henkilöauton liikennevakuutukseen sekä siihen saatavaan kaskovakuutukseen. Autovakuutuksen vertailua kannattaa tehdä, sillä vakuutus on pakollinen ja eri yhtiöiden etuohjelmat sekä keskittämisedut voivat tarkoittaa merkittäviäkin hintaeroja sisällöltään lähes samanlaisen vakuutuksen osalta. Kaskojen kohdalla erot eri vakuutusyhtöiden välillä ehtojen ja korvauspalvelun osalta kuitenkin kasvavat, jolloin vakuutusasiamiehen konsultointi voi jo olla paikallaan.</p>

        <h4>Kilpailuta eläinvakuutus</h4>
        <p>Eläinvakuutus on useimmissa yhtiöissä sama asia kuin lemmikkivakuutus. Yleisimmin se korvaa koiran, kissan tai hevosen hoitokuluja, joten vakuutuksesta esiintyy myös eläinlääkärikuluvakuutus nimikettä. Pienlemmikin voi myös vakuuttaa mutta vain harva yhtiö tarjoaa siihen räätälöityjä vakuutuksia.</p>

        <h4>Kilpailuta etätyövakuutus</h4>
        <p>Etätyövakuutus on työnantajille tarkoitettu vapaaehtoinen ryhmätapaturmavakuutus etätyöntekijöiden vakuuttamiseksi, jolla voidaan korvata esim. hoitokuluja tai ansiomenetyksiä. Etätyön määrän kasvaessa myös tämän vakuutuksen suosio on hiljalleen kasvussa.</p>

        <h4>Kilpailuta hallinnon vastuuvakuutus</h4>
        <p>Hallinnon vastuuvakuutus on yrityksen johdolle tarkoitettu taloudellisten vahinkojen varalta suojaava vakuutus. Tämä vakuutus korvaa yleensä myös asian puimisesta syntyneet oikeudenkäyntikulut ja suojaa henkilökohtaiselta korvausvastuulta.</p>

        <h4>Kilpailuta hammasvakuutus</h4>
        <p>Hammasvakuutus on työnantajille sunnattu vakuutus, joka otetaan yrityksen henkilöstölle, ja se voi olla yksi henkilöstön luontoiseduista. Hammasvakuutus luokitellaan terveysvakuutusten alle, ja osassa yhtiöistä suunhoidon vakuutukset onkin liitetty terveysvakuutukseen. Hammasvakuutuksen ansiosta henkilöstö pääsee nopeasti laadukkaaseen yksityiseen hammashoitoon esim. juurihoitoon, hammaspaikkaukseen tai iensairauksien hoitoon. Nopea hoitoonpääsy voi vähentää henkilöstön työpoissaolon määrää sekä lisätä työnantajan arvostusta henkilöstön näkökulmasta.</p>

        <h4>Kilpailuta henkilövakuutukset</h4>
        <p>Henkilövakuutukset on laaja yläkäsite vakuutuksille joilla korvataan ihmisille sattuneita vahinkoja. Pääsääntöisesti ne pitävät sisällään erilaiset terveysvakuutukset, tapaturmavakuutukset sekä henkivakuutukset.</p>

        <h4>Kilpailuta henkivakuutus</h4>
        <p>Henkivakuutuksella suojataan oman perheen toimeentulo vakuutuksenottajan kuollessa. Henkivakuutus tuo turvaa etenkin jos henkilöllä on paljon lainaa ja hän on perheensä ensisijainen elättäjä.</p>

        <h4>Kilpailuta hevosvakuutus</h4>
        <p>Hevosvakuutus otetaan yleensä kattamaan eläinlääkärikuluja, joten se soveltuu yhtälailla sekä harraste- että kilpahevosille. Hevosvakuutuksen kattavuutta sekä omavastuun määrää voi pääsääntöisesti säätää oman riskinsietokyvyn mukaan, mutta eri yhtiöiden välinen vertailu silti kannattaa, koska variaatiota hinnoissa ja ehdoissa ilmenee.</p>

        <h4>Kilpailuta hoitokuluvakuutus</h4>
        <p>Hoitokuluvakuutus kuuluu henkilövakuutuksiin, ja on yksi nimikkeistä jota käytetään terveysvakuutuksesta tai sairausvakuutuksesta. Nimensä mukaisesti sillä korvataan hoidoista aiheutuneita kuluja. Eri yhtiöiden välillä voi hoitokuluvakuutuksen osalta olla suuriakin eroja ehdoissa sekä tarjottavissa palveluissa, joten huolellinen vertailu ennen vakuutuksen ottamista kannattaa.</p>

        <h4>Kilpailuta IPR-vastuuvakuutus</h4>
        <p>IPR-vastuuvakuutus on tarkoitettu yritysten immateriaalioikeuksien loukkauksia vastaan käytyjen oikeudenkäyntikulujen korvaamista varten. Kyseiset oikeudenkäynnit voivat olla pitkiä ja kalliita, joten vakuutus voi olla järkevä vaihtoehto, mikäli tietty patentti tai tavaramerkki on yrityksen liiketoimminan kannalta äärimmäisen tärkeä.</p>

        <h4>Kilpailuta irtaimistovakuutus</h4>
        <p>Irtaimistovakuutus on tarkoitettu sekä henkilöasiakkaille että yrityksille. Henkilöasiakkailla irtaimistovakuutus on yleensä osa kotivakuutusta ja sillä korvataan kodin vaurioituneita tavaroita kuten huonekaluja tai vaatteita. Myös kodin elektroniikka, kännykät ja kannettavat tietokoneet mukaan lukien, sekä esim. polkupyörät lasketaan yleensä kodin irtaimistoksi.</p>

        <h4>Kilpailuta jakeluauton vakuutus</h4>
        <p>Jakeluauton vakuutus on liikennevakuutus, joka on tarkoitettu luvanvaraisen jakeluauton erityistarpeisiin, joista merkittävin on kenties menetettyjen ajopäivien korvaaminen. Tästä vakuutuksesta saatetaan käyttää myös nimikettä pakettiauton vakuutus.</p>

        <h4>Kilpailuta jatkuva matkavakuutus</h4>
        <p>Merkittävin ero jatkuvalla matkavakuutuksella verrattuna tavalliseen matkavakuutukseen on, että jatkuvalla matkavakuutuksella ei ole merkittyä päättymispäivämäärää. Tämä vaihtoehto soveltuu erityisesti usein matkustaville, sillä se säästää aikaa ja vaivaa. Matkavakuutus on hintaansa nähden varsin kätevä vakuutus, koska se kattaa matkatavaroiden lisäksi monesti erilaisia matkan aikana sattuneita tapaturmia sekä hoitokuluja, joista voi muodostua vieraassa maassa huomattaviakin kustannuksia.</p>

        <h4>Kilpailuta kaskovakuutus</h4>
        <p>Kaskovakuutus on pakollisen liikennevakuutuksen vapaaehtoinen lisäosa, jolla saat huomattavasti enemmän lisäturvaa. Kaskovakuutus korvaa pääsääntöisesti omalle ajoneuvollesi tapahtuneita vahinkoja ja ilkivaltaa. Kaskoja löytyy hyvin monenlaisilla liitännäisnimikkeillä, ja ehtojen sekä hintojen osalta löytyy laajaa variaatiota jopa saman yhtiön sisällä.</p>

        <h4>Kilpailuta keskeytysvakuutus</h4>
        <p>Keskeytysvakuutus on yrityksten liiketoiminnan äkillisestä keskekytyksestä aiheutuneiden kulujen korvaamiseen tarkoitettu vakuutus. Esimerkkejä keskeytyksen aiheuttajista ovat tulipalo, vesivahinko, tuotantolaitteen rikkoutuminen, avainhenkilön äkillinen sairastuminen. Keskeytysvakuutuksella saat palkat maksettua henkilöstölle myös toiminnan seistessä, jolloin vahingon mittakaavaa saadaan minimoitua.</p>

        <h4>Kilpailuta kiinteistövakuutus</h4>
        <p>Kiinteistövakuutus on tarkoitettu taloyhtiöiden ja yritysten tarpeisiin. Kiinteistövakuutus voi pitää sisällään monia erinimisiä osavakuutuksia, joita yhdistelemällä saadaan itselle sopiva kiinteistövakuutuskokonaisuus. Kiinteisövakuutuksen turva ulottuu monesti varsin laajalle sisältäen esimerkiksi talotekniikan rikkoutumisen, erilaiset luonnonilmiöistä syntyneet vahingot sekä vuokratulojen menetykset.</p>

        <h4>Kilpailuta kissavakuutus</h4>
        <p>Kissavakuutus kuuluu eläinvakuutuksiin, ja se on täsmävakuutus kissan eläinlääkärikulujen korvaamiseen. Kissavakuutus voi korvata myös kissan katoamisen tai äkillisen kuoleman sattuessa.</p>

        <h4>Kilpailuta koiravakuutus</h4>
        <p>Koiravakuutus on eläinvakuutus, joka otetaan pääsääntöisesti koiran eläinlääkärikulujen kattamiseksi. Koiravakuutus voi kuitenkin korvata myös koiran katoamisen tai äkillisen kuoleman.</p>

        <h4>Kilpailuta konsulttivastuuvakuutus</h4>
        <p>Konsulttivastuuvakuutus on yritysvakuutus, jolla korvataan virheellisestä suunnittelusta tai konsultoinnista aiheutuneita vahinkoja vahingon kohteeksi joutuneelle toimijalle. Vakuutus voi korvata myös oikeudenkäyntikuluja sekä taloudellisia menetyksiä vahingon kärsineelle.</p>

        <h4>Kilpailuta kotivakuutus</h4>
        <p>Kotivakuutus on yksi yleisimpiä henkilöasiakkaiden vakuutuksia. Kotivakuutuksella suojaudutaan taloudellisilta riskeiltä esim. vesivahingon, tulipalon tai varkauden osalta. Myös kotona olevat esineet ja tavarat kuuluvat monesti kotivakuutuksen korvauksen piiriin.</p>

        <h4>Kilpailuta kuljetusvastuuvakuutus</h4>
        <p>Kuljetusvastuuvakuutus on yritysvakuutus, joka on tarkoitettu rahtia kuljettaville yrityksille. Kuljetusvastuuvakuutus ei ole sama asia kuin tavarankuljetusvakuutus, joka on tarkoitettu tavaran myyjille tai ostajille. Kuljetusvastuuvakuutuksia löytyy erinimisinä riippuen tehdäänkö kuljetuksia maanteitse, vesiteitse, rautateitse vai ilmateitse.</p>

        <h4>Kilpailuta kuorma-auton vakuutus</h4>
        <p>Kuorma-auton vakuutus koostuu liikennevakuutuksesta sekä vapaaehtoisesta kaskosta. Kuorma-autovakuutuksen kaskolla voi saada korvauksia myös menetetyistä käyttöpäivistä.</p>

        <h4>Kilpailuta lapsivakuutus</h4>
        <p>Lapsivakuutus on sekä sairaus- että tapaturmavakuutus lapsille. Määritelmät siitä minkä ikäisille lapsille vakuutus on tarkoitettu, vaihtelee eri yhtiöiden välillä. Vielä syntymättömille lapsille löytyy myös omia vauvavakuutuksia tai syntymättömän lapsen vakuutuksia. Vakuutus tarjoaa nopean pääsyn laadukkaaseen hoitoon.</p>

        <h4>Kilpailuta lemmikkivakuutus</h4>
        <p>Lemmikkivakuutus on yhtä kuin eläinvakuutus ja se on yläkäsite eri lemmikkien vakuutuksille. Lemmikkivakuutusta käytetään pääsääntöisesti eläinlääkärikulujen korvaamiseen.</p>

        <h4>Kilpailuta liikennevakuutus</h4>
        <p>Liikennevakuutus on yksi yleisimpiä vakuutuksia, sillä se on pakollinen kaikille liikenteessä käytettäville ajoneuvoille. Liikennevakuutus pitää siis sisällään esim. autovakuutuksen, moottoripyörävakuutuksen sekä mopovakuutuksen ja tietenkin kaskovakuutukset. Liikennevakuutuksia kilpailutetaan varsin säännöllisesti kun eri yhtiöiden korvausehdoissa ei ole merkittävää eroa pakollisen liikennevakuutuksen osalta.</p>

        <h4>Kilpailuta linja-autovakuutus</h4>
        <p>Linja-autovakuutus on etenkin bussiyhitöille suunnattu vakuutus. Sillä voidaan turvata itse bussin lisäksi kuljettaja sekä matkustajien tavarat. Eri vakuutusyhtiöt tarjoavat erilaisia ehtoja, joten vertailu kannattaa.</p>

        <h4>Kilpailuta maatilavakuutus</h4>
        <p>Maatilavakuutuksella voidaan turvata koko maatilan omaisuus, tuotanto, henkilöstö sekä maatalousyrittäjän ansiotuloja. Kyseessä on siis varsin laaja kokonaisuus, ja pääsääntöisesti maatilavakutusta voi räätälöidä omien tarpeiden ja riskinsietokyvyn mukaan.</p>

        <h4>Kilpailuta matkailuauton vakuutus</h4>
        <p>Matkailuauton vakuutus on toiselta nimeltään asuntoauton vakuutus, joka on tarkoitettu nimensä mukaisesti matkailuautoille. Matkailuauton vakuutus sisältää pakollisen liikennevakuutuksen, ja siihen saa liitettyä myös kaskovakuutuksen paremman vakuutusturvan saamiseksi, jolloin esim. hirvi- ja palovahingot sekä varkaudet korvataan. Yleensä matkailuauton vakuutus kattaa myös kaikki matkailuautoon kiinteästi asennetut varusteet.</p>

        <h4>Kilpailuta matkailuvaunun vakuutus</h4>
        <p>Matkailuvaunun vakuutus on toiselta nimeltään asuntovaunun vakuutus, ja se otetaan matkailuvaunulle sekä sen varusteille. Osassa yhtiöistä matkailuvaunun vakuutuksesta saatetaan käyttää myös matkailuperävaunun vakuutus nimikettä, ja se pitää sisällään pakollisen liikennevakuutuksen sekä vapaaehtoisen kaskon, jolloin esim. eläinkolarit, puun kaatumiset vaunun päälle tai hinauskustannukset saatetaan korvata.</p>

        <h4>Kilpailuta matkatavaravakuutus</h4>
        <p>Matkatavaravakuutus on osa matkustajavakuutusta, ja sillä korvataan matkatavaroita esim. rikkoutumiselta tai varkaudelta. Ehdot vakuutuksen korvattavuudessa vaihtelevat yhtiöittäin, ja joissakin yhtiöissä vakuutus voi kattaa koko perheen tavarat.</p>

        <h4>Kilpailuta matkavakuutus</h4>
        <p>Matkavakuutus pitää sisällään vähintään matkustajavakuutuksen sekä mahdollisesti matkatavaravakuutuksen, matkavastuuvakuutuksen ja matkan oikeusturvavakuutuksen. Matkavakuutuksia saa määräaikaisina sekä jatkuvina. Ehdoissa ja hinnoissa on eroja yhtiöiden välillä, joten vertailu kannattaa.</p>

        <h4>Kilpailuta matkavastuuvakuutus</h4>
        <p>Matkavastuuvakuutuksella korvataan vakuutetun ulkoupuolisille aiheuttamia tavara- tai henkiövahinkoja. Joissakin yhtiöissä matkavastuuvakuutus sisältyy automaattisesti matkavakuutukseen.</p>

        <h4>Kilpailuta matkustajavakuutus</h4>
        <p>Matkustajavakuutus on osa matkavakuutusta, ja sillä korvataan lääkärikäyntejä matkan aikana tapahtuneille tapaturmille tai sairastumisille. Monissa maissa turistin lääkärikäynnit voivat tulla hyvin kalliiksi, joten vakuutus antaa hyvän suojan mahdollista taloudellista riskiä vastaan.</p>

        <h4>Kilpailuta merivakuutus</h4>
        <p>Merivakuutuksella vakuutetaan ammattikäytössä olevia vesialuksia tai niiden rahteja. Vakuutuksen piiriin voivat kuulua kaikenkokoiset yrityksen alukset veneistä aivan matkustajalaivoihin saakka. Merivakuutuksia tarjoavat vain harvat yhtiöt Suomessa.</p>

        <h4>Kilpailuta metsäpalovakuutus</h4>
        <p>Metsäpalovakuutus on osa metsävakuutusta ja sillä korvataan tulen aiheuttamia vahinkoja puustolle, taimikolle ja puutavaralle. Eri yhtiöiden välillä voi olla eroja ehtojen laajuudessa, joten vertailua kannattaa tehdä.</p>

        <h4>Kilpailuta metsävakuutus</h4>
        <p>Metsävakuutus on tarkoitettu talousmetsien vakuuttamiseen, ja sillä voi hankkia turvaa esim. myrskyjen, hyönteisten, lumen, tulen ja tulvien varalta. Laajat metsävakuutukset korvaavat yleensä myös menetettyä tuottoa.</p>

        <h4>Kilpailuta moottorikelkkavakuutus</h4>
        <p>Moottorikelkkavakuutus koostuu liikennevakuutuksesta sekä vapaaehtoisesta kaskovakuutuksesta. Moottorikelkkavakuutus on voimassa ympäri vuoden, ja vaikka kustannukset virallisesti kertyvät pääosin talvikuukausilta, niin itse laskutus on yleensä jaettu tasaisiin eriin pitkin vuotta.</p>

        <h4>Kilpailuta moottoripyörävakuutus</h4>
        <p>Moottoripyörävakuutus koostuu liikennevakuutukseta sekä kaskosta, ja sillä voidaan korvata esim. toisille sekä itselle aiheutuneita haittoja, ja oman moottoripyörän sekä varusteiden rikkoutumista. Kaskovakuutuksien edut pitävät sisällä paljon muutakin ja vertailu eri yhtiöiden välillä kannattaa. Moottoripyörävakuutukset ovat voimassa ympäri vuoden, mutta ovat vuodenaikahinnoiteltuja.</p>

        <h4>Kilpailuta moottoriveneen vakuutus</h4>
        <p>Moottoriveneen vakuutus on venevakuutus, jolla korvataan veneelle tai sen varusteille sattuneita vahinkoja. Vakuutuksen kattavuuden voi itse päättä, ja eri yhtiöiden korvausehdoissa on eroja, joten tarkempaa vertailua kannattaa tehdä.</p>

        <h4>Kilpailuta mopoauton vakuutus</h4>
        <p>Mopoauton vakuutus koostuu liikennevakuutuksesta sekä vapaaehtoisesta kaskosta. Vakuutuksen tulee olla mopautoa ensisijaisesti käyttävän nimissä, eli alaikäisen tapauksessa vakuutuksen saantiin saatetaan tarvita vanhemman suostumus.</p>

        <h4>Kilpailuta mopovakuutus</h4>
        <p>Mopovakuutus pitää sisällään pakollisen liikennevakuutuksen sekä vapaaehtoisen kaskon. Mopon vakuutus on vuodenaikahinnoiteltu, eli talvikuukausina vakuutus ei kerrytä juuri lainkaan kustannuksia. Mopovakuutuksen tulee olla mopoa ensisijaisesti käyttävän henkilön nimissä, joten alaikäisen kohdalla saatetaan tarvita vanhemman suostumus vakuutuksen myöntämiseen.</p>

        <h4>Kilpailuta MYEL-vakuutus</h4>
        <p>MYEL-vakuutus on maatalousyrittäjien, metsänomistajien, kalastajien ja poronhoitajien sekä heidän perheenjäsentensä työeläkevakuutus. MYEL-vakuutuksen saa ainoastaan Maatalousyrittäjien eläkelaitos Melalta.</p>

        <h4>Kilpailuta määräaikainen matkavakuutus</h4>
        <p>Määräaikainen matkavakuutus on matkavakuutus, jolla on ennalta määrätty ajankohta voimassaolon päättymiselle. Matkavakuuutus sisältää vähintään matkustajavakuutuksen sekä mahdollisesti matkatavaravakuutuksen, matkavastuuvakuutuksen ja matkan oikeusturvavakuutuksen.</p>

        <h4>Kilpailuta mökkivakuutus</h4>
        <p>Mökkivakuutus kuuluu kotivakuutusten kategoriaan, ja sillä vakuutetaan vapaa-ajan asuntoja, kuten mökkejä, joissa asutaan vain tilapäisesti. Mökkivakuutuksella voidaan korvata esim. myrskytuhoja tai murtoja.</p>

        <h4>Kilpailuta mönkijän vakuutus</h4>
        <p>Mönkijän vakuutus sisältää liikennevakuutuksen sekä vapaaehtoisen kaskon. Mönkijävakuutus on vuodenaikahinnoiteltu eli talvikuukausina hinta on selvästi alhaisempi.</p>

        <h4>Kilpailuta nuorisovakuutus</h4>
        <p>Nuorisovakuutus nimikkeen alla voi olla erilaisia vakuutuspaketteja eri-ikäisille nuorille tai sillä voidaan viitata yleiseen alennukseen monista yleisimmistä vakuutuksista, mikäli on vakuutusta otettaessa alle tietyn ikäinen. Nuorisovakuutusten kohdalla saatetaan käyttää myös opiskelijavakuutus nimikettä. Vertailu eri yhtiöiden välillä kannattaa, jotta löytää itselleen sopivimman kokonaisuuden.</p>

        <h4>Kilpailuta oikeusturvavakuutus</h4>
        <p>Oikeusturvavakuutus on vahinkovakuutus, jolla korvataan asianajo- sekä oikeudenkäyntikuluja. Oikeusturvavakuutus on monesti liitännäisenä muiden vakuutusten yhteydessä, kuten koti- tai matkavakuutuksessa.</p>

        <h4>Kilpailuta omaisuusvakuutus</h4>
        <p>Omaisuusvakuutus on yritysvakuutus, jolla turvataan räätälöidysti yrityksen omaisuutta sekä kiinteistöjä.</p>

        <h4>Kilpailuta osakaskovakuutus</h4>
        <p>Osakaskovakuutus on suppea muoto kaskovakuutuksesta, joka on pakollisen liikennevakuutuksen vapaaehtoinen lisäosa. Kuten laaja kasko, myös osakasko korvaa omalle ajoneuvollesi tapahtuneita vahinkoja ja ilkivaltaa.</p>

        <h4>Kilpailuta pakettiauton vakuutus</h4>
        <p>Pakettiauton vakuutus koostuu liikennevakuutuksesta sekä vapaaehtoisesta kaskosta. Kevyet kuorma-autot voidaan myös vakuuttaa pakkettiauton vakuutuksella. Turvatasoissa ja ehdoissa on eroja yhtiöiden välillä, joten vertailu ja kilpailuttaminen kannattaa.</p>

        <h4>Kilpailuta palovakuutus</h4>
        <p>Palovakuutus on yleensä liitännäisenä muiden vakuutusten yhteydessä, kuten koti- tai mökkivakuutuksessa. Palovakuutuksella korvataan nimensä mukaisesta tulipalosta aiheutuneita palo- ja savuhaittoja.</p>

        <h4>Kilpailuta peräkärryvakuutus</h4>
        <p>Peräkärryvakuutus on peräkärryjen, perävaunujen ja venetrailerien vakuutus, ja se koostuu liikennevakuutuksesta sekä vapaaehtoisesta kaskosta. Myös matkailu- ja asuntovaunut vakuutetaan tietyissä yhtiöissä peräkärryvakuutuksella.</p>

        <h4>Kilpailuta perävaunun vakuutus</h4>
        <p>Perävaunun vakuutus on sama mutta eriniminen vakuutus kuin peräkärryvakuutus. Sillä vakuutetaan perävaunuja, peräkärryjä, venetrailereita, matkailuvaunuja sekä asuntovaunuja. Perävaunun vakuutus koostuu liikennevakuutuksesta sekä vapaaehtoisesta kaskosta, jolla saa huomattavasti enemmän turvaa.</p>

        <h4>Kilpailuta potilasvakuutus</h4>
        <p>Potilasvakuutus on lakisääteisesti pakollinen vakuutus kaikille terveyden- tai sairaanhoitoa harjoittaville yrityksille. Vakuutuksella korvataan hoitotoimenpiteiden aikana aiheutuneita vahinkoja potilaalle.</p>

        <h4>Kilpailuta purjeveneen vakuutus</h4>
        <p>Purjeveneen vakuutus on venevakuutus, joka otetaan purjeveneelle. Vakuutus antaa turvaa sekä vesillä että kuljetuksen ja säilytyksen aikana ympäri vuoden. Ehtojen laajuudessa on eroja yhtiöiden välillä, joten vertailua kannattaa tehdä.</p>

        <h4>Kilpailuta raskaan perävaunun vakuutus</h4>
        <p>Raskaan perävaunun vakuutus on pääsääntöisesti yritysvakuutus, joka on tarkoitettu yli 3,5 tn painaville perävaunuille. Vakuutus koostuu liikennevakuutuksesta sekä vapaaehtoisesta kaskosta.</p>

        <h4>Kilpailuta rikosvakuutus</h4>
        <p>Rikosvakuutus on yritysvakuutus, jolla korvataan oman henkilöstön tai ulkopuolisten henkilöiden tekemiä yrtykseen kohdistuvia omaisuusrikoksia, kuten petoksia, väärennöksiä tai pankki- ja luottokorttien väärinkäyttötapauksia.</p>

        <h4>Kilpailuta ryhmähenkivakuutus</h4>
        <p>Ryhmähenkivakuutus on työnantajien vakuutus, jolla vakuutetaan työntekijöitä kuoleman varalta. Kuolemantapauksessa korvauksen saajina ovat työntekijän puoliso sekä alle 22-vuotiaat lapset.</p>

        <h4>Kilpailuta sairauskuluvakuutus</h4>
        <p>Sairauskuluvakuutus korvaa sairastumisesta aiheutuneita kuluja. Yhtiöstä riippuen sairaskuluvakuutus voi sisältää myös tapaturmavakuutuksen sekä henkivakuutuksen. Sairauskuluvakuutuksille on yleensä myös jokin yläikäraja mihin saakka se on voimassa.</p>

        <h4>Kilpailuta sairausvakuutus</h4>
        <p>Sairausvakuutus on yksi nimikkeistä jota käytetään sairaskuluvakuutuksen tai tapaturmavakuutuksen osalta. Sairausvakuutuksella korvataan sairauden tai loukkaantumisen aiheuttamia hoitokustannuksia. Vakuutusyhtiöiden sairausvakuutuksilla pääsee nopeasti laadukkaaseen hoitoon.</p>

        <h4>Kilpailuta skootterin vakuutus</h4>
        <p>Skootterin vakuutus on sama asia kuin mopovakuutus ja se sisältää liikennevakuutuksen sekä vapaaehtoisen kaskon. Skootterin vakuutus on vuodenaikahinnoiteltu, eli talvikuukausina vakuutus ei kerrytä juuri lainkaan kustannuksia. Skootterivakuutuksen tulee olla skootteria ensisijaisesti käyttävän henkilön nimissä, joten alaikäisen kohdalla saatetaan tarvita vanhemman suostumus vakuutuksen myöntämiseen.</p>

        <h4>Kilpailuta soitinvakuutus</h4>
        <p>Soitinvakuutus on arvotavaravakuutus taikka arvoesinevakuutus, jolla vakuutetaan jokin tietty tai tietyt soittimet. Soitinvakuutus on tarpeen kun soitin on hyvin arvokas ja sitä kuljetetaan säännöllisesti kodin ulkopuolelle.</p>

        <h4>Kilpailuta taksivakuutus</h4>
        <p>Taksivakuutus on liikenne ja kaskovakuutus, jonka kasko voi olla räätälöity taksiyrittäjän tarpeisiin. Eri yritysten ehtojen välillä on eroja, joten omia tapeita vastaavan vakuutuksen löytämiseen kannattaa tehdä huolellista vertailua.</p>

        <h4>Kilpailuta talkoovakuutus</h4>
        <p>Talkoovakuutus on talkoisiin osallistujille tarkoitettu tapaturmavakuutus. Talkootyö on vastikkeetonta työtä josta ei makseta palkkaa, ja esimerkiksi omakotitalon tai mökin rakentaminen talkoovoimin on yleinen tilanne jossa talkoovakuutus voi olla tarpeen.</p>

        <h4>Kilpailuta taloudellinen vastuuvakuutus</h4>
        <p>Taloudellisella vastuuvakuutuksella suojataan yrityksiä heidän aiheuttamilta taloudellisilta vahingoilta asiakkailleen.</p>

        <h4>Kilpailuta tapaturmavakuutus</h4>
        <p>Tapaturmavakuutuksella korvataan kotona ja vapaa-ajalla sattuneiden tapaturmien hoitokuluja. Tietyille riskialtteille urheilulajeille, kuten monille kamppailulajielle tarvitaan omat spesiftit tapaturmavakuutukset. Tapaturmavakuutukset ovat edullisuutensa johdosta varsin suosittuja.</p>

        <h4>Kilpailuta tavarankuljetusvakuutus</h4>
        <p>Tavarankuljetusvakuutus on yritysvakuutus, joka on tarkoitettu tavaran omistajalle, eli joko sen myyjälle tai ostajalle turvaamaan tavaran kuljetusta.</p>

        <h4>Kilpailuta terveysvakuutus</h4>
        <p>Terveysvakuutus on yksi nimikkeistä, jota käytetään sairaus- tai sairauskuluvakuutuksista. Terveysvakuutuksen alle voi kuulua lukuisia muitakin erinimisiä täsmävakuutuksia, mutta niitä kaikkia yhdistää se, että vakuutuksella korvataan vakuutetulle aiheutuneita hoitokuluja.</p>

        <h4>Kilpailuta tietoturvavakuutus</h4>
        <p>Tietoturvavakuutus on yritysvakuutus ja toiselta nimeltään kybervakuutus. Vakuutuksella korvataan tietomurroista tai esim. palvelunestohyökkäyksestä aiheutuneita kuluja varsin laajasti. Vakuutus on sen tuomaan turvaan nähden varsin edullinen, joten etenkin yritykset, joille heidän tietojärjestelmät ovat liiketoiminnan keskiössä, kannattaa ottaa kyseinen vakuutus.</p>

        <h4>Kilpailuta toimeentulovakuutus</h4>
        <p>Toimeentulovakuutus on vakuutus, joka korvaa pitkän sairausloman aikaisia ansionmenetyksiä. Monesti toimeentulovakuutus on osana jotakin muuta henkilövakuutusta. Eri yhtiöt voivat käyttää myös eri nimikkeitä samasta vakuutuksesta.</p>

        <h4>Kilpailuta toiminnanvastuuvakuutus</h4>
        <p>Toiminnanvastuuvakuutus on melko yleinen yritysvakuutus, jolla siirretään yrityksen toiminnasta aiheutuneiden esine- tai henkilövahinkojen korvausvastuu vakuutusyhtiölle.</p>

        <h4>Kilpailuta traktorivakuutus</h4>
        <p>Traktorivakuutus koostuu liikennevakuutuksesta sekä vapaaehtoisesta kaskosta. Traktorivakuutuksen voi ottaa sekä yksityishenkilönä että yritysasiakkaana.</p>

        <h4>Kilpailuta tuotevastuuvakuutus</h4>
        <p>Tuotevastuuvakuutus on yritysvakuutus, jolla yrityksen tuotteesta aiheutuneet henkilö- tai esinevahingot korvataan vakuutusyhtiön toimesta. Tuotevastuulain mukaisesti yritys on korvausvelvollinen omien tuotteiden aiheuttamien haittojen korvaamisesta haittoja kärsineelle, joten tuotevastuuvakuutuksella voidaan minimoida yrityksen taloudellisia riskejä.</p>

        <h4>Kilpailuta TyEL-vakuutus</h4>
        <p>TyEL-vakuutus on työnantajan ottama työeläkevakuutus työntekijöilleen. Vakuutus tulee pakolliseksi työnanatajalle, jos yksikin työsuhteessa oleva 17-68 vuotias henkilö tienaa vähintän 60,57 € kuukaudessa.</p>

        <h4>Kilpailuta työkonevakuutus</h4>
        <p>Työkonevakuutus on tarkoitettu erilaisten työkoneiden, kuten vaikkapa kaivureiden vakuuttamiseen. Moottorityökoneet sisältävät aina liikennevakuutuksen sekä vapaaehtoisen kaskon, ja työkonevakuutusta voi monesti täydentää monilla muilla yritysvakuutuksilla.</p>

        <h4>Kilpailuta työkyvyttömyysvakuutus</h4>
        <p>Työkyvyttömyysvakuutuksella vähennetään taloudellista riskiä väliaikaisesta tai pysyvästä työkyvyttömyydestä. Vakuutusta voi yleensä hakea 59 vuotiaaksi saakka.</p>

        <h4>Kilpailuta työtapaturmavakuutus</h4>
        <p>Työtapaturmavakuutus on tarkoitettu yrityksen työntekijöille sekä yrityksen osakkaille, jotka työskentelvät yrityksessä. Vakuutuksella korvataan työtapaturmista tai ammattitaudeista koituneita kustannuksia työntekijälle, ja vakuutus on pakollinen kaikille yrityksille, joilla on työntekijöitä.</p>

        <h4>Kilpailuta täyskaskovakuutus</h4>
        <p>Täyskaskovakuutus on nimitys laajasta kaskovakuutuksesta, joka pitää sisällään kattavimman vakuutusturvan. Synonyymejä samalle vakuutukselle voi eri yhtiöillä olla esim. loistokasko, huippukasko tai superkasko.</p>

        <h4>Kilpailuta urheiluvakuutus</h4>
        <p>Urheiluvakuutus on suunnattu kilpaurheiluun tähtäävään harjoitteluun sekä riskialttiiden lajien harrastajille. Käytännössä urheiluvakuutus on tapaturmavakuutus, joka korvaa nimenomaan urheillessa sattuneita tapaturmia.</p>

        <h4>Kilpailuta vakavan sairauden turva</h4>
        <p>Vakavan sairauden turva on tarkoitettu yleisimpien vakavien sairauksien aiheuttamien taloudellisten riskien minimointiin, kuten esim. syöpien tai aivoinfarktin varalta. Korvaus annetaan yleensä heti diagnosoinnin jälkeen kertakorvauksena, ja se voi olla enintään 100000 €. Vakuutuskorvauksesta ei tarvitse maksaa veroja.</p>

        <h4>Kilpailuta vapaa-ajan tapaturmavakuutus</h4>
        <p>Vapaa-ajan tapaturmavakuutus on työantajan työntekijöilleen ottama vapaaehtoinen tapaturmavakuutus, jolla korvataan työntekijälle vapaa-ajalla tapahtuneiden tapaturmien hoitokuluja.</p>

        <h4>Kilpailuta varallisuusvastuuvakuutus</h4>
        <p>Varallisuusvastuuvakuutus on yritysvakuutus, jolla korvataan yrityksen toiminnasta aiheutuneet varallisuusvahingot taikka taloudelliset vahingot, jotka eivät liity henkilö- tai esinevahinkoihin, niitä kärsineelle osapuolelle.</p>

        <h4>Kilpailuta varustevakuutus</h4>
        <p>Varustevakuutus on yleisnimike erilaisten vakuutusten yhteydessä olevalle vakuutukselle, jolla korvataan varusteita. Varustevakuutuksia löytyy esim. hevosurheilun tai extremeurheilun tarpeisiin.</p>

        <h4>Kilpailuta vastuuvakuutus</h4>
        <p>Vastuuvakuutus on yleisnimike erilaisille vastuuvakuutuksille, joilla korvataan toisille aiheutettuja haittoja. Vastuuvakuutuksia on sekä henkilöasiakkaille että yritysasiakkaille, ja vastuuvakuutus voi olla myös jonkin toisen vakuutuksen, kuten esim. kotivakuutuksen yhteydessä.</p>

        <h4>Kilpailuta vauvavakuutus</h4>
        <p>Vauvavakuutus kuuluu lapsivakuutuksiin, ja iän myötä se voi muuttua terveysvakuutukseksi, joten sitä ei tarvitse erikseen irtisanoa. Vauvavakuutus kannattaa ottaa jo raskausaikana.</p>

        <h4>Kilpailuta venevakuutus</h4>
        <p>Venevakuutus on nimestään huolimatta tarkoitettu erilaisille vesikulkuneuvoille, eli esimerkiksi myös vesijettivakuutus voidaan perinteisten pujevenevakuutuksen ja moottorivenevakuutuksen ohella luokitella venevakuutukseksi. Soutuveneet luokitellaan kodin irtaimistoon, joten niille ei tarvita venevakuutusta.</p>

        <h4>Kilpailuta vesiskootterin vakuutus</h4>
        <p>Vesiskootterin taikka vesijetin vakuutus kuuluu venevakuutuskiin, ja sillä turvataan vesiskootterille aiheutuneet rikkoutumiset tai esim. varkaudet.</p>

        <h4>Kilpailuta YEL-vakuutus</h4>
        <p>YEL-vakuutus on yrittäjän pakollinen eläkevakuutus, jolla turvataan yrittäjän oma eläke. Yrittäjä voi itse vaikuttaa tulevan eläkkeensä määrään vakutusmaksuillaan. YEL-vakuutus tarjoaa muutenkin melko laajan sosiaalisen turvan yrittäjälle, ja esim. sairauspäivärahan, työttömyysetuuden sekä vanhempainpäivärahan määrät määräytyvät maksetun YEL-työtulon mukaan.</p>

        <h4>Kilpailuta yhdistelmävakuutus</h4>
        <p>Ydistelmävakuutus on mahdollisesti tuloillaan oleva vapaaehtoinen vakuutusratkaisu itsensä työllistävien henkilöiden sosiaaliturvan takaamiseen. Yhdistelmävakuutuksen kohderymänä ovat kevytyrittäjät ja yrittäjät, jotka voivat saada osan ansioistaan palkkatyöllä.</p>

        <h4>Kilpailuta ympäristövahinkovakuutus</h4>
        <p>Ympäristövahinkovakuutus on pakollinen yrityksille, joiden toiminnassa on olemassa riski vakavan ympäristöhaitan syntymiselle, kuten esim. kemian- tai kaivosteollisuuden yrityksille.</p>

        <h4>Kilpailuta yritysvakuutukset</h4>
        <p>Yritysvakuutuksiin kuuluvat kaikki vakuutukset, joilla turvataan yritysten tai yrittäjien toimintaa. Yritysvakuutusten säännöllinen kilpailuttaminen voi tuoda selvää säästöä ja varmistaa että vakuutusten ehdot ovat kulloinkin ajan tasalla.</p>

      </div>

    </div>

  </div>


  <!-- FOOTER ................................................................................ -->
  <item-footer-main></item-footer-main>


  <!-- Just add this whre you need to warch for user's ativity .................................. -->
  <!-- On this page it has to turn this "activity watvhing" on only if user was signed in -->
  <box-user-keep-active v-if="$store.state.userType!=''"></box-user-keep-active>


</div>
</template>


<script> // -------------------------------------------------------------------------------------------------------

import itemInitNavBar    from '@/components/pageInit/itemInitNavBar'
import itemFooterMain    from '@/components/itemFooterMain'
import boxUserKeepActive from '@/components/boxUserKeepActive'


export default {
  name:        'pageInfoAboutInsurances',
  components: {
                itemInitNavBar,
                itemFooterMain,
                boxUserKeepActive
              },

  data () {

    return {

       //signUpShow: false,

    } // return

  }, // data


  // ===========================================================================================================
  created () {
  }, // created


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>
<template>

  <!-- STATUS 404 .................................................................................................... -->
  <div class="page-status-404">
    
    <div>
      <img class = "page-status-404-img"
           src   = "@/assets/img/ill-status-404.svg">
    </div>

    <div>
      <h1 class="theme-light-title-1">Voi ei jotain meni vikaan...</h1>
    </div>

    <div>Etsimääsi sivua ei löydy. Palaa takaisin etusivulle ja kokeile uudelleen.</div>
    
    <div>
      <input type  = "button"
            :value = "buttonGoHomeValue"
             class = "button-3"
            :class = "buttonTheme1"
            @click = "$router.push({ path: '/' })">
    </div>

  </div>

</template>


<script> // ----------------------------------------------------------------------------------------------------------

//import itemAdminNavBar from '@/components/userAdmin/itemAdminNavBar'


export default {
  name:        'pageStatus404',
  //components: { itemAdminNavBar },


  data () {  // data
    return {

      buttonTheme1: 'button-theme-1',

      buttonGoHomeValue: 'Siirry etusivulle',

    } // return
  }, // data


  // ===========================================================================================================
  created () {

    this.$store.commit('requestFailedMainDelete'); // In Store: GET type of the clicked buttom's id
    
    if (this.$store.state.userType) this.buttonGoHomeValue = 'Siirryy omalle sivulle';

  }, // created


  // ===========================================================================================================
  methods: {
  }, // methods


}
</script>

<template>
<transition name="slide" mode="out-in">

  <!-- VALIDATION BOX from SERVER ............................................................ -->
  <div :class="validationBoxClass">
    
    <span class = "button-close-4" 
         @click = "$emit('current-box-close')" 
          title = "Sulje ikkuna"></span>

    <ul>
      <li v-for = "(message, index) in messages" 
         :key   = "index">{{ message }}</li>
    </ul>

  </div>

</transition>
</template>


<script> // -------------------------------------------------------------------------------------------------------

//import mixinXhrPostAsyncApp from '@/mixins/mixinXhrPostAsyncApp'


export default {
  name:        'boxValidationServerBasic',
  props:      [
               'messages',
               'responseSucceeded' // OPTIONAL: only when it is need to use also SUCCESSFUL VALIDATION MSGs
              ],
  emits:      [
               'current-box-close'
              ],

//mixins:     [ mixinXhrPostAsyncApp ],
//components: { itemSignup },

  data () {
    return {

      validationBoxClass: 'validation-box validation-error-1 relative',

    } // return
  }, // data

  
  // ===========================================================================================================
  created () {

    if (this.responseSucceeded == true) { this.validationBoxClass = 'validation-box validation-success-1 relative'; }

  },


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>


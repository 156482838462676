
export default {


  data () {
    return {

        // mixinTrimRegExBasic: /^\s+|\s+$/gm,
      
    } // return
  }, // data


	methods: {
		
		
    //--- Delete all spaces from VALUE -------------------------------------------------------------------
		
    mixinWhitespaceRemove (v) {
      return v.replace(/\s+/g, '');
    }


	} // methods
}

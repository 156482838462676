
export default {

  /*
  ATTENTION! Do not forget to define VALIDATION rules in documentation:
  https://insured.atlassian.net/wiki/spaces/VAKUUTUSTI/pages/290947073/Validation+rules+F+B
  */

  data () {
    return {

      // 8-30 characters, at least 1 upper- and 1 lowercase letters, 1 number and 1 special character
      mixinValidation_password_regexTotal: /^(?=.*[a-zäöå])(?=.*[A-ZÄÖÅ])(?=.*\d)(?=.*[$@!%*?&#.,:;_-])[a-zA-ZÄÖÅäöå\d$@!%*?&#.,:;_-]{8,30}$/,

    } // return
  }, // data


  methods: {
		
    //---  -----------------------------------------------------------------------------------------------
    // mixinValidation_password (inputValue) {},

  } // methods

}




<template>
  <div>
    
  <window-time-left v-if               = "windowTimeLeft_show"
                   :bodyOverflowHidden = "false"
                   @activity-wake-up   = "activity_wakeUp"
                   @timer-over         = "timer_over"></window-time-left>

  
  <!-- DEV MODE BOX .................................................................................... -->

  <!-- <div class = "DEV-box-insurance-existing" @click="xxx">xxx</div> -->
  <!-- <div class = "DEV-box-insurance-existing" @click="mixinSignOut(true)">mixinSignOut(true)</div> -->

  <!--
  <div v-if  = "parentType=='request'"
       class = "DEV-box-insurance-existing">
    
    <div>
      <input type  = "button"
             class = "DEV-button-1 DEV-button-style-1" 
             value = "windowTimeLeft"
            @click = "DEV_activity_set">
    </div>
    
    <div>
      <input type  = "button"
             class = "DEV-button-1 DEV-button-style-1" 
             value = "Offer is over"
            @click = "pingMakingOffer_failed">
    </div>

    <div>
      <input type  = "button"
             class = "DEV-button-1 DEV-button-style-1" 
             value = "Offer data failed"
            @click = "$emit('parent-response-failed')">
    </div>

  </div>
  -->

</div>
</template>


<script> // ----------------------------------------------------------------------------------------------------------

import windowTimeLeft from '@/components/windowTimeLeft'

import mixinXhrPostAsyncApp from '@/mixins/mixinXhrPostAsyncApp'
import mixinSignOut         from '@/mixins/mixinSignOut'


export default {
  name:         'boxUserKeepActive',
  components: {
                windowTimeLeft
              },
  mixins:     [ 
                mixinXhrPostAsyncApp,
                mixinSignOut
              ],
  // props:      [ // 'parentType', // 'keyTender', // 'isUserActive' ],


  data () {
    return {

      activity: {
        isUserActive:        false,
        timerMain:           null, // It is for "setInterval" functionality
        timerMain_duration:  6,
        inactivityTimeSpent: 24,
        inactivityTime:      0
      },

      windowTimeLeft_show: false,

      // pingMakingOffer_reasonForFailure: {
      //   /* titleMain: ''*/
      //   textMain: ''
      // },

      // timeWait: 29*60*1000,

    } // return
  }, // data


  // ===========================================================================================================
  created () {

    this.activity_set();

  }, // created


  // ===========================================================================================================
  mounted () {

    window.addEventListener('wheel', this.activityCatch); // !: Can be need this: "() => funcHere"
    window.addEventListener('keypress', this.activityCatch); // !: Can be need this: "() => funcHere"
    window.addEventListener('click', this.activityCatch); // !: Can be need this: "() => funcHere"
    // alert('XXX');

  }, // mounted


  // ===========================================================================================================
  beforeUnmount () {

    clearInterval(this.activity.timerMain);
    // console.log('KEEP ACTIVE: (Interval is cleared) timerMain=' + this.activity.timerMain);

    window.removeEventListener('wheel', this.activityCatch); // !: Can be need this: "() => funcHere"
    window.removeEventListener('keypress', this.activityCatch); // !: Can be need this: "() => funcHere"
    window.removeEventListener('click', this.activityCatch); // !: Can be need this: "() => funcHere"

  },


  // ===========================================================================================================
  methods: {


    //---   ----------------------------------------------------------------------
    activity_set () {

      // console.log('KEEP ACTIVE: (1) timerMain=' + this.activity.timerMain);
      if (this.activity.timerMain) clearInterval(this.activity.timerMain); // RESET it
      // console.log('KEEP ACTIVE: (2) timerMain=' + this.activity.timerMain);

      // This timer is set to 6 min because "pingMakingOffer" checks also status of current tender:
      this.activity.timerMain = setInterval( () => {

          if (this.activity.isUserActive) {
            this.submitMain();
            this.activity.isUserActive = false; // RESET it
          }
          else {
            this.activity.inactivityTime += this.activity.timerMain_duration;
            this.windowTimeLeft_open();
          }

      }, this.activity.timerMain_duration*60*1000 );
      // console.log('KEEP ACTIVE: (3) timerMain=' + this.activity.timerMain);

    },

    
    //--- Calls at every user's activity (clicks, inputs, scroll) --------------------
    activityCatch () {

      // console.log('KEEP ACTIVE: activityCatch WORKS');
      if (this.$store.state.userType != '' || this.$store.state.DEV_Show) {
        if (!this.activity.isUserActive) this.activity.isUserActive = true;
      }

    },
    // activityCatch () {this.$refs.boxUserKeepActiveRef.activityCatch(); },


    
    //---   -------------------------------------------------------------------------
    activity_wakeUp () {

      this.activityCatch();
      this.activity_set();
      this.windowTimeLeft_show = false; // Close that window

    },

   

    //---   -------------------------------------------------------------------------
    windowTimeLeft_open () {

      if (this.activity.inactivityTime >= this.activity.inactivityTimeSpent) {
        // console.log('KEEP ACTIVE: inactivityTime=' + this.activity.inactivityTime);
        // console.log('KEEP ACTIVE: inactivityTimeSpent=' + this.activity.inactivityTimeSpent);
        this.windowTimeLeft_show = true;
      }

    },


    //--- Request  ------------------------------------------------------------------
    submitMain () {

      const url    = 'uiPing'; // URL address for HTTPXMLrequest in mixinXhrPostAsyncApp
      const header = 'app'; // (!) Check that this servlet works after setting "header" as "app" 
      const obj    = '';

      this.mixinXhrPostAsyncApp(obj, url, header);

    },


    //--- Create from SERVER response user friendly ERROR MSGs -------------------------------------------------
    xhrPostAsyncAppResponseMsgs (response/*, url*/) {
      
        if (response.succeeded == true) {
          this.activity.inactivityTime = 0; // RESET it
        }
        else if (response.succeeded == false) {
          alert('uiPing servlet does not work');
        }

    },


    //---  ---------------------------------------------------------------
    timer_over () {

      this.windowTimeLeft_show = false; // Close that window
      this.mixinSignOut(true);

    },


    //---   ----------------------------------------------------
    /*
    timer_over () {
      
      // 'parent-box-close' also calls func "submitMain_cancelMakingOffer" (see parent)
      this.$emit('parent-box-close', false);

      // this.windowTimeLeft_show = false; // Close that window
      
    },*/


    //--- DEV ---------------------------------------------------------------
    // DEV_activity_set () {
    //   this.windowTimeLeft_show = true;
    // }


    //--- Request  ------------------------------------------------------------------
    // submitMain_cancelMakingOffer () {

    //   const url    = 'cancelMakingOffer'; // URL address for HTTPXMLrequest in mixinXhrPostAsyncApp
    //   const header = '';
    //   const obj    = 'key=' + this.keyTender; // Request to SERVER: in this case it is EMPTY

    //   this.mixinXhrPostAsyncApp(obj, url, header);

    // },


    //---   ---------------------------------------------------------------
    // pingMakingOffer_failed (reasonForFailure) {

    //   // 'parent-box-close' also calls func "submitMain_cancelMakingOffer" (see parent)
    //   this.$emit('parent-box-close', 'fail');

    //   if (this.$store.state.DEV_Show) {
    //     this.pingMakingOffer_reasonForFailure.textMain = this.mixinDataSetBasic(this.mixinOffer_reasonForFailure, 'default');
    //   }
    //   else {
    //     this.pingMakingOffer_reasonForFailure.textMain = this.mixinDataSetBasic(this.mixinOffer_reasonForFailure, reasonForFailure);
    //   }
      
    //   this.$store.commit('windowNotificationSimpleBasic_Set', this.pingMakingOffer_reasonForFailure);
    //   // this.pingMakingOffer_reasonForFailureSet(reasonForFailure); // SET it

    // },


    //---   ---------------------------------------------------------------
    // pingMakingOffer_reasonForFailureSet (reasonForFailure) {

    //   switch (reasonForFailure) {

    //     case 'notFound':
    //       this.pingMakingOffer_reasonForFailure.textMain = 'Tarjouspyyntöä ei löydy'; break;

    //     case 'noActivity':
    //       this.pingMakingOffer_reasonForFailure.textMain = 'Lomakkeen täyttöaika ylittyi'; break;

    //     case 'abandoned':
    //       this.pingMakingOffer_reasonForFailure.textMain = 'Tarjouspyynnön lomake suljettu'; break;

    //     case 'tenderingExpired':
    //       this.pingMakingOffer_reasonForFailure.textMain = 'Kilpailutuksen aikaraja on umpeutunut'; break;

    //     case 'tenderingCancelled':
    //       this.pingMakingOffer_reasonForFailure.textMain = 'Asiakas on keskeyttänyt kilpailutuksen'; break;

    //     case 'otherOfferAccepted':
    //       this.pingMakingOffer_reasonForFailure.textMain = 'Asiakas on jo ehtinyt hyväksyä jonkin tarjouksen ja kilpailutus on päättynyt'; break;
        
    //     default: 
    //       this.pingMakingOffer_reasonForFailure.textMain = 'Tarjouspyynnön lomake suljettu';

    //   }

    // },


  }, // methods


}
</script>


<template>
<div>

  <div class="client-container info">

    <!-- NAVBAR .............................................................................. -->
    <item-init-nav-bar parentType="pageInfo"></item-init-nav-bar>

    <!-- TITLE ............................................................................... -->
    <div class="info-box-title-main">
      <div class="info-box-main">
        <div><h1 class="title-h1-bigger theme-light-title-1">Asiakastuki ja palaute</h1></div>
      </div>
    </div>

    <!-- MAIN ................................................................................ -->
    <div class="info-box-main info-box-1 info-customer_support_and_feedback-1">

      <div class="info-box-1_1">

        <p><b>Vakuutustiedot.fi palvelun kaikki asiakastuki ja palaute hoidetaan jokaisen profiilin yhteydessä olevan asiakaspalvelu-osion kautta.</b></p>

        <p>Voit siis olla meihin yhteydessä luomalla itsellesi profiilin. Vastaamme asiakaspalvelupyyntöihin niiden saapumisjärjestyksessä. Voit antaa meille myös palautetta ja kehitysideoita palvelumme kehittämiseksi asiakaspalvelu-osion kautta. Löydät asiakaspalvelu-osion profiilisi alaosasta.</p>

        <p><b>Et voi olla meihin yhteydessä puhelimitse tai sähköpostitse.</b> Tällä tavalla voimme tarjota parhaan mahdollisen asiakaspalvelukokemuksen.</p>

      </div>

    </div>

  </div>

  <!-- FOOTER ................................................................................ -->
  <item-footer-main></item-footer-main>


  <!-- Just add this whre you need to warch for user's ativity .................................. -->
  <!-- On this page it has to turn this "activity watvhing" on only if user was signed in -->
  <box-user-keep-active v-if="$store.state.userType!=''"></box-user-keep-active>


</div>
</template>


<script> // -------------------------------------------------------------------------------------------------------

import itemInitNavBar from '@/components/pageInit/itemInitNavBar'
import itemFooterMain from '@/components/itemFooterMain'
import boxUserKeepActive from '@/components/boxUserKeepActive'


export default {
  name:        'pageInfoCustomerSupportAndFeedback',
  components: {
                itemInitNavBar,
                itemFooterMain,
                boxUserKeepActive
              },

  data () {

    return {

       //signUpShow: false,

    } // return

  }, // data


  // ===========================================================================================================
  created () {
  }, // created


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>
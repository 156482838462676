import { createRouter, createWebHistory } from 'vue-router'

import pageInitPp     from '@/components/pageInit/pageInitPp'
import pageInitPc     from '@/components/pageInit/pageInitPc'
import pageInitIr     from '@/components/pageInit/pageInitIr'
// import pageUserClient from '@/components/pageUserClient' // TURN IT "ON" if you DO NOT use "component: () => import" etc.
// import pageUserSeller from '@/components/pageUserSeller' // TURN IT "ON" if you DO NOT use "component: () => import" etc.
// import pageUserAdmin  from '@/components/pageUserAdmin' // TURN IT "ON" if you DO NOT use "component: () => import" etc.

import pageInfoTenderingProcess           from '@/components/pagesInfo/pageInfoTenderingProcess'
import pageInfoSafetyInstructions         from '@/components/pagesInfo/pageInfoSafetyInstructions'
import pageInfoRecommendAndEarn           from '@/components/pagesInfo/pageInfoRecommendAndEarn'
import pageInfoCommonHints                from '@/components/pagesInfo/pageInfoCommonHints'
import pageInfoCompanyInsurances          from '@/components/pagesInfo/pageInfoCompanyInsurances'
import pageInfoPrivateInsurances          from '@/components/pagesInfo/pageInfoPrivateInsurances'
import pageInfoCustomerSupportAndFeedback from '@/components/pagesInfo/pageInfoCustomerSupportAndFeedback'
import pageInfoProfileActivation          from '@/components/pagesInfo/pageInfoProfileActivation'
import pageInfoForgotPassword             from '@/components/pagesInfo/pageInfoForgotPassword'
import pageInfoFaq                        from '@/components/pagesInfo/pageInfoFaq'
import pageInfoCookies                    from '@/components/pagesInfo/pageInfoCookies'
import pageInfoPrivacyPolicy              from '@/components/pagesInfo/pageInfoPrivacyPolicy'
import pageInfoAboutInsurances            from '@/components/pagesInfo/pageInfoAboutInsurances'
import pageInfoGeneralRules               from '@/components/pagesInfo/pageInfoGeneralRules'
import pageInfoAboutUs                    from '@/components/pagesInfo/pageInfoAboutUs'
import pageInfoTermsOfUse                 from '@/components/pagesInfo/pageInfoTermsOfUse'

import pageStatus404  from '@/components/pageStatus404'
// import HomeView from '../views/HomeView.vue'


const routes = [


  // BASIC PAGES ............................................................................................................

  { path: '/',                     name: 'pageInit/pageInitPp', component: pageInitPp/*, alias: '/henkiloasiakkaille'*/ },
  { path: '/yritysasiakkaille',    name: 'pageInit/pageInitPc', component: pageInitPc },
  { path: '/vakuutusasiamiehille', name: 'pageInit/pageInitIr', component: pageInitIr },
  { path: '/client/:userType',     name: 'pageUserClient',      component: () => import(/* webpackChunkName:'pageUserClient' */'@/components/pageUserClient')/*component: pageUserClient*/ },
  { path: '/seller/:userType',     name: 'pageUserSeller',      component: () => import(/* webpackChunkName:'pageUserSeller' */'@/components/pageUserSeller')/*component: pageUserSeller*/ },
  { path: '/admin/:userType',      name: 'pageUserAdmin',       component: () => import(/* webpackChunkName:'pageUserAdmin'  */'@/components/pageUserAdmin') /*component: pageUserAdmin*/ },
  //{ path: '/user/:id', component: pageUser }, // !: ONLY FOR TESTING 
    
    
  // INFORMATION PAGES ......................................................................................................

  { path: '/kilpailutusprosessi',   name: 'pagesInfo/pageInfoTenderingProcess',           component: pageInfoTenderingProcess },
  { path: '/turvallisuusohjeet',    name: 'pagesInfo/pageInfoSafetyInstructions',         component: pageInfoSafetyInstructions },
  { path: '/tienaasuosittelemalla', name: 'pagesInfo/pageInfoRecommendAndEarn',           component: pageInfoRecommendAndEarn },
  { path: '/yleisetvinkit',         name: 'pagesInfo/pageInfoCommonHints',                component: pageInfoCommonHints },
  { path: '/yritysvakuutukset',     name: 'pagesInfo/pageInfoCompanyInsurances',          component: pageInfoCompanyInsurances },
  { path: '/yksityisetvakuutukset', name: 'pagesInfo/pageInfoPrivateInsurances',          component: pageInfoPrivateInsurances },
  { path: '/asiakastukijapalaute',  name: 'pagesInfo/pageInfoCustomerSupportAndFeedback', component: pageInfoCustomerSupportAndFeedback },
  { path: '/tilinaktivointiin',     name: 'pagesInfo/pageInfoProfileActivation',          component: pageInfoProfileActivation },
  { path: '/unohditkosalasanan',    name: 'pagesInfo/pageInfoForgotPassword',             component: pageInfoForgotPassword },
  { path: '/useinkysyttya',         name: 'pagesInfo/pageInfoFaq',                        component: pageInfoFaq },
  { path: '/evastekaytannot',       name: 'pagesInfo/pageInfoCookies',                    component: pageInfoCookies },
  { path: '/tietosuojaseloste',     name: 'pagesInfo/pageInfoPrivacyPolicy',              component: pageInfoPrivacyPolicy },
  { path: '/tietoavakuutuksista',   name: 'pagesInfo/pageInfoAboutInsurances',            component: pageInfoAboutInsurances },
  { path: '/yleisetsaannot',        name: 'pagesInfo/pageInfoGeneralRules',               component: pageInfoGeneralRules },
  { path: '/tietoameista',          name: 'pagesInfo/pageInfoAboutUs',                    component: pageInfoAboutUs },
  { path: '/kayttoehdot',           name: 'pagesInfo/pageInfoTermsOfUse',                 component: pageInfoTermsOfUse },


  // STATUS PAGES ......................................................................................................
    
  { path: '/status404', name: 'pageStatus404', component: pageStatus404 },


  // NOT EXISTING PAGES ..................................................................................................

  { path: '/:pathMatch(.*)*', name: 'pageStatus404', component: pageStatus404 },


  // { path: '/',      name: 'home', component: HomeView },
  // { path: '/about', name: 'about', component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue') }


]






const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(/*to, from, savedPosition*/) {
    return { top: 0 } // always scroll to top
  }
})

export default router

<template>
<div>

  <div class="client-container info">

    <!-- NAVBAR .............................................................................. -->
    <item-init-nav-bar parentType="pageInfo"></item-init-nav-bar>

    <!-- TITLE ............................................................................... -->
    <div class="info-box-title-main">
      <div class="info-box-main">
        <div><h1 class="title-h1-bigger theme-light-title-1">Unohditko salasanan?</h1></div>
      </div>
    </div>

    <!-- MAIN ................................................................................ -->
    <div class="info-box-main info-box-1">

      <div v-if="isUserLoggedIn">
        <p>Voit vaihtaa salasanasi Omat tiedot ja asetukset -osiosta.</p>
        <p><br></p>
        <p>Jos olet unohtanut nykyisen salasanasi, kirjaudu ensin ulos. Uloskirjautumisen jälkeen voit uusia salasanasi etusivun kirjautuminen-valikon kautta klikkaamalla <b>Unohditko salasanasi?</b> linkkiä. Voit myös pyytää salasanan vaihtolinkin palaamalla tälle <b>Unohditko salasanan?</b> sivulle.</p>
      </div>

      <div v-else>
        <p>Jos unohdit salasanasi, voit vaihtaa sen etusivun <b>"KIRJAUDU SISÄÄN"</b> -valikon kautta klikkaamalla <b>"Unohditko salasanasi?"</b> -linkkiä. Kirjoittamalla aukeavaan ikkunaan sähköpostisi, lähetämme sinulle linkin salasanan vaihtamiseen.</p>
        <p><br></p>
        <p>Voit myös pyytää salasanan vaihtolinkin suoraan tältä sivulta:</p>
      </div>

      <!-- FORGOT PASSWORD ... -->
      <item-forgot-password v-if       = "!isUserLoggedIn"
                            class      = "windowed-inner-2 info-forgot-password-window"
                            parentType = "pageInfo"></item-forgot-password>

    </div>

  </div>

  <!-- FOOTER ................................................................................ -->
  <item-footer-main></item-footer-main>


  <!-- Just add this whre you need to warch for user's ativity .................................. -->
  <!-- On this page it has to turn this "activity watvhing" on only if user was signed in -->
  <box-user-keep-active v-if="$store.state.userType!=''"></box-user-keep-active>


</div>
</template>


<script> // -------------------------------------------------------------------------------------------------------

import itemInitNavBar     from '@/components/pageInit/itemInitNavBar'
import itemFooterMain     from '@/components/itemFooterMain'
import itemForgotPassword from '@/components/itemForgotPassword'
import boxUserKeepActive from '@/components/boxUserKeepActive'

import mixinUserDefine from '@/mixins/mixinUserDefine'


export default {
  name:        'pageInfoForgotPassword',
  components: {
                itemInitNavBar,
                itemFooterMain,
                itemForgotPassword,
                boxUserKeepActive
              },
  mixins:     [ 
                mixinUserDefine
              ],

  data () {

    return {

       isUserLoggedIn: false,

    } // return

  }, // data


  // ===========================================================================================================
  beforeMount() {

    this.mixinUserDefine_TypeGet(); // Get fresh userType (helpful, if user updates page)

  }, // beforeMount


  // ===========================================================================================================
  created () {

    // If user was logged in:
    if (this.$store.state.userType) this.isUserLoggedIn = true;

  }, // created


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>
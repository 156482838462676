<template>
<div>

  <div class="client-container info">

    <!-- NAVBAR .............................................................................. -->
    <item-init-nav-bar parentType="pageInfo"></item-init-nav-bar>

    <!-- TITLE ............................................................................... -->
    <div class="info-box-title-main">
      <div class="info-box-main">
        <div><h1 class="title-h1-bigger theme-light-title-1">Yleiset vinkit</h1></div>
      </div>
    </div>

    <!-- MAIN ................................................................................ -->
    <div class="info-box-main info-box-1">

      <div class="info-box-1_1">

        <p>Vakuutussopimukset kannattaa tarkistaa säännöllisesti. Suosittelemme tarkistamaan kaikki vakuutussopimukset vuosittain.</p>

        <p>Etenkin uusien vakuutusten hankinnassa on järkevintä tehdä vertailua eri vakuutusyhtiöiden kesken. Vakuutuksia vertaillessa oleellisinta on miettiä mihin tarkoitukseen vakuutusta olet hankkimassa. Halvempi hinta tarkoittaa yleensä heikompia korvausehtoja. Vakuuttamalla itsesi tai vakuutettavan kohteen laajemmin, voit nauttia paremmasta mielenrauhasta ja saat selvästi parempia korvauksia, jos jotakin sattuu.</p>

        <p>Palvelumme välityksellä pääset keskustelemaan omista vakuutuksistasi vakuutusasiamiesten kanssa, jotka ovat vakuuttamisen ammattilaisia. Vakuutusasiamiesten tarjoama konsultaatio tai itse palvelun käyttö ei maksa sinulle mitään.</p>

        <p>Suosittelemalla palveluamme voit tarjota muillekin mahdollisuuden hoitaa vakuutusasiat kätevästi netissä.</p>

      </div>

    </div>

  </div>

  <!-- FOOTER ................................................................................ -->
  <item-footer-main></item-footer-main>


  <!-- Just add this whre you need to warch for user's ativity .................................. -->
  <!-- On this page it has to turn this "activity watvhing" on only if user was signed in -->
  <box-user-keep-active v-if="$store.state.userType!=''"></box-user-keep-active>


</div>
</template>


<script> // -------------------------------------------------------------------------------------------------------

import itemInitNavBar from '@/components/pageInit/itemInitNavBar'
import itemFooterMain from '@/components/itemFooterMain'
import boxUserKeepActive from '@/components/boxUserKeepActive'


export default {
  name:        'pageInfoCommonHints',
  components: {
                itemInitNavBar,
                itemFooterMain,
                boxUserKeepActive
              },

  data () {

    return {

       //signUpShow: false,

    } // return

  }, // data


  // ===========================================================================================================
  created () {
  }, // created


  // ===========================================================================================================
  methods: {
  }, // methods

}
</script>